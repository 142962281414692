import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Autocomplete,
  AutocompleteItem,
  cn,
} from '@nextui-org/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { userService } from 'infra/api';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  data?: {
    email: string;
  };
  id: string;
}

export const GetEmail = ({ id, Trigger, data }: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const connectToDiscord = () => {
    const discordAuthUrl = import.meta.env.VITE_DISCORD_AUTH_URL;
    const dataQueryString = new URLSearchParams({ id: id }).toString();
    const dynamicAuthUrl = `${discordAuthUrl}&state=${encodeURIComponent(dataQueryString)}`;

    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;

    window.open(
      dynamicAuthUrl,
      '_blank',
      `width=${width},height=${height},top=${top},left=${left},resizable=yes,scrollbars=yes,status=yes`
    );
  };

  const formik = useFormik({
    initialValues: {
      email: data?.email || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
    }),
    onSubmit: async (values) => {
      userService
        .updateDiscordMail({
          discordMail: values.email,
          id: id,
        })
        .then((res) => {
          connectToDiscord();
        });
      formik.resetForm();
      onClose();
    },
  });

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        onClose={() => {
          formik.resetForm();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Connect to Discord</ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  label="Discord Email:"
                  placeholder="Enter your email"
                  classNames={{
                    inputWrapper: 'px-7 h-12 shadow-none rounded-[20px]',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  name="email"
                  type="email"
                />
                <p style={{ fontSize: 9, color: 'red' }}>
                  *Please enter the email which you have already logged in to discord.
                </p>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={onClose}
                  className={cn(
                    'bg-[#F0F0F0]',
                    'text-black font-medium',
                    'md:w-[200px] lg:w-[220px] xl:w-[260px]'
                  )}
                >
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => formik.submitForm()}
                  className={cn('bg-[#99CF63]', 'md:w-[200px] lg:w-[220px] xl:w-[260px]')}
                  disabled={!formik.isValid}
                >
                  {'Submit'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
