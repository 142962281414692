import { ProjectIteration } from 'domain/modules/projects/models/iteration.model';
import { projectsService } from 'infra/api';
import { ApiError } from 'infra/api/utils/errorHandler';
import { useState } from 'react';

export const useProjectIteration = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [projectIterations, setProjectIterations] = useState<ProjectIteration[]>([]);
  const [iteration, setIteration] = useState<ProjectIteration>();

  const createIteration = async (projectId: string, body: ProjectIteration) => {
    try {
      setIsCreating(true);
      await projectsService.createIteration(projectId, body);
    } catch (ex) {
      alert((ex as ApiError).message);
    } finally {
      setIsCreating(false);
    }
  };

  const getProjectIterations = async (project_id: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getProjectIterations(project_id);
      setProjectIterations(data);
    } catch (ex) {
      alert((ex as ApiError).message);
    } finally {
      setIsFetching(false);
    }
  };

  const getIteration = async (projectId: string, iterationId: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getIterationById(projectId, iterationId);
      setIteration(data);
    } catch (ex) {
      alert((ex as ApiError).message);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteIteration = async (projectId: string, iterationId: string) => {
    try {
      const data = await projectsService.deleteIteration(projectId, iterationId);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  };

  return {
    isCreating,
    isFetching,
    projectIterations,
    iteration,
    getIteration,
    createIteration,
    getProjectIterations,
    deleteIteration,
  };
};
