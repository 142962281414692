import { Select, SelectItem } from '@nextui-org/react';
import { Project } from 'adminApp/models/project';
import { TaskStatusType } from 'domain/modules';
import { projectsService } from 'infra/api';

const options = [
  {
    id: 'START_DESIGNING',
    label: 'Start Designing',
  },
  {
    id: 'IN_PROGRESS',
    label: 'In Progress',
  },
  {
    id: 'IN_REVIEW',
    label: 'In Review',
  },
  {
    id: 'COMPLETED',
    label: 'Completed',
  },
];

interface IProps {
  projectId?: string;
  iterationId?: string;
  project?: Project | undefined;
}

export const StatusSelection = (props: IProps) => {
  const ValidateDeliveryItem =
    props.project &&
    props.project.deliverable_item &&
    Array.isArray(props.project.deliverable_item) &&
    props.project.deliverable_item.length > 0
      ? true
      : false;

  const onChange = async (toStatus: TaskStatusType) => {
    if (toStatus) {
      if (props.projectId) {
        if (toStatus === 'IN_REVIEW' && !ValidateDeliveryItem) {
          alert('No Deliverables Found');
        } else {
          await projectsService.updateProjectStatus(props.projectId, toStatus);
          alert('Project status updated');
        }
      }
    }
  };

  return (
    <Select
      label="Select a status"
      className="max-w-xs"
      onChange={(ev) => onChange(ev.target.value as TaskStatusType)}
    >
      {options.map((option) => (
        <SelectItem key={option.id} value={option.id}>
          {option.label}
        </SelectItem>
      ))}
    </Select>
  );
};
