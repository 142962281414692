import { useProjectDetailContext } from '../../context';
import { FooterIteractionRequest, FooterMarkAsComplete } from './FooterActions';

const FooterAction = {
  MarkAsComplete: FooterMarkAsComplete,
  IterationRequest: FooterIteractionRequest,
};

export const Footer = () => {
  const { project } = useProjectDetailContext();

  if (project.status === 'COMPLETED') {
    return null;
  }

  return (
    <footer className="flex fixed bottom-0 left-0 right-0 h-16 bg-white shadow-[0_10px_60px_0px_rgba(0,0,0,0.25)]">
      <div className="w-[85%] md:w-[90%] lg:w-[95%] mx-auto max-w-[1890px] flex items-center justify-between">
        <FooterAction.MarkAsComplete />
        <FooterAction.IterationRequest />
      </div>
    </footer>
  );
};
