import { useAppDispatch, useAppSelector } from 'application/shared';
import { createBrand, getAllBrands } from '../brand.thunks';
import { clearErrors } from '../brand.slice';
import { brandService } from 'infra/api';

export const useBrand = () => {
  const dispatch = useAppDispatch();
  const { brand, isCreating, errorCreating, brandList, isFetchingList } = useAppSelector(
    (state) => state.brand
  );

  const create = (name: string) => {
    return new Promise((resolve, reject) => {
      dispatch(createBrand(name))
        .unwrap()
        .then((originalPromiseResult) => {
          resolve(originalPromiseResult);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const deleteBrand = (id: string) => {
    return new Promise((resolve, reject) => {
      brandService
        .deleteBrand(id)
        .then(() => {
          getAll();
          resolve(null);
        })
        .catch(() => {
          reject();
        });
    });
  };

  const getAll = () => {
    return dispatch(getAllBrands());
  };

  const _clearErrors = () => {
    return dispatch(clearErrors());
  };

  return {
    brand,
    create,
    isCreating,
    clearErrors: _clearErrors,
    errorCreating,
    deleteBrand,
    getAll,
    brandList,
    isFetchingList,
  };
};
