import { TaskStatusType } from 'domain/modules';
import { IColumn } from './types';

export const columnList: Array<IColumn> = [
  {
    id: 'TODO',
    label: 'TO DO',
  },
  {
    id: 'START_DESIGNING',
    label: 'START DESIGNING',
  },
  {
    id: 'IN_PROGRESS',
    label: 'IN PROGRESS',
  },
  {
    id: 'IN_REVIEW',
    label: 'IN REVIEW',
  },
  {
    id: 'COMPLETED',
    label: 'COMPLETED',
  },
];

export const STATE_TRANSITIONS: Record<TaskStatusType, Array<TaskStatusType>> = {
  IN_ACTIVE: [],
  TODO: ['START_DESIGNING'],
  START_DESIGNING: ['TODO'],
  IN_PROGRESS: [],
  IN_REVIEW: ['COMPLETED'],
  COMPLETED: [],
};
