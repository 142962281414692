import sample_project_thumbnail from 'presentation/assets/sample_project/sample_project_thumbnail.png';
import mockup from 'presentation/assets/sample_project/mockup.png';
import source from 'presentation/assets/zipFile.svg';

export const supportedFileTypes = ['JPG', 'PNG', 'GIF', 'JPEG', 'SVG'];

export const supportedProjectFileTypes = [
  'JPG',
  'PNG',
  'SVG',
  'JPEG',
  'PDF',
  'MP4',
  'DOC',
  'PPT',
  'MP3',
  'PPTX',
];

export const brandIntroduction: string[] = [
  'Easily set up, manage, and grow your brand with all your assets, controls, and workflows in one place.',
  'Manage Your brand kits to enhance your design.',
  'Find all of your brand assets and templates from one place in the editor.',
];

export const sampleProjectFIles: Array<{ name: string; file: string }> = [
  {
    name: 'Design File',
    file: sample_project_thumbnail,
  },
  {
    name: 'Source File',
    file: source,
  },
  {
    name: 'Mockup',
    file: mockup,
  },
];
