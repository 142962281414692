import { Button } from 'presentation/components';

interface IProps {
  onCloseModal: () => void;
  projectCredits: number;
}

export const ProjectCreatedWizard = ({ onCloseModal, projectCredits }: IProps) => {
  const handleDoneClick = () => {
    // Call the onCloseModal function (if needed)
    onCloseModal();

    // Refresh the page
    window.location.reload();
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-10">
      <div className="flex flex-col items-center justify-center gap-1">
        <h3 className="text-black font-semibold text-xl">
          You have successfully created a design task!
        </h3>
        <h5 className="text-[#828282] text-center text-[16px] w-[90%]">
          {projectCredits} design credits have been utilized.
        </h5>
      </div>

      <div className="flex items-center justify-center gap-4">
        <Button radius="md" className="bg-[#99CF63] text-black px-10" onClick={handleDoneClick}>
          Done
        </Button>
      </div>
    </div>
  );
};
