import dayjs from 'dayjs';

export const timeDisplay = (datetimeString: string) => {
  const now = dayjs();
  const datetime = dayjs(datetimeString);

  // Calculate the difference in time
  const diffInSeconds = now.diff(datetime, 'second');

  if (diffInSeconds < 60) {
    return 'Just now';
  } else if (diffInSeconds < 3600) {
    const minutesAgo = Math.floor(diffInSeconds / 60);
    return `${minutesAgo} min ago`;
  } else if (diffInSeconds < 86400) {
    const hoursAgo = Math.floor(diffInSeconds / 3600);
    return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
  } else {
    const daysAgo = Math.floor(diffInSeconds / 86400);
    return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
  }
};
