import { adminApi } from 'adminApp/api';
import { ProjectAttachment } from 'adminApp/models/create-attachment';
import { useState } from 'react';

export const useGetAll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ProjectAttachment[]>([]);

  const getAll = async (projectId: string, projectIterationId?: string) => {
    setIsLoading(true);
    setData([]);
    adminApi
      .getAllAttachment({
        projectId,
        projectIterationId,
      })
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        console.log('Unable to get project attachment');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteAttachment = async (
    attachmentId: string,
    projectId: string,
    projectIterationId: string
  ) => {
    adminApi
      .deleteAttachment(attachmentId)
      .then(() => {
        getAll(projectId, projectIterationId);
        alert('Deleted Project Attachment');
      })
      .catch(() => {
        alert('Unable to delete ProjectAttachment');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    data,
    getAll,
    deleteAttachment,
  };
};
