import { Chip, Modal, ModalBody, ModalContent, ModalHeader, ScrollShadow } from '@nextui-org/react';
import { Button } from 'presentation/components';
import { ProjectDeliverables } from '../../../projectDetail/components/ProjectDeliverables';
import { Project } from 'domain/modules';
import { ProjectIteration } from 'domain/modules/projects/models/iteration.model';
import { FileRenderer } from 'presentation/components/FileRenderer';
import { useLovsList } from 'adminApp/lov/hooks/useLovList';
import { useEffect, useState } from 'react';
import { IterationLovGroupName } from 'constants';

interface IProps {
  isOpen: boolean;
  onOpenChange: () => void;
  projectId: string;
  project: Project;
  iteration: ProjectIteration | undefined;
  iterationStatus: string;
}

export const IterationDetails = ({
  isOpen,
  onOpenChange,
  projectId,
  project,
  iteration,
  iterationStatus,
}: IProps) => {
  const [selectedIteration, setSelectedIteration] = useState<ProjectIteration | null>(null);
  const { lovs, getAllLovsByGroupName, isFetchingLovs } = useLovsList();

  useEffect(() => {
    getAllLovsByGroupName(IterationLovGroupName);
  }, []);

  useEffect(() => {
    if (iteration) {
      setSelectedIteration(iteration);
    } else {
      setSelectedIteration(null);
    }
  }, [iteration]);

  return (
    <Modal
      size="4xl"
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      closeButton={
        <Button isIconOnly variant="light">
          <i className="ri-close-fill ri-2x" />
        </Button>
      }
      className="pl-8 pr-5"
      classNames={{
        closeButton: 'p-0 mr-8 mt-5 data-[hover]:bg-transparent',
        body: 'pb-6',
      }}
    >
      <ModalContent className="py-3">
        {() => (
          <>
            <ModalHeader className="text-black text-xl font-bold">Iteration Details</ModalHeader>

            <ModalBody>
              <div className="flex gap-4 items-start justify-between">
                <div className="flex-1 flex flex-col gap-3">
                  <div className="flex gap-4 items-center">
                    <h5 className="text-[#232323] text-base font-semibold text-wrap">
                      Iteration Status
                    </h5>
                    <Chip
                      size="sm"
                      variant="bordered"
                      classNames={{
                        content: 'text-[#21A663] font-semibold',
                      }}
                    >
                      {iterationStatus}
                    </Chip>
                  </div>

                  <div>
                    <h5 className="text-[#232323] text-base font-semibold text-wrap">
                      Iteration Reason
                    </h5>
                    <h6 className="text-[#828282] text-sm">
                      {isFetchingLovs
                        ? ''
                        : lovs.find((l) => l.id === iteration?.reason)?.label || iteration?.reason}
                    </h6>
                  </div>

                  <div>
                    <h5 className="text-[#232323] text-base font-semibold text-wrap">
                      Design request description
                    </h5>
                    <ScrollShadow className="max-h-[30vh] overflow-y-auto pr-4 pb-4">
                      <h6 className="text-[#828282] text-sm">{iteration?.description}</h6>
                    </ScrollShadow>
                  </div>

                  <div>
                    <h5 className="text-[#232323] text-base font-semibold text-wrap">
                      Voice Notes
                    </h5>

                    {iteration &&
                    Array.isArray(iteration.iteration_voice_note) &&
                    iteration.iteration_voice_note.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        {iteration.iteration_voice_note.map((pv) => (
                          <audio controls key={pv.assetId}>
                            <source src={pv.media_url} type="audio/mpeg"></source>
                            <source src={pv.media_url} type="audio/ogg"></source>
                          </audio>
                        ))}
                      </div>
                    ) : (
                      '-'
                    )}
                  </div>

                  <div>
                    <h5 className="text-[#232323] text-base font-semibold text-wrap">
                      Attachments
                    </h5>

                    {iteration &&
                    Array.isArray(iteration.iteration_media_reference) &&
                    iteration.iteration_media_reference.length > 0 ? (
                      <div className="flex flex-wrap gap-2">
                        <FileRenderer files={iteration.iteration_media_reference} />
                      </div>
                    ) : (
                      '-'
                    )}
                  </div>
                </div>
                {selectedIteration ? (
                  <ProjectDeliverables
                    projectId={`${projectId}`}
                    project={project}
                    selectedIterationId={selectedIteration.id}
                    iterationView
                  />
                ) : null}
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
