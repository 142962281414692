import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@nextui-org/react';
import { useGetAll } from './hooks/useGetAll';
import { useCallback, useEffect } from 'react';
import { columns } from './columns';
import { CreateSubCategory } from './Create';
import { CreateSubCategoryDto } from 'adminApp/models/create-sub-category';
import { Loading } from 'adminApp/SharedComponents';

export const CategoryTable = ({ categoryId }: { categoryId: string }) => {
  const { data, isLoading, getAll, deleteCategory } = useGetAll();

  useEffect(() => {
    getAll(categoryId);
  }, [categoryId]);

  const renderCell = useCallback((listItem: CreateSubCategoryDto, columnKey: React.Key) => {
    const cellValue = listItem[columnKey as keyof CreateSubCategoryDto];

    switch (columnKey) {
      case 'actions':
        return (
          <div className="relative flex items-center gap-4">
            <CreateSubCategory
              categoryId={categoryId}
              isUpdate
              data={listItem}
              Trigger={({ open }) => {
                return (
                  <Tooltip content="Edit Sub Category">
                    <span
                      className="text-lg text-default-400 cursor-pointer active:opacity-50"
                      onClick={open}
                    >
                      <i className="ri-edit-box-line" />
                    </span>
                  </Tooltip>
                );
              }}
              refreshList={() => {
                getAll(categoryId);
              }}
            />

            <Tooltip color="danger" content="Delete Sub Category">
              <span
                className="text-lg text-danger cursor-pointer active:opacity-50"
                onClick={() => {
                  if (listItem.id) {
                    deleteCategory(categoryId, listItem.id);
                  }
                }}
              >
                <i className="ri-delete-bin-5-line" />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table aria-label="Example table with dynamic content" isHeaderSticky isStriped>
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={data}>
        {(item: CreateSubCategoryDto) => (
          <TableRow key={item.id}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
