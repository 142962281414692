import { useAppDispatch } from 'application/shared';
import { getAllProjects } from '../projects.thunks';
import { TaskStatusType } from 'domain/modules';
import { projectsService } from 'infra/api';

export const useProjectStatus = () => {
  const dispatch = useAppDispatch();

  const getAll = () => {
    return dispatch(getAllProjects());
  };

  const updateTaskStatus = async (projectId: string, toStatus: TaskStatusType) => {
    try {
      const data = await projectsService.updateProject(projectId, toStatus);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  };

  const deleteProject = async (projectId: string) => {
    try {
      const data = await projectsService.deleteProject(projectId);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  };

  return {
    getAll,
    updateTaskStatus,
    deleteProject,
  };
};
