import { Comments, Replies } from 'domain/modules';
import { projectsService } from 'infra/api';
import { useState } from 'react';

export const useProjectComments = () => {
  const [comments, setComments] = useState<Comments[]>([]);
  const [isFetching, setIsFetching] = useState(true);

  const getComments = async (projectId: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getProjectComments(projectId);
      setComments(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const addComment = async (projectId: string, comment: Comments) => {
    try {
      setIsFetching(true);
      const data = await projectsService.createProjectComment(projectId, comment);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const addReplyComment = async (reply: Replies) => {
    try {
      setIsFetching(true);
      const data = await projectsService.createReplyComment(reply);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    comments,
    getComments,
    addComment,
    addReplyComment,
  };
};
