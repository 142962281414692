import React, { useEffect } from 'react';
import './ChatWrapper.css'; // Import your CSS file for styling
import { cn } from '@nextui-org/react';
import { useSubscription } from 'application/modules/subscription';
import { GetEmail } from './EmailInputModal';
import { useAuth } from 'application/modules';

const ChatWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { subscription, get } = useSubscription();
  const { user, beUser, getUser } = useAuth();
  useEffect(() => {
    get();
    getUser();
  }, []);
  const handleChatClick = () => {
    window.open('https://discord.com/channels/1305141216408506368');
  };

  return (
    <div className="chat-wrapper">
      {children}
      {subscription && (
        <GetEmail
          id={user?.uid || ''}
          Trigger={({ open }) => (
            <div
              className="chat-icon"
              onClick={() => (beUser?.discordUserId ? handleChatClick() : open())}
            >
              <i className={cn('ri-discord-line')} style={{ fontSize: '50px' }} />
            </div>
          )}
        />
      )}
    </div>
  );
};

export default ChatWrapper;
