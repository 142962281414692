import { sampleProjectFIles } from 'presentation/constants';
import { Header } from './header';
import { Card } from '@nextui-org/react';
import { LazyImage } from 'presentation/components/LazyImage';

export const ListingSampleProject = () => {
  return (
    <>
      <Header title={'Sample Project'} />
      <div className="flex gap-6 mt-4">
        {sampleProjectFIles.map((project, index) => {
          return (
            <div className="cursor-pointer" key={index}>
              <Card radius="lg" className="border-none h-[250px] w-[250px]">
                <LazyImage
                  alt={project.name}
                  className="object-cover h-[250px] w-[250px] cursor-default"
                  height="100%"
                  src={project.file}
                  width="100%"
                />
              </Card>
              <p className="text-sm text-black text-center mt-2 font-medium">{project.name}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};
