import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from '@nextui-org/react';
import { useCreate } from './hooks/useCreate';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CreateSubCategoryDto } from 'adminApp/models/create-sub-category';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: CreateSubCategoryDto;
  categoryId: string;
}

export const CreateSubCategory = ({ refreshList, Trigger, isUpdate, data, categoryId }: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, update, reset } = useCreate();

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
    }),
    onSubmit: async (values) => {
      if (!isUpdate) {
        await create({
          name: values.name,
          category_id: categoryId,
        });
      } else {
        if (data && data.id) {
          await update(data.id, {
            name: values.name,
            category_id: categoryId,
          });
        }
      }

      reset();
      formik.resetForm();
      onClose();
      refreshList();
    },
  });

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        onClose={() => {
          reset();
          formik.resetForm();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Category
              </ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  label="Name"
                  placeholder="Enter a name"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => formik.submitForm()}
                  className="bg-black text-white"
                  disabled={isLoading || !formik.isValid}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Update' : 'Create'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
