import { createSlice } from '@reduxjs/toolkit';
import { createCompany, getCompany, getCompanySetupLov, updateCompany } from './company.thunks';
import { Company } from 'domain/modules';
import { Lov } from 'domain/shared/models/lov.model';

interface CompanyState {
  company: Company | null;
  isFetching: boolean;

  companySetupLov: Lov[];
}

const initialState: CompanyState = {
  company: null,
  isFetching: false,
  companySetupLov: [],
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    companyReset: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompany.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.isFetching = false;
      state.company = action.payload;
    });

    builder.addCase(getCompany.rejected, (state) => {
      state.isFetching = false;
      state.company = null;
    });

    builder.addCase(createCompany.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(createCompany.fulfilled, (state, action) => {
      state.isFetching = false;
      state.company = action.payload;
    });

    builder.addCase(createCompany.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(updateCompany.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.isFetching = false;
      state.company = action.payload;
    });

    builder.addCase(updateCompany.rejected, (state) => {
      state.isFetching = false;
    });

    builder.addCase(getCompanySetupLov.pending, (state) => {
      state.isFetching = true;
    });

    builder.addCase(getCompanySetupLov.fulfilled, (state, action) => {
      state.isFetching = false;
      state.companySetupLov = action.payload;
    });

    builder.addCase(getCompanySetupLov.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const { companyReset } = companySlice.actions;

export default companySlice.reducer;
