export const styleNameList = [
  {
    id: 'Standard',
    name: 'Standard',
  },
  {
    id: 'Intermediate',
    name: 'Intermediate',
  },
  {
    id: 'Pro',
    name: 'Pro',
  },
];

export const columns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'assetId',
    label: 'Asset ID',
  },
  {
    key: 'media_url',
    label: 'Media URL',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
