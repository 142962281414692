/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import axios from 'axios';
import { useState } from 'react';

export const PDFGenerator = ({ invoiceId }: { invoiceId: string }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  return (
    <div className="relative flex justify-end items-center gap-2">
      <Dropdown className="bg-background border-1 border-default-200">
        <DropdownTrigger>
          <Button isIconOnly radius="full" size="sm" variant="light">
            <i className="ri-more-2-line text-lg" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              if (!isDownloading) {
                setIsDownloading(true);
                axios
                  .get(`/api/credit-payments/invoice/download/${invoiceId}`)
                  .then((res) => {
                    if (window && res && res.data) {
                      (window as any).open(res.data, '_blank').focus();
                    }
                  })
                  .catch((ex) => {
                    console.error(ex);
                  })
                  .finally(() => {
                    setIsDownloading(false);
                  });
              }
            }}
          >
            <span className="text-xs">
              {isDownloading ? (
                <span className="text-gray-500">Waiting to generate file...</span>
              ) : (
                'Download Invoice'
              )}
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
