import { createSlice } from '@reduxjs/toolkit';
import { getAllProjects } from './projects.thunks';
import { Project } from 'domain/modules';

interface ProjectState {
  isFetchingProjectList: boolean;
  projectsList: Array<Project>;
}

const initialState: ProjectState = {
  isFetchingProjectList: true,
  projectsList: [],
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    projectsReset: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProjects.pending, (state) => {
      state.isFetchingProjectList = true;
    });

    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      state.isFetchingProjectList = false;
      state.projectsList = action.payload;
    });

    builder.addCase(getAllProjects.rejected, (state) => {
      state.isFetchingProjectList = false;
      state.projectsList = [];
    });
  },
});

// export const { projectsReset } = projectsSlice.actions;

export default projectsSlice.reducer;
