import { adminApi } from 'adminApp/api';
import { CreateSubCategoryDto } from 'adminApp/models/create-sub-category';
import { useState } from 'react';

export const useGetAll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CreateSubCategoryDto[]>([]);

  const getAll = async (categoryId: string) => {
    setIsLoading(true);
    setData([]);
    adminApi
      .getAllSubCategory(categoryId)
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        alert('Unable to get categories');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteCategory = async (categoryId: string, subCategoryId: string) => {
    adminApi
      .deleteCategory(subCategoryId)
      .then(() => {
        getAll(categoryId);
        alert('Deleted category');
      })
      .catch(() => {
        alert('Unable to delete categories');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    data,
    getAll,
    deleteCategory,
  };
};
