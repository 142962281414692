import './CommentStructure.scss';
import { useContext } from 'react';
import { GlobalContext } from '../../context/Provider';
import InputField from '../InputField/Index';
import '@szhsin/react-menu/dist/core.css';
import moment from 'moment';
import { Avatar } from '@nextui-org/react';

interface CommentStructureProps {
  info: {
    userId: string;
    comId: string;
    fullName: string;
    avatarUrl: string;
    text: string;
    userProfile?: string;
    updatedAt: string;
    replies?: Array<object> | undefined;
  };
  editMode: boolean;
  parentId?: string;
  replyMode: boolean;
  logIn: {
    loginLink: string;
    signupLink: string;
  };
}

const CommentStructure = ({ info, editMode, parentId, replyMode }: CommentStructureProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalStore: any = useContext(GlobalContext);
  const currentUser = globalStore.currentUserData;

  const replyBottomSection = () => {
    return (
      <div className="comment_section">
        <div className="userInfo flex">
          {/* <a className="userLink" target="_blank" href={info.userProfile} rel="noreferrer">
            {info.userId !== currentUser.currentUserId ? (
              <Avatar name="A" className="w-8 h-8" />
            ) : (
              <img src={info.avatarUrl} alt="userIcon" className="imgdefault" />
            )}
          </a> */}
          <a className="userLink" target="_blank" href={info.userProfile} rel="noreferrer">
            <Avatar
              name={info.fullName.slice(0, 1) || 'A'}
              className="w-5 h-5 imgdefault"
              color="primary"
              src={info.avatarUrl || info.userProfile}
              as="button"
              size="sm"
            />
          </a>
          <div className="userComment___">
            {info.userId !== currentUser.currentUserId ? (
              <h4 className="userName">Admin</h4>
            ) : (
              <h4 className="userName">{info.fullName}</h4>
            )}

            <div className="userComment flex">
              <h5 className="comment">{info.text}</h5>
              {currentUser && !parentId && (
                <button
                  className="replyBtn"
                  onClick={() => globalStore.handleAction(info.comId, false)}
                >
                  <div className="replyIcon" />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="c3">
          <p className="dateStyle">{moment(info.updatedAt).format('MMMM Do YYYY, HH:mm ')}</p>
        </div>
      </div>
    );
  };

  const actionModeSection = (mode: string) => {
    if (mode === 'reply') {
      return (
        <div className="replysection">
          {replyBottomSection()}
          <InputField
            formStyle={{
              backgroundColor: 'transparent',
              padding: '20px 0px',
              marginLeft: '-15px',
            }}
            comId={info.comId}
            fillerText={''}
            mode={'replyMode'}
            parentId={parentId}
          />
        </div>
      );
    } else {
      return (
        <InputField
          formStyle={{
            backgroundColor: 'transparent',
            padding: '20px 0px',
            marginLeft: '-15px',
          }}
          comId={info.comId}
          fillerText={info.text}
          mode={'editMode'}
          parentId={parentId}
        />
      );
    }
  };

  return (
    <div>
      {editMode
        ? actionModeSection('edit')
        : replyMode
        ? actionModeSection('reply')
        : replyBottomSection()}
    </div>
  );
};

export default CommentStructure;
