import axios from 'axios';
import { Faq, IFaqService } from 'domain/modules/faq';
import { has } from 'lodash';

class FaqService implements IFaqService {
  getFaqQuestions(): Promise<Array<Faq>> {
    return new Promise((resolve, reject) => {
      axios
        .get<Faq[]>(`/api/faq/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
}

export const faqService = new FaqService();
