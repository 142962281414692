import { useProjectDetail } from 'application/modules/projects';
import { Header } from './header';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Card } from '@nextui-org/react';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { LazyImage } from 'presentation/components/LazyImage';
import { NoSubscriptionAvailable } from './NoSubscriptionAvailable';

interface iterationType {
  audio_url: string;
  createdAt: string;
  description: string;
  id: string;
  isActive: boolean;
  project_id: string;
  reason: string;
}

export const ListingByProjectIterations = () => {
  const [searchParams] = useSearchParams();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { getProjectDetail, project, isFetching } = useProjectDetail();
  const [projectTitle, setProjectTitle] = useState('');

  useEffect(() => {
    if (projectId) {
      getProjectDetail(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    setProjectTitle(searchParams.get('projectLabel') || 'Project');
  }, []);

  const onOpenItem = (item: iterationType) => {
    navigate(`/projects/listing/project/${projectId}/iteration/${item.id}`, {
      state: { projectTitle: projectTitle },
    });
  };

  const pageTitle = () => {
    const label = searchParams.get('projectLabel') || 'Project';
    return decodeURIComponent(label);
  };

  if (isFetching) {
    return (
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '80vh',
        }}
      />
    );
  }

  if (!project || project.iteration.length === 0) {
    return (
      <>
        <Header title={pageTitle()} />
        <NoSubscriptionAvailable type="noResultsYet" />
      </>
    );
  }

  return (
    <>
      <Header title={pageTitle()} />
      <div className="flex gap-6 mt-4">
        {project.iteration.map((iteration, index) => {
          const deliverables = project.deliverable_item.find((d) => {
            return (
              d.project_iteration_id === iteration.id &&
              d.project_asset_type === 'SHOWCASE_FILES_THUMBNAIL'
            );
          });

          if (!deliverables) {
            return null;
          }

          return (
            <div className="cursor-pointer" key={index} onClick={() => onOpenItem(iteration)}>
              <Card radius="lg" className="border-none h-[250px] w-[250px]">
                <LazyImage
                  alt={project.name}
                  className="object-cover h-[250px] w-[250px]"
                  height="100%"
                  src={deliverables?.asset?.url || ''}
                  width="100%"
                />
              </Card>
              <p className="text-sm text-black text-center mt-2 font-medium">
                {index === 0 ? 'Original' : `Iteration ${index}`}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
