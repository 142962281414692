import { useDisclosure } from '@nextui-org/react';
import { cn } from '@nextui-org/system';
import { useProjectDeliverables } from 'application/modules/projects/hooks/useProjectDeliverables';
import { DeliveryAssetType, Project, ProjectAttachmentResponse } from 'domain/modules';
import empty_attachment from 'presentation/assets/empty_attachment.png';
import folderImage from 'presentation/assets/folder.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { DeliverablesPreview } from './DeliverablesPreview';
import { Button } from 'presentation/components';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { LazyImage } from 'presentation/components/LazyImage';

interface IProps {
  projectId?: string;
  project?: Project;
  iterationView?: boolean;
  iterationRequest?: boolean;
  loaderAutoHeight?: boolean;
  selectedIterationId?: string;
}

export const ProjectDeliverables = ({
  projectId,
  project,
  iterationView,
  iterationRequest,
  loaderAutoHeight = false,
  selectedIterationId,
}: IProps) => {
  const {
    isFetching,
    deliveryAttachments,
    getAttachments,
    attachmentsByType,
    getAttachmentsByType,
  } = useProjectDeliverables();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [currentIteration] = useState(
    project && project.iteration.length > 0
      ? project.iteration[project.iteration.length - 1].id
      : ''
  );

  const [showcaseThumbnail, setShowCaseThumbnail] = useState<ProjectAttachmentResponse | null>(
    null
  );
  const [sourceThumbnail, setSourceThumbnail] = useState<ProjectAttachmentResponse | null>(null);
  const [mockupThumbnail, setMockupThumbnail] = useState<ProjectAttachmentResponse | null>(null);

  const [showcaseFiles, setShowCaseFiles] = useState<ProjectAttachmentResponse[]>([]);
  const [mockupFile, setMockupFile] = useState<ProjectAttachmentResponse | null>(null);

  const [previeMode, setPreviewMode] = useState<'DESIGN' | 'MOCKUP'>('DESIGN');
  const [previewAssetType, setPreviewAssetType] = useState<DeliveryAssetType>(
    'SHOWCASE_FILES_THUMBNAIL'
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (projectId && project && project.iteration.length > 0) {
      let iterationId;

      if (!selectedIterationId) {
        if (
          project.status === 'COMPLETED' ||
          (project.status === 'IN_PROGRESS' && project.iteration.length === 1)
        ) {
          iterationId = project.iteration[project.iteration.length - 1].id;
        } else {
          if (
            !project.iteration[project.iteration.length - 1].isActive ||
            project.status === 'IN_PROGRESS'
          ) {
            iterationId = project.iteration[project.iteration.length - 2].id;
          } else {
            iterationId = project.iteration[project.iteration.length - 1].id;
          }
        }
      } else {
        iterationId = selectedIterationId;
      }

      getAttachments(projectId, iterationId);
    }
  }, [selectedIterationId]);

  useEffect(() => {
    if (projectId && previewAssetType) {
      getAttachmentsByType(projectId, previewAssetType);
    }
  }, [projectId, previewAssetType]);

  useEffect(() => {
    if (deliveryAttachments && deliveryAttachments.length > 0) {
      const fileThumbnail = deliveryAttachments.find(
        (d) => d.project_asset_type === 'SHOWCASE_FILES_THUMBNAIL'
      );

      if (fileThumbnail) {
        setShowCaseThumbnail(fileThumbnail);
      }

      const sourcefileThumbnail = deliveryAttachments.find(
        (d) => d.project_asset_type === 'SOURCE_FILES_THUMBNAIL'
      );

      if (sourcefileThumbnail) {
        setSourceThumbnail(sourcefileThumbnail);
      }

      const mockupfileThumbnail = deliveryAttachments.find(
        (d) => d.project_asset_type === 'MOCKUP_FILES_THUMBNAIL'
      );

      if (mockupfileThumbnail) {
        setMockupThumbnail(mockupfileThumbnail);
      }

      const showcaseFiles = deliveryAttachments.filter(
        (d) => d.project_asset_type === 'SHOWCASE_FILES'
      );

      if (showcaseFiles) {
        setShowCaseFiles(showcaseFiles);
      }

      const currentMockupFile = deliveryAttachments.find(
        (d) => d.project_asset_type === 'MOCKUP_FILES'
      );

      if (currentMockupFile) {
        setMockupFile(currentMockupFile);
      }
    }
  }, [deliveryAttachments]);

  const openProject = () => {
    if (project) {
      let iterationId;
      if (!selectedIterationId) {
        if (
          !project.iteration[project.iteration.length - 1].isActive ||
          project.status === 'IN_PROGRESS'
        ) {
          iterationId = project.iteration[project.iteration.length - 2].id;
        } else {
          iterationId = project.iteration[project.iteration.length - 1].id;
        }
      } else {
        iterationId = selectedIterationId;
      }

      navigate(`/projects/listing/project/${projectId}/iteration/${iterationId}`, {
        state: { projectTitle: project.name },
      });
    }
  };

  const changePreview = (projectId: string, iterationId: string) => {
    getAttachments(projectId, iterationId);
  };

  const openPreview = (isDesignPreview: boolean) => {
    if (!isDesignPreview) {
      setPreviewMode('MOCKUP');
      setPreviewAssetType('MOCKUP_FILES');
    } else {
      setPreviewMode('DESIGN');
      setPreviewAssetType('SHOWCASE_FILES_THUMBNAIL');
    }
    onOpen();
  };

  if (isFetching || !deliveryAttachments) {
    return (
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: loaderAutoHeight ? 'auto' : '50vh',
        }}
      />
    );
  }

  if (deliveryAttachments.length === 0) {
    return (
      <div
        className={cn(
          'bg-black-300 relative border border-black-300 overflow-hidden',
          !iterationView ? 'w-[400px] h-[475px]' : 'w-[264px] h-[264px]'
        )}
      >
        <LazyImage
          className="attachment w-full h-full object-cover"
          src={empty_attachment}
          alt="no_attachment"
        />
      </div>
    );
  }

  if (iterationView) {
    return (
      <div className="flex flex-col gap-2 items-center">
        {iterationRequest ? null : (
          <h4 className="text-[#232323] text-base font-semibold self-start">Attachments</h4>
        )}

        <LazyImage
          className={cn(
            'w-[264px] h-[264px]',
            iterationRequest ? 'rounded-md' : 'attachment object-cover'
          )}
          src={showcaseThumbnail?.asset.url}
          alt="no_attachment"
        />
        {!iterationRequest ? (
          <div className="text-right">
            <Button
              size="sm"
              variant="bordered"
              className={cn('border border-solid border-black', 'rounded-[42px]')}
              onClick={openProject}
            >
              View Project folder
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <>
      <div className="w-[420px] bg-black-300 relative overflow-hidden flex flex-col justify-between">
        <div className="flex mb-3 gap-2 ">
          <LazyImage
            className="w-[206px] h-48 cursor-pointer"
            src={showcaseThumbnail?.asset.url}
            alt="design"
            onClick={() => openPreview(true)}
          />

          <LazyImage
            className="w-[206px] h-48 cursor-pointer"
            src={sourceThumbnail?.asset.url}
            alt="source_file"
            onClick={() => openPreview(true)}
          />
        </div>

        <div>
          <LazyImage
            className="cursor-pointer w-[420px] h-60"
            src={mockupThumbnail?.asset.url}
            alt="mockup"
            onClick={() => openPreview(false)}
          />
        </div>

        <div
          onClick={openProject}
          className="w-[420px] flex gap-2 items-center py-2 px-4 bg-[#F1F3F5] hover:bg-[#b4b7bb] mt-2 cursor-pointer"
        >
          <LazyImage src={folderImage} alt="Project Name" className="w-[25px] h-[21px]" />
          <span className="text-sm font-medium">{project?.name}</span>
        </div>
      </div>

      <DeliverablesPreview
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        showcaseFiles={showcaseFiles}
        mockupFile={mockupFile}
        previeMode={previeMode}
        project={project}
        projectId={projectId}
        previewAssetType={previewAssetType}
        currentIteration={currentIteration}
        attachmentsByType={attachmentsByType}
        changePreview={changePreview}
        isFetching={isFetching}
      />
    </>
  );
};
