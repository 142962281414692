import { Outlet } from 'react-router-dom';
import Header from '../header';
import ChatWrapper from '../DiscordFloatButton';

export const Layout = () => {
  return (
    <div className="h-full flex flex-col relative">
      <ChatWrapper>
        <Header />
        <Outlet />
      </ChatWrapper>
    </div>
  );
};
