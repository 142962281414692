import { useEffect, useState } from 'react';
import { has } from 'lodash';
import { User } from 'firebase/auth';

import { authReset, setFirebaseUser } from 'application/modules';
import { brandReset } from 'application/modules/brand';
import { companyReset } from 'application/modules/company';
import { useAppDispatch } from 'application/shared';
import { userService } from 'infra/api';
import { listenToAuthChanges } from 'infra/providers/firebase';
import { logout } from 'application/modules/auth/auth.thunks';
import { getNotificationToken } from 'infra/providers/firebase/notifications';

interface FirebaseUserWithToken extends User {
  stsTokenManager: {
    accessToken: string;
  };
}

export const FirebaseWrapper = ({ children }: { children: React.ReactElement }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const checkAccessToken = (_user: User) => {
    if (has(_user, 'stsTokenManager.accessToken')) {
      const token = (_user as unknown as FirebaseUserWithToken).stsTokenManager.accessToken;
      userService.setBearerToken(token);
    }
  };

  useEffect(() => {
    const onLoggedIn = (user: User) => {
      checkAccessToken(user);
      setLoading(false);
      dispatch(setFirebaseUser(user));
      getNotificationToken();
    };

    const onLoggedOut = () => {
      setLoading(false);
      userService.setBearerToken('');

      setTimeout(() => {
        dispatch(authReset());
        dispatch(brandReset());
        dispatch(companyReset());
      }, 3000);
    };

    const unsubscribe = listenToAuthChanges(onLoggedIn, onLoggedOut);

    userService.registerAuthInterceptor(function () {
      dispatch(logout());
    });

    return () => {
      if (unsubscribe) unsubscribe();
      userService.unregisterAuthInterceptor();
    };
  }, [dispatch]);

  return loading ? null : children;
};
