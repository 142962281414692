import { adminApi } from 'adminApp/api';
import { ProjectType } from 'adminApp/models/create-type';
import { useState } from 'react';

export const useGetAll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ProjectType[]>([]);

  const getAll = async (subcategoryId: string) => {
    setIsLoading(true);
    setData([]);
    adminApi
      .getAllTypes(subcategoryId)
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        alert('Unable to get types');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteType = async (subCategoryId: string, typeId: string) => {
    adminApi
      .deleteTypes(typeId)
      .then(() => {
        getAll(subCategoryId);
        alert('Deleted type');
      })
      .catch(() => {
        alert('Unable to delete type');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    data,
    getAll,
    deleteType,
  };
};
