import { Footer } from './components/footer';
import { Header } from './components/header';
import { LeftContent } from './components/left-content';
import { PageContainer } from './components/PageContainer';
import { RightContent } from './components/right-content';
import { ProjectDetailProvider } from './context';

export const ProjectDetailScreen = () => {
  return (
    <ProjectDetailProvider>
      <PageContainer.Root>
        <PageContainer.Header>
          <Header />
        </PageContainer.Header>

        <PageContainer.Content>
          <PageContainer.LeftContent>
            <LeftContent.Root>
              <LeftContent.Details />
              <LeftContent.Activity.Root
                items={[
                  {
                    id: 'comments',
                    label: 'Comments',
                    content: LeftContent.Activity.Comments,
                  },
                  {
                    id: 'iterations',
                    label: 'Iterations',
                    content: LeftContent.Activity.Iterations,
                  },
                ]}
              />
            </LeftContent.Root>
          </PageContainer.LeftContent>
          <PageContainer.RightPanel>
            <RightContent />
          </PageContainer.RightPanel>
        </PageContainer.Content>

        <PageContainer.Footer>
          <Footer />
        </PageContainer.Footer>
      </PageContainer.Root>
    </ProjectDetailProvider>
  );
};
