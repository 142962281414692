import { Select, SelectItem } from '@nextui-org/react';
import { useCompanies } from './hooks/useCompanies';
import { useProjects } from './hooks/useProjects';
import { useEffect, useState } from 'react';
import { CommentSection } from 'presentation/vendors/react-comment-section';
import 'presentation/vendors/react-comment-section/Index.scss';
import { useProjectComments } from 'application/modules/projects';
import { Comments, Replies } from 'domain/modules';
import { useAuth } from 'application/modules';
import { useUsers } from './hooks/useUsers';

export const CommentsManagement = () => {
  const { users, isFetching: isFetchingUsers, getAll: getAllUsers } = useUsers();
  const {
    companies,
    isFetching: isFetchingCompanies,
    getAll: getAllCompanies,
    getCompanyForUsers,
    company,
  } = useCompanies();
  const {
    projects,
    isFetching: isFetchingProjects,
    getAll: getAllProject,
    getProjectById,
    project,
  } = useProjects();
  const { comments, getComments, addComment, addReplyComment } = useProjectComments();
  const { beUser } = useAuth();

  const [seletedUser, setSelectedUser] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedProject, setSelectedProject] = useState('');

  useEffect(() => {
    getAllUsers();
    getAllCompanies();
  }, []);

  useEffect(() => {
    getCompanyForUsers(seletedUser);
  }, [seletedUser]);

  useEffect(() => {
    if (selectedProject) {
      getProjectById(selectedProject);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedCompany) {
      getAllProject(selectedCompany);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (project) {
      getComments(project.id);
    }
  }, [project]);

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-semibold">Project Management</h1>

      <Select
        placeholder="Select a User"
        label="User"
        labelPlacement="outside"
        className="max-w-xs"
        isLoading={isFetchingUsers}
        defaultSelectedKeys={seletedUser ? [seletedUser] : []}
        onChange={(ev) => setSelectedUser(ev.target.value)}
      >
        {users.map((user) => (
          <SelectItem key={String(user.id)} value={String(user.id)}>
            {user.email}
          </SelectItem>
        ))}
      </Select>

      {company ? (
        <div>
          <h5>Current User company id: {company.id}</h5>
        </div>
      ) : null}

      <Select
        placeholder="Select a company"
        label="Company"
        labelPlacement="outside"
        className="max-w-xs"
        isLoading={isFetchingCompanies}
        defaultSelectedKeys={selectedCompany ? [selectedCompany] : []}
        onChange={(ev) => setSelectedCompany(ev.target.value)}
      >
        {companies.map((company) => (
          <SelectItem key={String(company.id)} value={String(company.id)}>
            {company.id}
          </SelectItem>
        ))}
      </Select>
      <div className="flex gap-4 items-end">
        <Select
          placeholder="Select a project"
          label="Project"
          labelPlacement="outside"
          className="max-w-xs"
          isLoading={isFetchingProjects}
          defaultSelectedKeys={selectedProject ? [selectedProject] : []}
          onChange={(ev) => setSelectedProject(ev.target.value)}
        >
          {projects.map((project) => (
            <SelectItem key={project.id} value={project.name}>
              {project.name}
            </SelectItem>
          ))}
        </Select>
        {selectedProject && project ? (
          <CommentSection
            currentUser={{
              currentUserId: beUser ? beUser.id : '',
              currentUserImg: beUser ? beUser.profile_picture : '',
              currentUserProfile: beUser ? beUser.profile_picture : '',
              currentUserFullName: beUser ? beUser.name : '',
            }}
            logIn={{
              loginLink: 'http://picasso.com/',
              signupLink: 'http://picasso.com/',
            }}
            commentData={comments}
            onSubmitAction={async (data: Comments) => {
              await addComment(project.id, data);
              if (project) {
                getComments(project.id);
              }
            }}
            onReplyAction={async (data: Replies) => {
              await addReplyComment(data);
              if (project) {
                getComments(project.id);
              }
            }}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            currentData={(data: any) => {
              console.log('curent data', data);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};
