export const columns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'name',
    label: 'NAME',
  },
  {
    key: 'min_credit',
    label: 'Minimum Credit',
  },
  {
    key: 'max_credit',
    label: 'Maximum Credit',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
