import { Chip, Input } from '@nextui-org/react';
import { Brand } from 'domain/modules/brand';
import { Button } from 'presentation/components';
import { useEffect } from 'react';
import { FontUploader } from '../FontPicker';
import { AssetUploadResponse } from 'infra/api';

interface FontFileData {
  id?: string;
  name: string;
  url: string;
  assetId?: string;
  brandId?: string;
}

interface IProps {
  updateStaticObject: (key: keyof Brand, colors: Array<FontFileData>) => void;
  data: FontFileData[];
  fontFiles: FontFileData[];
  setFontFiles: (val: FontFileData[]) => void;
  brandId: string;
}

export const Fonts = ({
  data,
  fontFiles: fontsList,
  setFontFiles: setFontsList,
  brandId,
}: IProps) => {
  useEffect(() => {
    setFontsList(
      data.length > 0
        ? data
        : [
            {
              id: `temp-1`,
              name: '',
              url: '',
              // assetId: '',
            },
            {
              id: `temp-2`,
              name: '',
              url: '',
              // assetId: '',
            },
          ]
    );
  }, [data]);

  const handleChange = (id: string, value: string) => {
    const tempData = Array.isArray(fontsList) ? fontsList : [];
    const data = tempData.map((font) => {
      if (id === font.id) {
        return {
          ...font,
          name: value,
        };
      }

      return font;
    });

    setFontsList(data);
  };

  const handleSaveUploadFile = (id: string, asset: AssetUploadResponse) => {
    const updatedFont = fontsList.map((font) => {
      if (id === font.id) {
        return { ...font, url: asset.url, assetId: asset.id };
      }
      return font;
    });

    setFontsList(updatedFont);
  };

  const handleDelete = async (font: FontFileData) => {
    handleChange(font.id || '', '');

    if (font && font.assetId) {
      const fontId = font.id;
      const updatedFont = fontsList.map((font) => {
        if (font.id === fontId) {
          delete font.assetId;

          return { ...font, url: '' };
        }
        return font;
      });

      setFontsList(updatedFont);
    }
  };

  return (
    <div className="fonts_container overflow-x-hidden">
      {!Array.isArray(fontsList)
        ? null
        : fontsList.map((fontItem, index) => (
            <div key={fontItem.id || index} className="p-0 mb-4">
              <div className="w-full flex gap-1 items-center">
                <Input
                  label=""
                  variant="bordered"
                  placeholder={`Enter ${index === 0 ? 'Primary' : 'Secondary'} font here`}
                  value={fontItem.name ?? ''}
                  onChange={(ev) => handleChange(fontItem.id || '', ev.target.value)}
                  classNames={{
                    inputWrapper: 'rounded-md border ml-0',
                  }}
                  startContent={
                    !fontItem.assetId ? null : (
                      <Chip className="w-48">
                        {index === 0 ? 'Primary Font' : 'Secondary Font'}
                      </Chip>
                    )
                  }
                  endContent={
                    <div className="flex items-center">
                      <FontUploader
                        path={`brand/${brandId}/fonts`}
                        hasUploadedFiles={!fontItem.assetId}
                        handleSaveUploadFile={handleSaveUploadFile}
                        fileLength={0}
                        id={fontItem.id || ''}
                      />

                      <Button
                        isIconOnly
                        variant="light"
                        className="data-[hover]:bg-transparent"
                        // onClick={() => handleChange(fontItem.id || '', '')}
                        onClick={() => handleDelete(fontItem)}
                        isDisabled={!(fontItem.name.length > 0) && !fontItem.assetId}
                      >
                        <i className="ri-delete-bin-5-line "></i>
                      </Button>
                    </div>
                  }
                />
              </div>
            </div>
          ))}
    </div>
  );
};
