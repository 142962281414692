import { cn } from '@nextui-org/react';
import { Project } from 'domain/modules';
import { Button } from 'presentation/components';

interface IProps {
  project: Project;
}

export const IterationStatusChip = (props: IProps) => {
  const hasARequestedIteration = () => {
    return props.project.iteration.filter((p) => p.isActive === false).length > 0;
  };

  if (hasARequestedIteration()) {
    return (
      <Button
        variant="bordered"
        radius="lg"
        className={cn('border-2 border-solid border-[#F2F2F2]', 'text-[#268FB0]')}
        disabled
      >
        Iteration Requested
      </Button>
    );
  } else if (props.project.iteration.length > 1) {
    if (props.project.status === 'IN_PROGRESS') {
      return (
        <Button
          variant="bordered"
          radius="lg"
          className={cn('border-2 border-solid border-[#F2F2F2]', 'text-[#FF5C00]')}
          disabled
        >
          Iteration In Progress
        </Button>
      );
    } else {
      return (
        <Button
          variant="bordered"
          radius="lg"
          className={cn('border-2 border-solid border-[#F2F2F2]', 'text-[#188544]')}
          disabled
        >
          Iteration Completed
        </Button>
      );
    }
  }

  return null;
};
