import { useWorkboard } from 'application/modules/projects';
import { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { onDragEnd } from './utils';
import { columnList } from '../../../application/modules/projects/constants';
import { KanbanColumn } from './components/KanbanColumn';
import { TaskCard } from './components/TaskCard';
import { Subscription, TaskStatusType } from 'domain/modules';
import { canTransition } from 'application/modules/projects/utils';
import { NoProjectsAvailable } from './components/NoProjectsAvailable';
import { useDisclosure } from '@nextui-org/react';
import { InsufficientCredits } from './components/InsufficientCredits';

interface IProps {
  updateProjectStatus: (projectId: string, status: TaskStatusType) => void;
  subscription: Subscription | null;
  getSubscriptionData: () => void;
  isFetchingSubscription: boolean;
  onOpen: () => void;
}

export const KanbanBoard = ({
  updateProjectStatus,
  subscription,
  getSubscriptionData,
  isFetchingSubscription,
  onOpen,
}: IProps) => {
  const { data, projectsList } = useWorkboard();
  const [columns, setColumns] = useState(data);
  const {
    isOpen: creditsModalIsOpen,
    onOpen: creditsModalOnOpen,
    onClose: creditsModalOnClose,
    onOpenChange: creditsModalOnOpenChange,
  } = useDisclosure();

  useEffect(() => {
    setColumns(data);
  }, [data]);


  const isCreditsExhausted = (id: string) => {
    const currentProject = projectsList.find((p) => p.id === id);

    if (subscription && currentProject) {
      const totalCredits = subscription.total_purchased_credits;
      const usedCredits = subscription.used_credits;

      return totalCredits - usedCredits < currentProject.credits ? true : false;
    } else {
      return false;
    }
  };

  const isIterationRequested = (projectId: string) => {
    const currentProject = projectsList.find((p) => p.id === projectId);
    if (currentProject) {
      return currentProject.iteration.filter((p) => p.isActive === false).length > 0;
    }
  };

  const isStatusIsInDraft = (projectId: string) => {
    const currentProject = projectsList.find((p) => p.id === projectId);
    if (currentProject) {
      return currentProject.status === 'IN_ACTIVE';
    }
  };

  const projectsInLine = () => {
    const temp = projectsList.filter(
      (project) =>
        project.status === 'START_DESIGNING' ||
        project.status === 'IN_PROGRESS' ||
        project.status === 'IN_REVIEW'
    );

    if (temp.length > 0) {
      return temp;
    }
    return [];
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;

    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId === 'START_DESIGNING' && projectsInLine().length > 0) {
        alert(
          'Only one project card can be moved at a time. Complete the current project card before starting a new one.'
        );
        return;
      } else if (
        destination.droppableId === 'START_DESIGNING' &&
        isCreditsExhausted(result.draggableId)
      ) {
        creditsModalOnOpen();
        return;
      } else if (
        destination.droppableId === 'COMPLETED' &&
        isIterationRequested(result.draggableId)
      ) {
        alert(`Couldn't move current project to next state as Iteration is Requested`);
        return;
      } else if (source.droppableId === 'IN_REVIEW' && destination.droppableId === 'IN_PROGRESS') {
        alert(
          "Only an admin can move project cards from 'In Review' to 'In Progress.' Please contact an admin for this action."
        );
        return;
      } else if (destination.droppableId === 'START_DESIGNING' && isStatusIsInDraft(draggableId)) {
        alert(
          "Draft project cards cannot be moved to the 'Start Designing' phase. Please complete the draft by providing all mandatory details before proceeding."
        );
        return;
      } else {
        if (
          canTransition(
            source.droppableId as TaskStatusType,
            destination.droppableId as TaskStatusType
          )
        ) {
          const projectId = draggableId;
          const toStatus = destination.droppableId as TaskStatusType;
          updateProjectStatus(projectId, toStatus);
        }
      }
    }

    onDragEnd(result, columns, setColumns);
  };

  if (projectsList.length === 0) {
    return (
      <NoProjectsAvailable
        isFetchingSubscription={isFetchingSubscription}
        subscription={subscription}
        onOpen={onOpen}
      />
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="flex gap-6 h-full w-full flex-nowrap grow py-7 px-8">
          {columnList.map((column) => (
            <KanbanColumn
              key={column.id}
              column={column}
              canShowPlaceholder={projectsInLine().length === 0}
              disableColumn={projectsList.length === 0}
            >
              <>
                {((columns[column.id] || {}).tasks || []).map((item, index) => (
                  <TaskCard
                    task={item}
                    key={item.id}
                    index={index}
                    projectsList={projectsList}
                    subscription={subscription}
                    getSubscriptionData={getSubscriptionData}
                  />
                ))}
              </>
            </KanbanColumn>
          ))}
        </div>
      </DragDropContext>

      <InsufficientCredits
        isOpen={creditsModalIsOpen}
        onOpen={creditsModalOnOpen}
        onClose={creditsModalOnClose}
        creditsModalOnOpenChange={(...args) => {
          getSubscriptionData();
          creditsModalOnOpenChange(...args);
        }}
      />
    </>
  );
};
