import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  RadioGroup,
  Radio,
  Select,
  SelectItem,
  Textarea,
} from '@nextui-org/react';
import { useCreate } from './hooks/useCreate';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { StyleType } from 'adminApp/models/create-form-with-combination';
import { DynamicFields } from 'adminApp/models/create-dynamic-field';
import { fieldTypes } from './columns';
import { useLovGroupNames } from './hooks/useLovGroupNames';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: DynamicFields;
  subcategoryId: string | null;
  categoryId: string;
  typeID: string | null;
  styleName: StyleType | null;
}

export const CreateField = ({
  refreshList,
  Trigger,
  isUpdate,
  data,
  categoryId,
  subcategoryId,
  typeID,
  styleName,
}: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, update, reset } = useCreate();
  const { lovGroupNames, isFetchingLOVGroupNames, getAll } = useLovGroupNames();

  const stringifyValidations = (str: string) => {
    try {
      return JSON.stringify(str);
    } catch (ex) {
      return '';
    }
  };

  const formik = useFormik({
    initialValues: {
      categoryId,
      subcategoryId,
      typeID,
      styleName,

      label: data?.label || '',
      detailViewLabel: data?.detailViewLabel || '',
      group_name: data?.group_name || '',
      field_name: data?.field_name || '',
      field_type: data?.field_type || '',
      fields_per_row: String(data?.fields_per_row || ''),
      // style: {},
      lov_group_name: data?.lov_group_name || '',
      is_conditional: data?.is_conditional || '',
      conditional_field_id: data?.conditional_field_id ? String(data?.conditional_field_id) : null,
      conditional_group_id: data?.conditional_group_id ? String(data?.conditional_group_id) : null,
      validations: stringifyValidations(data?.validations || ''),
      order: data?.order || 0,
    },
    validationSchema: Yup.object({
      label: Yup.string().max(100, 'Must be 15 characters or less').required('Required'),
      group_name: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      field_name: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      field_type: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      fields_per_row: Yup.number().required('Required'),
      order: Yup.number().required('Required'),
    }),
    onSubmit: async (values) => {
      if (!isUpdate) {
        await create({
          categoryId,
          subcategoryId,
          typeID,
          styleName,

          label: values.label,
          detailViewLabel: values.detailViewLabel,
          group_name: values.group_name,
          field_name: values.field_name,
          field_type: values.field_type,
          fields_per_row: parseInt(values.fields_per_row),
          // style: {},
          lov_group_name: values.lov_group_name,
          is_conditional: values.is_conditional === 'true' ? true : false,
          conditional_field_id: values.conditional_field_id,
          conditional_group_id: values.conditional_group_id,
          validations: values.validations,
          order: values.order,
        });
      } else {
        if (data && data.id) {
          await update(data.id, {
            categoryId,
            subcategoryId,
            typeID,
            styleName,

            label: values.label,
            detailViewLabel: values.detailViewLabel,
            group_name: values.group_name,
            field_name: values.field_name,
            field_type: values.field_type,
            fields_per_row: parseInt(values.fields_per_row),
            // style: {},
            lov_group_name: values.lov_group_name,
            is_conditional: values.is_conditional === 'true' ? true : false,
            conditional_field_id: values.conditional_field_id,
            conditional_group_id: values.conditional_group_id,
            validations: values.validations,
            order: values.order,
          });
        }
      }

      reset();
      formik.resetForm();
      onClose();
      refreshList();
    },
  });

  useEffect(() => {
    if (isOpen) {
      getAll();
      reset();
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        scrollBehavior="inside"
        onClose={() => {
          reset();
          formik.resetForm();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Type
              </ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  label="Label"
                  placeholder="Enter a label"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.label}
                  name="label"
                />
                <Input
                  autoFocus
                  label="Detail View Label"
                  placeholder="Enter a detail view label"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.detailViewLabel}
                  name="detailViewLabel"
                />
                <Input
                  autoFocus
                  label="Group Name"
                  placeholder="Enter the group name"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.group_name}
                  name="group_name"
                />
                <Input
                  autoFocus
                  label="Field Name"
                  placeholder="Enter the field name"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.field_name}
                  name="field_name"
                />

                <Input
                  label="Order"
                  type="number"
                  placeholder="Enter a order"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.order)}
                  name="order"
                />

                <Select
                  items={fieldTypes}
                  label="Field Type"
                  placeholder="Select a Field Type"
                  classNames={{
                    trigger: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  defaultSelectedKeys={formik.values.field_type ? [formik.values.field_type] : []}
                  onChange={formik.handleChange}
                  name="field_type"
                >
                  {(item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.label}
                    </SelectItem>
                  )}
                </Select>
                {['select', 'multiselect'].includes(formik.values.field_type) ? (
                  <Select
                    items={lovGroupNames}
                    label="LOV Group Name"
                    placeholder="Select a Lov Group Name"
                    classNames={{
                      trigger: 'rounded-md',
                    }}
                    isLoading={isFetchingLOVGroupNames}
                    labelPlacement="outside"
                    defaultSelectedKeys={formik.values.field_type ? [formik.values.field_type] : []}
                    onChange={formik.handleChange}
                    name="lov_group_name"
                  >
                    {(item) => (
                      <SelectItem key={item.label} value={item.label}>
                        {item.label}
                      </SelectItem>
                    )}
                  </Select>
                ) : null}
                <Input
                  type="number"
                  label="Fields per row"
                  placeholder="Enter number of fields per row"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.fields_per_row)}
                  name="fields_per_row"
                />
                <Textarea
                  label="Validations"
                  placeholder="Enter your validation JSON"
                  labelPlacement="outside"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.validations ? String(formik.values.validations) : ''}
                  name="validations"
                />
                <RadioGroup
                  label="Is a Conditional Field"
                  name="is_conditional"
                  value={String(formik.values.is_conditional || '')}
                  onChange={formik.handleChange}
                  orientation="horizontal"
                >
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </RadioGroup>
                {formik.values.is_conditional ? (
                  <>
                    {/* <Input
                      autoFocus
                      label="Conditional Field ID"
                      placeholder="Enter the conditional Field ID"
                      classNames={{
                        inputWrapper: 'rounded-md',
                      }}
                      labelPlacement="outside"
                      onChange={formik.handleChange}
                      value={String(formik.values.conditional_field_id)}
                      name="conditional_field_id"
                    /> */}
                    <Input
                      autoFocus
                      label="Conditional Group ID"
                      placeholder="Enter the conditional group ID"
                      classNames={{
                        inputWrapper: 'rounded-md',
                      }}
                      labelPlacement="outside"
                      onChange={formik.handleChange}
                      value={String(formik.values.conditional_group_id || '')}
                      name="conditional_group_id"
                    />
                  </>
                ) : null}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => formik.submitForm()}
                  className="bg-black text-white"
                  disabled={isLoading || !formik.isValid}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Update' : 'Create'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
