import { useNavigate, useParams } from 'react-router-dom';
import { StepFieldName, Steps, StepsKeys } from './wizard';
import { useEffect } from 'react';
import { useAuth } from 'application/modules';
import { useCompany } from 'application/modules/company';

import './style.css';
import { Company } from 'domain/modules';
import { cn, useDisclosure } from '@nextui-org/react';
import { LogoutModal } from 'presentation/components/LogoutModal';
import { OnboardingNavbar } from './components/Navbar';
import { Auth, getAuth, User } from 'firebase/auth';
import { app } from 'infra/providers/firebase/setup';

const auth: Auth = getAuth(app);

export const CompanySetupScreen = () => {
  const { step } = useParams();
  const { beUser, getUser } = useAuth();
  const { get, create, fetchLov, update, company, isFetching, companySetupLov } = useCompany();
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  
  useEffect(() => {
    if (beUser?.company_id && beUser?.company_step_number === 'complete') {
      navigate('/workboard');
    }
  }, [beUser, navigate]);

  useEffect(() => {
    if (!company && !isFetching) {
      if (beUser?.company_id) {
        get();
      } else {
        create();
      }
    }
  }, [company, isFetching, get, beUser?.company_id, create]);

  useEffect(()=>{
    getUser()
  }, [])

  useEffect(() => {
    if (company && !isFetching && beUser && companySetupLov.length === 0) {
      fetchLov();
    }
  }, [company, isFetching, beUser, companySetupLov, fetchLov]);

  const updateData = async (company: Company, stepName: string) => {    
    await update(company, stepName);
    if (stepName === 'complete') {      
      getUser();
      // navigate(`/workboard`);
    }
  };

  const Component = Steps[step as StepsKeys];
  const fieldName = StepFieldName[step as StepsKeys];
  
  return (
    <div className={cn('company_screen overflow-hidden', 'py-2 px-5 md:py-7 md:px-8')}>
      <OnboardingNavbar
        avatarUrl={beUser?.profile_picture}
        userName={beUser?.name}
        email={beUser?.email}
        onLogout={onOpen}
      />

      {company ? (
        <div className="pb-12 h-[calc(100%-4.5rem)]">
          <Component fieldName={fieldName} company={company} updateData={updateData} />
        </div>
      ) : null}

      <LogoutModal isOpen={isOpen} onOpenChange={onOpenChange} />
    </div>
  );
};
