import { Select, SelectItem } from '@nextui-org/react';
import { useLovFieldNames, useLovGroupNames } from './hooks/useLovList';
import { useEffect, useState } from 'react';
import { LovTable } from './Table';
import { CreateLov } from './Create';
import { Button } from 'presentation/components';

export const Lov = () => {
  const [refetch, setRefetch] = useState(false);
  const { groupNames, getAllGroupNames, isFetchingGroupNames } = useLovGroupNames();
  const { fieldNames, getAllFieldNames, isFetchingFieldNames } = useLovFieldNames();

  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [selectedFieldName, setSelectedFieldName] = useState('');

  useEffect(() => {
    getAllGroupNames();
  }, []);

  useEffect(() => {
    if (selectedGroupName) {
      getAllFieldNames(selectedGroupName);
    }
  }, [selectedGroupName]);

  return (
    <div>
      <div className="mb-4 flex flex-row-reverse justify-between items-center">
        <CreateLov
          Trigger={({ open }) => {
            return (
              <Button color="primary" className="bg-black text-white" onClick={open}>
                Add LOV
              </Button>
            );
          }}
          refreshList={() => {
            setRefetch(true);

            setTimeout(() => {
              setRefetch(false);
            }, 100);
          }}
        />
        <div className="flex gap-4">
          <Select
            items={groupNames}
            label="Groups"
            placeholder="Select a group"
            className="w-96"
            isLoading={isFetchingGroupNames}
            defaultSelectedKeys={selectedGroupName ? [selectedGroupName] : []}
            onChange={(ev) => {
              setSelectedGroupName(ev.target.value);
            }}
          >
            {(item) => (
              <SelectItem key={item.label} value={item.label}>
                {item.label}
              </SelectItem>
            )}
          </Select>
          {selectedGroupName ? (
            <Select
              items={fieldNames}
              label="Fields"
              placeholder="Select a field"
              className="w-96"
              isLoading={isFetchingFieldNames}
              defaultSelectedKeys={selectedFieldName ? [selectedFieldName] : []}
              onChange={(ev) => setSelectedFieldName(ev.target.value)}
            >
              {(item) => (
                <SelectItem key={item.label} value={item.label}>
                  {item.label}
                </SelectItem>
              )}
            </Select>
          ) : null}
        </div>
      </div>
      {refetch || !selectedGroupName ? null : (
        <LovTable fieldName={selectedFieldName} groupName={selectedGroupName} />
      )}
    </div>
  );
};
