/* eslint-disable import/no-unused-modules */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';

export interface IBaseProps {
  children?: ReactNode;
}

export const PageContainerRoot = (props: IBaseProps) => {
  return (
    <div className="h-screen w-[85%] md:w-[90%] lg:w-[95%] max-w-[1890px] mx-auto">
      {props.children}
    </div>
  );
};

export const PageContainerHeader = (props: IBaseProps) => {
  return <div className="h-28">{props.children}</div>;
};

export const PageContainerFooter = (props: IBaseProps) => {
  return <>{props.children}</>;
};

export const PageContainerContent = (props: IBaseProps) => {
  return (
    <aside className="flex flex-col-reverse overscroll-y-auto pb-16 md:flex-row">
      {props.children}
    </aside>
  );
};

export const PageContainerContentLeftContent = (props: IBaseProps) => {
  return <div className="grow md:pr-10">{props.children}</div>;
};

export const PageContainerContentRightPanel = (props: IBaseProps) => {
  return (
    <div className="pb-10 md:pd-0 md:pl-10 md:pr-5">
      <div className="max-w-[400px] min-w-[300px]">{props.children}</div>
    </div>
  );
};

export const PageContainer = {
  Root: PageContainerRoot,
  Header: PageContainerHeader,
  Content: PageContainerContent,
  LeftContent: PageContainerContentLeftContent,
  RightPanel: PageContainerContentRightPanel,
  Footer: PageContainerFooter,
};
