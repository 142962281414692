import {
  CircularProgress,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
  cn,
  useDisclosure,
} from '@nextui-org/react';
import { Button } from 'presentation/components';
import { ProjectDeliverables } from './ProjectDeliverables';
import { Project } from 'domain/modules';
import { useEffect, useState } from 'react';
import { useProjectIteration } from 'application/modules/projects';
import { FileUploader } from 'react-drag-drop-files';
import { useAssetsUpload } from 'application/shared';
import { AssetUploadResponse } from 'infra/api';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { FileRenderer } from 'presentation/components/FileRenderer';
import { supportedProjectFileTypes } from 'presentation/constants';
import { useLovsList } from 'adminApp/lov/hooks/useLovList';
import { IterationLovGroupName } from 'constants';

interface IProps {
  projectId: string | undefined;
  project: Project;
  refetchProject: () => void;
}

interface FileData {
  id?: string;
  media_url: string;
  assetId: string;
}

export const RequestIteration = (props: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isCreating, createIteration } = useProjectIteration();
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState<FileData[]>([]);
  const [voices, setVoices] = useState<FileData[]>([]);
  const { deleteAsset, upload } = useAssetsUpload();
  const { lovs, getAllLovsByGroupName, isFetchingLovs } = useLovsList();

  const isDescriptionValid = description.length <= 1500 ? true : false;

  useEffect(() => {
    getAllLovsByGroupName(IterationLovGroupName);
  }, []);

  const createIterationRequest = async () => {
    if (!reason || !description) {
      alert('Reason and description is mandatory');
      return;
    }

    if (props.projectId && isDescriptionValid) {
      await createIteration(props.projectId, {
        reason,
        description,
        audio_url: '',
        isActive: true,
        iteration_media_reference:
          files.length > 0
            ? files.map((file) => ({
                assetId: file.assetId,
                media_url: file.media_url,
                id: file.id ? file.id : undefined,
              }))
            : [],
        iteration_voice_note:
          voices.length > 0
            ? voices.map((voice) => ({
                assetId: voice.assetId,
                media_url: voice.media_url,
                id: voice.id ? voice.id : undefined,
              }))
            : [],
      });
      props.refetchProject();
      onClose();
    }
  };

  const hasARequestedIteration = () => {
    return props.project.iteration.filter((p) => p.isActive === false).length > 0;
  };

  const handleSaveUploadFile = async (asset: AssetUploadResponse) => {
    setFiles([
      ...files,
      {
        media_url: asset.url,
        assetId: asset.id,
      },
    ]);
  };

  const deleteFile = async (file: FileData) => {
    if (file.assetId) {
      await deleteAsset(file.assetId);
      const tempFiles = [...files].filter((i) => i.assetId !== file.assetId);
      setFiles(tempFiles);
    }
  };

  const addAudioElement = async (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement('audio');
    audio.src = url;
    audio.controls = true;
    const filePath = await upload(
      `project/${props.project?.id}/additionalInputs/voice_notes}`,
      new File([blob], 'voice_note')
    );
    handleVoiceUploads(filePath);
  };

  const handleVoiceUploads = async (asset: AssetUploadResponse) => {
    setVoices([
      ...voices,
      {
        media_url: asset.url,
        assetId: asset.id,
      },
    ]);
  };

  const deleteVoiceNote = async (file: FileData) => {
    if (file.assetId) {
      await deleteAsset(file.assetId);
      const tempFiles = [...voices].filter((i) => i.assetId !== file.assetId);
      setVoices(tempFiles);
    }
  };

  return (
    <>
      <Button
        variant="light"
        size="sm"
        className={cn(
          'text-[#EF4B4C]',
          'data-[hover]:bg-transparent',
          'disabled:text-slate-500 disabled:bg-slate-50 disabled:hover:bg-slate-50 disabled:cursor-not-allowed'
        )}
        onClick={onOpen}
        disabled={hasARequestedIteration()}
      >
        Request an iteration
      </Button>
      <Modal
        size="4xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        scrollBehavior="inside"
        placement="top-center"
        closeButton={
          <Button isIconOnly variant="light">
            <i className="ri-close-fill ri-2x" />
          </Button>
        }
        className="py-6 pl-8 pr-5"
        classNames={{
          closeButton: 'p-0 mr-8 mt-10 data-[hover]:bg-transparent text-black',
        }}
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="text-black text-xl font-bold">Iteration Request</ModalHeader>
              <ModalBody>
                <div className="flex gap-4 items-center justify-between">
                  <div className="flex-1 flex flex-col gap-3">
                    <Select
                      variant="bordered"
                      isRequired
                      label=" What specific aspect of the design would you like to change?"
                      placeholder="Choose Reason"
                      labelPlacement="outside"
                      isLoading={isFetchingLovs}
                      defaultSelectedKeys={reason ? [reason] : []}
                      onChange={(ev) => setReason(ev.target.value)}
                      classNames={{
                        label: 'text-sm font-medium text-wrap pl-2',
                        innerWrapper: 'pl-3',
                        trigger: cn(
                          'rounded-[16px] min-h-[48px] shadow-none data-[focus=true]:border-default'
                        ),
                        errorMessage: 'absolute',
                      }}
                    >
                      {lovs
                        .filter((i) => i.fieldName === 'reason')
                        .map((changeReason) => (
                          <SelectItem key={changeReason.label}>{changeReason.label}</SelectItem>
                        ))}
                    </Select>
                    <Textarea
                      label="Describe the design Request?"
                      labelPlacement="outside"
                      isRequired
                      placeholder="Help us refine your design even more"
                      description={`${description.length} of 1500 characters`}
                      maxLength={1500}
                      classNames={{
                        label: 'text-sm font-medium text-wrap pl-2',
                        input: cn('max-h-[120px]', !description ? 'text-xs' : ''),
                        inputWrapper: cn(
                          description.length > 1500 ? 'border-1 border-solid border-[red]' : '',
                          'bg-[#F9F9F9] rounded-lg'
                        ),
                        description: 'text-right',
                        errorMessage: 'text-right',
                      }}
                      value={description}
                      onChange={(ev) => setDescription(ev.target.value)}
                      errorMessage={description.length > 1500 && 'Limit exceeded'}
                    />

                    <div>
                      <h4 className="text-[#11181C] text-sm font-medium text-wrap pl-2 mb-1">
                        Would you like to add anything?
                      </h4>

                      <div className="flex gap-2 file-upload-container">
                        <FileRenderer files={files} deleteFile={deleteFile} />

                        {files.length < 10 ? (
                          <FileUpload
                            hasUploadedFiles={files.length === 0}
                            path={`project/${props.project?.id}/additionalInputs`}
                            handleSaveUploadFile={handleSaveUploadFile}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="w-[300px] h-[300px] flex items-center justify-center">
                    <ProjectDeliverables
                      projectId={props.projectId}
                      project={props.project}
                      iterationView
                      iterationRequest
                      loaderAutoHeight
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="w-full flex items-center justify-between">
                  <div className="w-[75%]">
                    {voices.length > 0 ? null : (
                      <AudioRecorder
                        onRecordingComplete={addAudioElement}
                        audioTrackConstraints={{
                          noiseSuppression: true,
                          echoCancellation: true,
                        }}
                        downloadFileExtension="mp3"
                        showVisualizer
                      />
                    )}

                    <div id="audio-renderer" className="w-full">
                      {voices.map((voice) => (
                        <div key={voice.assetId} className="flex items-center">
                          <audio controls preload="auto">
                            <source src={voice.media_url} type="audio/mpeg"></source>
                            <source src={voice.media_url} type="audio/ogg"></source>
                          </audio>
                          <Button
                            variant="light"
                            color="danger"
                            onClick={() => deleteVoiceNote(voice)}
                            className="data-[hover]:bg-transparent text-sm font-medium"
                          >
                            Delete
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <Button
                    radius="lg"
                    className={cn('bg-[#99CF63]', 'px-10', 'text-black text-sm font-medium')}
                    onPress={createIterationRequest}
                    disabled={isCreating}
                  >
                    Create
                  </Button>
                </div>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

interface IFileUploadProps {
  hasUploadedFiles: boolean;
  handleSaveUploadFile: (asset: AssetUploadResponse) => void;
  path: string;
}

const FileUpload = ({ hasUploadedFiles, handleSaveUploadFile, path }: IFileUploadProps) => {
  const [loading, setIsLoading] = useState(false);
  const { upload } = useAssetsUpload();

  const handleChange = async (file: File) => {
    try {
      setIsLoading(true);
      const filePath = await upload(path, file);
      handleSaveUploadFile(filePath);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FileUploader handleChange={handleChange} name="file" types={supportedProjectFileTypes}>
      {hasUploadedFiles ? (
        <div
          className={cn(
            'bg-[#F9F9F9] rounded-lg',
            'flex items-center justify-center gap-2',
            'py-4 focus:outline-none '
          )}
        >
          <i className="ri-upload-cloud-2-line ri-xl" />

          {loading ? (
            <p className="font-medium text-sm flex gap-4 items-center">
              <CircularProgress aria-label="Loading..." size="sm" /> Uploading
            </p>
          ) : (
            <div className="flex flex-col items-center">
              <p className="text-[#232323] text-xs">
                <span className="text-[#136DC7]">Click here </span>
                to upload or drop media here (add up to 10 files)
              </p>
              <p className="italic text-[#232323] text-[10px] text-center">
                permissible files: .png, .svg, .jpeg, .pdf, .mp4, .doc, .ppt
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="w-20 h-20 rounded border flex justify-center items-center hover:bg-gray-100 cursor-pointer">
          {loading ? (
            <CircularProgress aria-label="Loading..." size="sm" />
          ) : (
            <i className="ri-add-fill" />
          )}
        </div>
      )}
    </FileUploader>
  );
};
