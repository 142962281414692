import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useCreate } from './hooks/useCreate';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useEffect } from 'react';
import { styleNameList } from './columns';
import { useLovFieldNames } from './hooks/useLovFieldNames';
import { Credits } from 'adminApp/models/create-credits';
import { useCategories } from './hooks/useCategories';
import { useSubCategories } from './hooks/useSubCategories';
import { useType } from './hooks/useType';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: Credits;
}

export const CreateCredit = ({ refreshList, Trigger, isUpdate, data }: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, update, reset } = useCreate();

  const { getAllCategories, categoryList, isFetching: isFetchingCategories } = useCategories();
  const {
    getAllSubCategories,
    subcategoryList,
    isFetching: isFetchingSubCategories,
  } = useSubCategories();
  const { getAllTypes, typeList, isFetching: isFetchingTypes } = useType();
  const {
    getAllLovsFieldNames,
    lovFieldNames,
    isFetching: isFetchingLovFieldNames,
  } = useLovFieldNames();

  const formik = useFormik({
    initialValues: {
      categoryId: data?.categoryId ? String(data?.categoryId) : '',
      subcategoryId: data?.subcategoryId ? String(data?.subcategoryId) : '',
      typeId: data?.typeId ? String(data?.typeId) : '',
      styleType: data?.styleType || 'Standard',
      lovId: data?.lovId ? String(data?.lovId) : '',
      valueCalculation: data?.valueCalculation,
      rangeStart: data?.rangeStart,
      rangeEnd: data?.rangeEnd,
      credits: data?.credits,
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      if (!isUpdate) {
        await create({
          categoryId: String(values.categoryId),
          subcategoryId: String(values.subcategoryId),
          typeId: values.typeId ? String(values.typeId) : undefined,
          styleType: values.styleType,
          lovId: values.lovId ? String(values.lovId) : undefined,
          valueCalculation: values.valueCalculation,
          rangeStart: values.rangeStart,
          rangeEnd: values.rangeEnd,
          credits: values.credits ? parseInt(String(values.credits)) : 0,
        });
      } else {
        if (data && data.id) {
          await update(data.id, {
            categoryId: String(values.categoryId),
            subcategoryId: String(values.subcategoryId),
            typeId: values.typeId ? String(values.typeId) : undefined,
            styleType: values.styleType,
            lovId: values.lovId ? String(values.lovId) : undefined,
            valueCalculation: values.valueCalculation,
            rangeStart: values.rangeStart,
            rangeEnd: values.rangeEnd,
            credits: values.credits ? parseInt(String(values.credits)) : 0,
          });
        }
      }

      reset();
      formik.resetForm();
      onClose();
      refreshList();
    },
  });

  useEffect(() => {
    if (isOpen) {
      getAllCategories();
      getAllLovsFieldNames();
      reset();
      formik.resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    if (formik.values.categoryId) {
      getAllSubCategories(formik.values.categoryId);
    }
  }, [formik.values.categoryId]);

  useEffect(() => {
    if (formik.values.subcategoryId) {
      getAllTypes(formik.values.subcategoryId);
    }
  }, [formik.values.subcategoryId]);

  console.log(formik.values);

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        scrollBehavior="inside"
        onClose={() => {
          reset();
          formik.resetForm();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Credits
              </ModalHeader>
              <ModalBody>
                <Select
                  items={categoryList}
                  label="Category"
                  placeholder="Select a category"
                  classNames={{
                    trigger: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  isLoading={isFetchingCategories}
                  defaultSelectedKeys={formik.values.categoryId ? [formik.values.categoryId] : []}
                  onChange={formik.handleChange}
                  name="categoryId"
                >
                  {(item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.name}
                    </SelectItem>
                  )}
                </Select>
                <Select
                  items={subcategoryList}
                  label="Sub Category"
                  placeholder="Select a subcategory"
                  classNames={{
                    trigger: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  isLoading={isFetchingSubCategories}
                  defaultSelectedKeys={
                    formik.values.subcategoryId ? [formik.values.subcategoryId] : []
                  }
                  onChange={formik.handleChange}
                  name="subcategoryId"
                >
                  {(item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.name}
                    </SelectItem>
                  )}
                </Select>
                <Select
                  items={typeList}
                  label="Type"
                  placeholder="Select a type"
                  classNames={{
                    trigger: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  isLoading={isFetchingTypes}
                  defaultSelectedKeys={formik.values.typeId ? [formik.values.typeId] : []}
                  onChange={formik.handleChange}
                  name="typeId"
                >
                  {(item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.name}
                    </SelectItem>
                  )}
                </Select>
                <Select
                  items={styleNameList}
                  label="Style"
                  placeholder="Select a style"
                  labelPlacement="outside"
                  defaultSelectedKeys={formik.values.styleType ? [formik.values.styleType] : []}
                  onChange={formik.handleChange}
                  name="styleType"
                >
                  {(item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.name}
                    </SelectItem>
                  )}
                </Select>
                <Select
                  items={lovFieldNames}
                  label="LOV Field"
                  placeholder="Select a lov field name"
                  labelPlacement="outside"
                  isLoading={isFetchingLovFieldNames}
                  defaultSelectedKeys={formik.values.lovId ? [formik.values.lovId] : []}
                  onChange={formik.handleChange}
                  name="lovId"
                >
                  {(item) => (
                    <SelectItem key={item.id} value={item.id}>
                      {item.label}
                    </SelectItem>
                  )}
                </Select>
                <Input
                  type="number"
                  label="Range Start"
                  placeholder="Enter range start"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.rangeStart)}
                  name="rangeStart"
                />
                <Input
                  type="number"
                  label="Range End"
                  placeholder="Enter range end"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.rangeEnd)}
                  name="rangeEnd"
                />
                <Input
                  type="number"
                  label="Credits"
                  placeholder="Enter credits"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.credits)}
                  name="credits"
                />
                <Input
                  label="Value Calculation"
                  placeholder="Enter Value Calculation"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  description="Example: (FIELD(fieldId)+FIELD(fieldId))*FIELD(fieldId)"
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.valueCalculation}
                  name="valueCalculation"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => formik.submitForm()}
                  className="bg-black text-white"
                  disabled={isLoading || !formik.isValid}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Update' : 'Create'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
