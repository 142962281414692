import { cn } from '@nextui-org/system';
import { useProjectDeliverables } from 'application/modules/projects/hooks/useProjectDeliverables';
import { Project, ProjectAttachmentResponse } from 'domain/modules';
import empty_attachment from 'presentation/assets/empty_attachment.png';
import folderImage from 'presentation/assets/folder.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Button } from 'presentation/components';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { LazyImage } from 'presentation/components/LazyImage';

interface IProps {
  projectId?: string;
  project?: Project;
  iterationView?: boolean;
  iterationRequest?: boolean;
}

export const ProjectDeliverables = ({
  projectId,
  project,
  iterationView,
  iterationRequest,
}: IProps) => {
  const { isFetching, deliveryAttachments, getAttachments } = useProjectDeliverables();

  const [showcaseThumbnail, setShowCaseThumbnail] = useState<ProjectAttachmentResponse | null>(
    null
  );
  const [sourceThumbnail, setSourceThumbnail] = useState<ProjectAttachmentResponse | null>(null);
  const [mockupThumbnail, setMockupThumbnail] = useState<ProjectAttachmentResponse | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (projectId && project && project.iteration.length > 0) {
      let iterationId;

      if (
        project.status === 'COMPLETED' ||
        (project.status === 'IN_PROGRESS' && project.iteration.length === 1)
      ) {
        iterationId = project.iteration[project.iteration.length - 1].id;
      } else {
        if (
          !project.iteration[project.iteration.length - 1].isActive ||
          project.status === 'IN_PROGRESS'
        ) {
          iterationId = project.iteration[project.iteration.length - 2].id;
        } else {
          iterationId = project.iteration[project.iteration.length - 1].id;
        }
      }
      getAttachments(projectId, iterationId);
    }
  }, []);

  useEffect(() => {
    if (deliveryAttachments && deliveryAttachments.length > 0) {
      const fileThumbnail = deliveryAttachments.find(
        (d) => d.project_asset_type === 'SHOWCASE_FILES_THUMBNAIL'
      );

      if (fileThumbnail) {
        setShowCaseThumbnail(fileThumbnail);
      }

      const sourcefileThumbnail = deliveryAttachments.find(
        (d) => d.project_asset_type === 'SOURCE_FILES_THUMBNAIL'
      );

      if (sourcefileThumbnail) {
        setSourceThumbnail(sourcefileThumbnail);
      }

      const mockupfileThumbnail = deliveryAttachments.find(
        (d) => d.project_asset_type === 'MOCKUP_FILES_THUMBNAIL'
      );

      if (mockupfileThumbnail) {
        setMockupThumbnail(mockupfileThumbnail);
      }
    }
  }, [deliveryAttachments]);

  const openProject = () => {
    if (project) {
      let iterationId;

      if (
        !project.iteration[project.iteration.length - 1].isActive ||
        project.status === 'IN_PROGRESS'
      ) {
        iterationId = project.iteration[project.iteration.length - 2].id;
      } else {
        iterationId = project.iteration[project.iteration.length - 1].id;
      }

      navigate(`/projects/listing/project/${projectId}/iteration/${iterationId}`, {
        state: { projectTitle: project.name },
      });
    }
  };

  if (isFetching || !deliveryAttachments) {
    return (
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '50vh',
        }}
      />
    );
  }

  if (deliveryAttachments.length === 0) {
    return (
      <div
        className={cn(
          'bg-black-300 relative border border-black-300 overflow-hidden',
          !iterationView ? 'w-[400px] h-[475px]' : 'w-[264px] h-[264px]'
        )}
      >
        <img
          className="attachment w-full h-full object-cover"
          src={empty_attachment}
          alt="no_attachment"
        />
      </div>
    );
  }

  if (iterationView) {
    return (
      <div>
        {iterationRequest ? null : <h4 className="text-[#172B4D] text-lg">Attachments</h4>}

        <LazyImage
          className={cn(
            'w-[264px] h-[264px]',
            iterationRequest ? 'rounded-md' : 'attachment object-cover'
          )}
          src={showcaseThumbnail?.asset.url}
          alt="no_attachment"
        />
        {!iterationRequest ? (
          <div className="text-right">
            <Button
              variant="bordered"
              className={cn(
                'border border-solid border-black',
                'rounded-[42px]',
                'text-[#0D1216] text-[16px] font-medium'
              )}
              onClick={openProject}
            >
              View Project folder
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <>
      <div className="w-[420px] bg-black-300 relative overflow-hidden flex flex-col justify-between">
        <div className="flex mb-3 gap-2 ">
          <LazyImage className="w-[206px] h-48" src={showcaseThumbnail?.asset.url} alt="design" />

          <LazyImage
            className="w-[206px] h-48"
            src={sourceThumbnail?.asset.url}
            alt="source_file"
          />
        </div>

        <div>
          <LazyImage className="w-[420px] h-60" src={mockupThumbnail?.asset.url} alt="mockup" />
        </div>

        <div
          onClick={openProject}
          className="w-[420px] flex gap-2 items-center py-2 px-4 bg-[#F1F3F5] hover:bg-[#b4b7bb] mt-2 cursor-pointer"
        >
          <LazyImage src={folderImage} alt="Project Name" className="w-[25px] h-[21px]" />
          <span className="text-sm font-medium">{project?.name}</span>
        </div>
      </div>
    </>
  );
};
