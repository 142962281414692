import { Button } from '@nextui-org/button';
import { Chip, cn } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { useProjectDetailContext } from '../../context';
import { IBaseProps } from '../PageContainer';
import { HeaderEditAction, HeaderIterationAction, HeaderStatusAction } from './HeaderActions';

const BackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  return (
    <Button
      size="sm"
      variant="light"
      startContent={<i className="ri-arrow-left-s-line ri-xl" />}
      className="p-0 text-[#2C62B4] text-sm data-[hover]:bg-transparent"
      onClick={goBack}
    >
      Back
    </Button>
  );
};

const ProjectTitleView = () => {
  const context = useProjectDetailContext();
  const { project_display_id, name } = context.project;

  let projectName;
  if (project_display_id && name) {
    projectName = project_display_id + '_' + name;
  } else if (project_display_id) {
    projectName = project_display_id;
  } else if (name) {
    projectName = name;
  } else {
    projectName = 'NAME_UNAVAILABLE';
  }

  return (
    <h1 className="text-[#0D1216] text-[28px] font-semibold max-w-[600px] truncate">
      {projectName}
    </h1>
  );
};

const CreditsConsumed = () => {
  const context = useProjectDetailContext();
  const { status, credits } = context.project;
  if (!credits) {
    return null;
  }
  return (
    <Chip
      className={cn(status === 'IN_ACTIVE' ? 'bg-[#F3F3F3]' : 'bg-[#BAE289]')}
      classNames={{ content: 'text-black text-sm font-bold' }}
    >
      {status === 'IN_ACTIVE' ? 'Draft' : `${credits} Credits`}
    </Chip>
  );
};

const ActionsRoot = ({ children }: IBaseProps) => {
  return <div className="flex items-center gap-4">{children}</div>;
};

const HeaderAction = {
  Root: ActionsRoot,
  Iteration: HeaderIterationAction,
  Edit: HeaderEditAction,
  Status: HeaderStatusAction,
};

const Divider = () => {
  return <div className="border-r-1 w-[1px] h-[25px]" />;
};

export const Header = () => {
  return (
    <div className="flex flex-col items-start gap-1 pt-6">
      <BackButton />
      <div className="flex justify-between items-center w-full">
        <div className="flex items-center gap-4">
          <ProjectTitleView />
          <CreditsConsumed />
        </div>
        <HeaderAction.Root>
          <HeaderAction.Edit />
          <HeaderAction.Iteration />
          <Divider />
          <HeaderAction.Status />
        </HeaderAction.Root>
      </div>
    </div>
  );
};
