import { adminApi } from 'adminApp/api';
import { Faq } from 'adminApp/models/create-faq';
import { useState } from 'react';

export const useFaq = () => {
  const [isFetchingFaq, setIsFetchingFaq] = useState(false);
  const [faq, setFaq] = useState<Faq[]>([]);

  const getAllFaqs = async () => {
    try {
      setIsFetchingFaq(true);
      const data = await adminApi.getFaq();
      setFaq(data);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingFaq(false);
    }
  };
  const deleteFaq = async (faqId: string) => {
    adminApi
      .deleteFaq(faqId)
      .then(() => {
        getAllFaqs();
        alert('Deleted Faq');
      })
      .catch(() => {
        alert('Unable to delete Faq');
      })
      .finally(() => {
        setIsFetchingFaq(false);
      });
  };

  return {
    faq,
    isFetchingFaq,
    getAllFaqs,
    deleteFaq,
  };
};
