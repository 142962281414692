import { adminApi } from 'adminApp/api';
import { StyleType } from 'adminApp/models/create-form-with-combination';
import { Inspiration } from 'adminApp/models/create-inspiration';
import { useState } from 'react';

export const useGetAll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Inspiration[]>([]);

  const getAll = async (categoryId: string, subcategoryId: string, styleType: StyleType) => {
    setIsLoading(true);
    setData([]);
    adminApi
      .getAllInspirations(categoryId, subcategoryId, styleType)
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        console.log('Unable to get Inspirations');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteInspiration = async (
    inspirationId: string,
    categoryId: string,
    subcategoryId: string,
    styleType: StyleType
  ) => {
    adminApi
      .deleteInspiration(inspirationId)
      .then(() => {
        getAll(categoryId, subcategoryId, styleType);
        alert('Deleted Inspiration');
      })
      .catch(() => {
        alert('Unable to delete Inspiration');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    data,
    getAll,
    deleteInspiration,
  };
};
