/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createContext, useEffect, useState } from 'react';
import _ from 'lodash';

export const GlobalContext = createContext({});

export const GlobalProvider = ({
  children,
  currentUser,
  replyTop,
  customImg,
  inputStyle,
  formStyle,
  submitBtnStyle,
  cancelBtnStyle,
  imgStyle,
  commentsCount,
  commentData,
  onSubmitAction,
  onDeleteAction,
  onReplyAction,
  onEditAction,
  currentData,
  replyInputStyle,
  removeEmoji,
  advancedInput,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any;
  currentUser?: {
    currentUserId: string;
    currentUserImg: string;
    currentUserProfile?: string | undefined;
    currentUserFullName: string;
  } | null;
  replyTop?: boolean;
  customImg?: string;
  inputStyle?: object;
  formStyle?: object;
  submitBtnStyle?: object;
  cancelBtnStyle?: object;
  imgStyle?: object;
  replyInputStyle?: object;
  commentsCount?: number;
  removeEmoji?: boolean;
  commentData?: Array<{
    userId: string;
    comId: string;
    fullName: string;
    avatarUrl: string;
    text: string;
    userProfile?: string;
    replies?:
      | Array<{
          userId: string;
          comId: string;
          fullName: string;
          avatarUrl: string;
          text: string;
          userProfile?: string;
        }>
      | undefined;
  }>;
  onSubmitAction?: Function;
  onDeleteAction?: Function;
  onReplyAction?: Function;
  onEditAction?: Function;
  currentData?: Function;
  advancedInput?: boolean;
}) => {
  const [currentUserData] = useState(currentUser);
  const [data, setData] = useState<
    Array<{
      userId: string;
      comId: string;
      fullName: string;
      avatarUrl: string;
      text: string;
      userProfile?: string;
      replies?:
        | Array<{
            userId: string;
            comId: string;
            fullName: string;
            avatarUrl: string;
            text: string;
            userProfile?: string;
          }>
        | undefined;
    }>
  >([]);
  const [editArr, setEdit] = useState<string[]>([]);
  const [replyArr, setReply] = useState<string[]>([]);

  useEffect(() => {
    if (commentData) {
      setData(commentData);
    }
  }, [commentData]);

  useEffect(() => {
    if (currentData) {
      currentData(data);
    }
  }, [data]);

  const handleAction = (id: string, edit: boolean) => {
    if (edit) {
      const editArrCopy: string[] = [...editArr];
      const indexOfId = _.indexOf(editArrCopy, id);
      if (_.includes(editArr, id)) {
        editArrCopy.splice(indexOfId, 1);
        setEdit(editArrCopy);
      } else {
        editArrCopy.push(id);
        setEdit(editArrCopy);
      }
    } else {
      const replyArrCopy: string[] = [...replyArr];
      const indexOfId = _.indexOf(replyArrCopy, id);
      if (_.includes(replyArr, id)) {
        replyArrCopy.splice(indexOfId, 1);
        setReply(replyArrCopy);
      } else {
        replyArrCopy.push(id);
        setReply(replyArrCopy);
      }
    }
  };

  const onSubmit = (text: string, uuid: string) => {
    const copyData = [...data];
    copyData.push({
      userId: currentUserData!.currentUserId,
      comId: uuid,
      avatarUrl: currentUserData!.currentUserImg,
      userProfile: currentUserData!.currentUserProfile
        ? currentUserData!.currentUserProfile
        : undefined,
      fullName: currentUserData!.currentUserFullName,
      text: text,
      replies: [],
    });
    setData(copyData);
  };

  const onEdit = (text: string, comId: string, parentId: string) => {
    const copyData = [...data];
    if (parentId) {
      const indexOfParent = _.findIndex(copyData, { comId: parentId });
      const indexOfId = _.findIndex(copyData[indexOfParent].replies, {
        comId: comId,
      });
      copyData[indexOfParent].replies![indexOfId].text = text;
      setData(copyData);
      handleAction(comId, true);
    } else {
      const indexOfId = _.findIndex(copyData, { comId: comId });
      copyData[indexOfId].text = text;
      setData(copyData);
      handleAction(comId, true);
    }
  };

  const onReply = (text: string, comId: string, parentId: string, uuid: string) => {
    const copyData = [...data];
    if (parentId) {
      const indexOfParent = _.findIndex(copyData, { comId: parentId });
      copyData[indexOfParent].replies!.push({
        userId: currentUserData!.currentUserId,
        comId: uuid,
        avatarUrl: currentUserData!.currentUserImg,
        userProfile: currentUserData!.currentUserProfile
          ? currentUserData!.currentUserProfile
          : undefined,
        fullName: currentUserData!.currentUserFullName,
        text: text,
      });
      setData(copyData);
      handleAction(comId, false);
    } else {
      const indexOfId = _.findIndex(copyData, {
        comId: comId,
      });
      copyData[indexOfId].replies!.push({
        userId: currentUserData!.currentUserId,
        comId: uuid,
        avatarUrl: currentUserData!.currentUserImg,
        userProfile: currentUserData!.currentUserProfile
          ? currentUserData!.currentUserProfile
          : undefined,
        fullName: currentUserData!.currentUserFullName,
        text: text,
      });
      setData(copyData);
      handleAction(comId, false);
    }
  };

  const onDelete = (comId: string, parentId: string) => {
    const copyData = [...data];
    if (parentId) {
      const indexOfParent = _.findIndex(copyData, { comId: parentId });
      const indexOfId = _.findIndex(copyData[indexOfParent].replies, {
        comId: comId,
      });
      copyData[indexOfParent].replies!.splice(indexOfId, 1);
      setData(copyData);
    } else {
      const indexOfId = _.findIndex(copyData, { comId: comId });
      copyData.splice(indexOfId, 1);
      setData(copyData);
    }
  };

  return (
    <GlobalContext.Provider
      value={{
        currentUserData: currentUserData,
        replyTop: replyTop,
        data: data,
        handleAction: handleAction,
        editArr: editArr,
        onSubmit: onSubmit,
        onEdit: onEdit,
        replyArr: replyArr,
        onReply: onReply,
        onDelete: onDelete,
        customImg: customImg,
        inputStyle: inputStyle,
        formStyle: formStyle,
        submitBtnStyle: submitBtnStyle,
        cancelBtnStyle: cancelBtnStyle,
        imgStyle: imgStyle,
        commentsCount: commentsCount,
        onSubmitAction: onSubmitAction,
        onDeleteAction: onDeleteAction,
        onReplyAction: onReplyAction,
        onEditAction: onEditAction,
        replyInputStyle: replyInputStyle,
        removeEmoji: removeEmoji,
        advancedInput: advancedInput,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
