import { useEffect, useState } from 'react';
import { CreateSubCategory } from './Create';
import { CategoryTable } from './Table';
import { Button } from 'presentation/components';
import { useCategories } from './hooks/useCategories';
import { Select, SelectItem } from '@nextui-org/react';

export const SubCategory = () => {
  const [refetch, setRefetch] = useState(false);
  const { categoryList, getAllCategories, isFetching } = useCategories();
  const [categoryId, setCategoryId] = useState<string>();

  useEffect(() => {
    getAllCategories();
  }, []);

  return (
    <div>
      <div className="mb-4 flex flex-row-reverse justify-between items-center">
        {!categoryId ? (
          <div />
        ) : (
          <CreateSubCategory
            categoryId={categoryId}
            Trigger={({ open }) => {
              return (
                <Button color="primary" className="bg-black text-white" onClick={open}>
                  Add Sub Category
                </Button>
              );
            }}
            refreshList={() => {
              setRefetch(true);

              setTimeout(() => {
                setRefetch(false);
              }, 100);
            }}
          />
        )}
        <Select
          items={categoryList}
          label="Category"
          placeholder="Select a category"
          className="max-w-xs"
          isLoading={isFetching}
          defaultSelectedKeys={categoryId ? [categoryId] : []}
          onChange={(ev) => setCategoryId(ev.target.value)}
        >
          {(item) => (
            <SelectItem key={item.id} value={item.id}>
              {item.name}
            </SelectItem>
          )}
        </Select>
      </div>
      {refetch || !categoryId ? null : <CategoryTable categoryId={categoryId} />}
    </div>
  );
};
