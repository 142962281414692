/* eslint-disable import/no-unused-modules */
import { useEffect, useState } from 'react';
// import { CreateProjectAttachment } from './Create';
import { AttachmentTable } from './Table';
import { Button } from 'presentation/components';
import { useProjects } from './hooks/useProjects';
import { Select, SelectItem } from '@nextui-org/react';
import { AddShowCaseFilesThumbnail } from './AddShowCaseFilesThumbnail';
import { AddSourceFilesThumbnail } from './AddSourceFilesThumbnail';
import { AddMockupFilesThumbnail } from './AddMockupFilesThumbnail';
import { AddShowCaseFiles } from './AddShowCaseFiles';
import { AddMockupFiles } from './AddMockupFiles';
import { AddOtherFiles } from './AddOtherFiles';
import { AddDesignFile } from './AddDesignFile';
import { AddSourceFile } from './AddSourceFile';

export const ProjectAttachment = () => {
  const [refetch, setRefetch] = useState(false);
  const { projectList, getAllProjects, isFetching } = useProjects();
  const [projectId, setProjectId] = useState<string>('');
  const [projectIterationId, setProjectIterationId] = useState<string>('');

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div>
      <div className="mb-4 flex  justify-between items-center">
        <div className="flex gap-4">
          <Select
            items={projectList}
            label="Project"
            placeholder="Select a project"
            className="w-96"
            isLoading={isFetching}
            defaultSelectedKeys={projectId ? [projectId] : []}
            onChange={(ev) => {
              setProjectIterationId('');
              setProjectId(ev.target.value);
            }}
          >
            {(item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.name}
              </SelectItem>
            )}
          </Select>
          {projectId ? (
            <Select
              items={
                Array.isArray(projectList)
                  ? projectList.find((p) => p.id === projectId)?.iteration || []
                  : []
              }
              label="Project Iteration"
              placeholder="Select a Project Iteration"
              className="w-96"
              isLoading={isFetching}
              defaultSelectedKeys={projectIterationId ? [projectIterationId] : []}
              onChange={(ev) => setProjectIterationId(ev.target.value)}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.reason}
                </SelectItem>
              )}
            </Select>
          ) : null}
        </div>
      </div>

      <div className="flex items-center gap-5 my-8">
        {!projectId || !projectIterationId ? (
          <div />
        ) : (
          <>
            <AddDesignFile
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Design File
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />

            <AddSourceFile
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Source File
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />

            <AddShowCaseFilesThumbnail
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Project Files Thumbnail
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />

            <AddSourceFilesThumbnail
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Source Files Thumbnail
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />

            <AddMockupFilesThumbnail
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Mockup Files Thumbnail
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />

            <AddShowCaseFiles
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Showcase Files
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />

            <AddMockupFiles
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Mockup Files
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />

            <AddOtherFiles
              projectId={projectId}
              projectIterationId={projectIterationId}
              Trigger={({ open }) => {
                return (
                  <Button color="primary" className="bg-black text-white" onClick={open}>
                    Add Other Files
                  </Button>
                );
              }}
              refreshList={() => {
                setRefetch(true);

                setTimeout(() => {
                  setRefetch(false);
                }, 100);
              }}
            />
          </>
        )}
      </div>
      {refetch || !projectId || !projectIterationId ? null : (
        <AttachmentTable projectId={projectId} projectIterationId={projectIterationId} />
      )}
    </div>
  );
};
