import { Modal, ModalBody, ModalContent, ModalHeader } from '@nextui-org/react';
import { DeliveryAssetType, Project, ProjectAttachmentResponse } from 'domain/modules';
import { Button } from 'presentation/components';
import { Carousel } from 'react-responsive-carousel';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { LazyImage } from 'presentation/components/LazyImage';

interface IProps {
  isOpen: boolean;
  onOpenChange: () => void;
  showcaseFiles: ProjectAttachmentResponse[];
  mockupFile: ProjectAttachmentResponse | null;
  previeMode: 'DESIGN' | 'MOCKUP';
  project?: Project;
  projectId?: string;
  previewAssetType: DeliveryAssetType;
  currentIteration?: string;
  attachmentsByType: ProjectAttachmentResponse[];
  changePreview: (projectId: string, iterationId: string) => void;
  isFetching: boolean;
}

export const DeliverablesPreview = ({
  isOpen,
  onOpenChange,
  mockupFile,
  previeMode,
  showcaseFiles,
  project,
  attachmentsByType,
  changePreview,
  isFetching,
}: IProps) => {
  return (
    <Modal isOpen={isOpen} onOpenChange={onOpenChange} size="2xl">
      <ModalContent>
        {() => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <h1>
                {project && project.iteration.length > 1
                  ? `Iteration ${project?.iteration.length - 1}`
                  : 'Design'}
              </h1>
            </ModalHeader>
            <ModalBody className="flex items-center justify-center">
              {isFetching ? (
                <Player
                  src={inspiration_loader}
                  className="player"
                  loop
                  autoplay
                  style={{
                    height: '50vh',
                  }}
                />
              ) : (
                <>
                  <div className="relative ">
                    {previeMode === 'MOCKUP' ? (
                      <LazyImage
                        src={mockupFile?.asset.url}
                        alt="design_preview"
                        className="w-[500px] h-[400px] object-contain"
                      />
                    ) : showcaseFiles.length === 1 ? (
                      project?.category.name === 'video' ? (
                        <video
                          width="200"
                          // controls
                          controlsList="nofullscreen noremoteplayback nodownload"
                          className="rounded-lg"
                        >
                          <source src={showcaseFiles[0].asset.url} type="video/mp4" />
                          <source src={showcaseFiles[0].asset.url} type="video/ogg" />
                          Your browser does not support HTML video.
                        </video>
                      ) : (
                        <LazyImage
                          src={showcaseFiles[0].asset.url}
                          alt="design_preview"
                          className="w-[500px] h-[400px] object-contain"
                        />
                      )
                    ) : (
                      <Carousel
                        showArrows
                        showThumbs={false}
                        showStatus={false}
                        showIndicators={false}
                        renderArrowPrev={(onClickHandler, hasPrev, label) => (
                          <Button
                            isIconOnly
                            onClick={onClickHandler}
                            title={label}
                            className="absolute top-[50%] left-[15px] z-[1] rounded-full"
                            isDisabled={!hasPrev}
                          >
                            <i className="ri-arrow-left-s-line" />
                          </Button>
                        )}
                        renderArrowNext={(onClickHandler, hasNext, label) => (
                          <Button
                            isIconOnly
                            onClick={onClickHandler}
                            title={label}
                            className="absolute top-[50%] right-[15px] rounded-full"
                            isDisabled={!hasNext}
                          >
                            <i className="ri-arrow-right-s-line" />
                          </Button>
                        )}
                      >
                        {showcaseFiles.map((file, index) => (
                          <div key={index}>
                            <LazyImage
                              src={file.asset.url}
                              alt={`image_${index}`}
                              className="w-[500px] h-[400px] object-contain"
                            />
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </div>

                  {attachmentsByType && attachmentsByType.length > 0 ? (
                    <div className="flex items-center justify-center">
                      {attachmentsByType
                        .slice()
                        .reverse()
                        .map((attachment, index) => (
                          <LazyImage
                            key={index}
                            src={attachment.asset.url}
                            alt="thumbnail_design"
                            className="w-24 h-24 object-contain"
                            onClick={() =>
                              changePreview(attachment.project_id, attachment.project_iteration_id)
                            }
                          />
                        ))}
                    </div>
                  ) : null}
                </>
              )}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
