import { adminApi } from 'adminApp/api';
import { Company } from 'domain/modules';
import { useState } from 'react';

export const useCompanies = () => {
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getAll = async () => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllCompanies();
      if (Array.isArray(res)) {
        setCompanies(res);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    companies,
    isFetching,
    getAll,
  };
};
