import { Chip, Input, Select, SelectItem, Textarea, cn } from '@nextui-org/react';
import { DynamicField } from 'application/modules/shared/hooks/useDynamicForm';
import { Lov } from 'domain/shared/models/lov.model';
import { FormikProps } from 'formik';
import { get, has } from 'lodash';
import { ColorPicker } from '../ColorPickerCustom';
import { useEffect, useState } from 'react';
import { CustomInputLayout } from './CustomInput';

interface IProps {
  fieldData: Record<string, DynamicField[]>;
  groupName: string;
  lovData: Lov[];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  formik: FormikProps<any>;
  isEditMode?: boolean;
  fromDetails?: boolean;
  showErrorMessage?: boolean;
}

export const DynamicForms = ({
  formik,
  fieldData,
  lovData,
  groupName,
  isEditMode,
  fromDetails,
  showErrorMessage,
}: IProps) => {
  const [colorFields, setColorFields] = useState<{ id: string; color: string }[] | []>([]);

  useEffect(() => {
    if (has(formik.values, groupName)) {
      if (
        has(formik.values[groupName], 'preferredColors') &&
        formik.values[groupName].preferredColors
      ) {
        setColorFields(JSON.parse(formik.values[groupName].preferredColors));
      }
    }
  }, []);

  const getIsInValid = (fieldName: string, formik: FormikProps<unknown>, message = false) => {
    if (!showErrorMessage) {
      return false;
    }

    const error = get(formik.errors, fieldName);
    if (error) {
      return message ? error : true;
    }
  };

  const renderField = (_groupName: string, field: DynamicField) => {
    const fieldName = `${_groupName}.${field.field_name}`;
    const fieldValue = String(get(formik.values, fieldName) || '') || '';

    switch (field.field_type) {
      case 'text':
        return (
          <CustomInputLayout
            type="text"
            label={field.label}
            placeholder={field.label}
            key={fieldName}
            name={fieldName}
            labelPlacement={!fromDetails ? 'outside' : 'outside-left'}
            onChange={(ev: any) => {
              formik.setFieldTouched(fieldName, true);
              formik.handleChange(ev);
            }}
            value={fieldValue}
            maxLength={250}
            errorMessage={
              getIsInValid(fieldName, formik, true) ||
              (fieldValue.length > 250 && 'Maximum number of characters is 250')
            }
            isRequired={fromDetails && !isEditMode ? false : true}
            isDisabled={fromDetails && !isEditMode}
            classNames={{
              base: fromDetails
                ? 'grid auto-cols-[1fr] auto-rows-[1fr] grid-cols-[1fr_1fr] gap-[0px_0em]'
                : '',
              label: 'font-semibold text-base text-wrap text-[#172b4d]',
              input: !fieldValue ? 'text-xs' : '',
              inputWrapper: cn(
                'rounded-lg',
                getIsInValid(fieldName, formik, true) || fieldValue.length > 250
                  ? 'border-1 border-solid border-[red]'
                  : ''
              ),
              mainWrapper: fromDetails ? 'w-full' : '',
            }}
            className="mb-2 data-[disabled]:text-[#172B4D] data-[disabled]:opacity-100"
          />
        );
      case 'textArea':
        return (
          <Textarea
            label={field.label}
            placeholder={field.label}
            key={fieldName}
            name={fieldName}
            labelPlacement={!fromDetails ? 'outside' : 'outside-left'}
            onChange={(ev) => {
              formik.setFieldTouched(fieldName, true);
              formik.handleChange(ev);
            }}
            value={fieldValue}
            description={fieldValue ? `${fieldValue.length} of 750 characters` : 'Required'}
            maxLength={750}
            errorMessage={
              getIsInValid(fieldName, formik, true) ||
              (fieldValue.length > 750 && 'Character count exceeded')
            }
            isRequired={fromDetails && !isEditMode ? false : true}
            isDisabled={fromDetails && !isEditMode}
            classNames={{
              base: fromDetails
                ? 'grid auto-cols-[1fr] auto-rows-[1fr] grid-cols-[1fr_1fr] gap-[0px_0em] relative'
                : '',
              label: 'font-semibold text-base text-wrap text-[#172b4d]',
              input: cn('max-h-[120px]', !fieldValue ? 'text-xs' : ''),
              inputWrapper: cn(
                'rounded-lg',
                getIsInValid(fieldName, formik, true) || fieldValue.trim().split(/\s+/).length > 150
                  ? 'border-1 border-solid border-[red]'
                  : ''
              ),
              mainWrapper: 'w-full',
              helperWrapper: fromDetails ? 'absolute right-0 top-[135px]' : '',
            }}
            className="mb-2 data-[disabled]:text-[#172B4D] data-[disabled]:opacity-100"
          />
        );
      case 'number':
        return (
          <Input
            type="number"
            label={field.label}
            placeholder={field.label}
            key={fieldName}
            name={fieldName}
            labelPlacement={!fromDetails ? 'outside' : 'outside-left'}
            onChange={(ev) => {
              formik.setFieldTouched(fieldName, true);
              formik.handleChange(ev);
            }}
            value={fieldValue}
            isInvalid={getIsInValid(fieldName, formik)}
            errorMessage={getIsInValid(fieldName, formik, true)}
            isRequired={fromDetails && !isEditMode ? false : true}
            isDisabled={fromDetails && !isEditMode}
            classNames={{
              base: fromDetails
                ? 'grid auto-cols-[1fr] auto-rows-[1fr] grid-cols-[1fr_1fr] gap-[0px_0em]'
                : '',
              label: 'font-semibold text-base text-wrap text-[#172b4d]',
              input: !fieldValue ? 'text-xs' : '',
              mainWrapper: 'w-full',
              inputWrapper: 'rounded-lg',
            }}
            className="mb-2 data-[disabled]:text-[#172B4D] data-[disabled]:opacity-100"
          />
        );
      case 'select':
        return (
          <Select
            variant="bordered"
            label={field.label}
            placeholder={`Choose a ${field.label}`}
            labelPlacement={!fromDetails ? 'outside' : 'outside-left'}
            key={fieldName}
            name={fieldName}
            onChange={(ev) => {
              formik.setFieldTouched(fieldName, true);
              formik.handleChange(ev);
            }}
            defaultSelectedKeys={fieldValue ? [fieldValue] : []}
            errorMessage={getIsInValid(fieldName, formik, true)}
            isDisabled={fromDetails && !isEditMode}
            isRequired={fromDetails && !isEditMode ? false : true}
            classNames={{
              base: fromDetails
                ? 'grid auto-cols-[1fr] auto-rows-[1fr] grid-cols-[1fr_1fr] gap-[0px_0em] items-center'
                : '',
              label: 'font-semibold text-base text-wrap text-[#172b4d]',
              value: !fieldValue ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
              innerWrapper: 'pl-3',
              trigger: cn(
                'rounded-[16px] min-h-[48px] shadow-none data-[focus=true]:border-default',
                getIsInValid(fieldName, formik, true) ? 'border-1 border-solid border-[red]' : ''
              ),
              errorMessage: 'absolute',
            }}
            className="mb-2 opacity-100"
          >
            {lovData
              .filter((lov) => lov.fieldName === field.lov_group_name)
              .map((item) => {
                const _value = item.value.includes('custom') ? 'custom' : item.id;
                return (
                  <SelectItem key={_value} value={_value}>
                    {item.label}
                  </SelectItem>
                );
              })}
          </Select>
        );
      case 'multiselect':
        return (
          <Select
            label={field.label}
            labelPlacement={!fromDetails ? 'outside' : 'outside-left'}
            placeholder={field.label}
            key={fieldName}
            name={fieldName}
            selectionMode="multiple"
            isMultiline={true}
            onChange={(ev) => {
              formik.setFieldTouched(fieldName, true);
              formik.handleChange(ev);
            }}
            selectedKeys={fieldValue ? fieldValue.split(',') : []}
            errorMessage={getIsInValid(fieldName, formik, true)}
            isDisabled={fromDetails && !isEditMode}
            classNames={{
              base: fromDetails
                ? 'grid auto-cols-[1fr] auto-rows-[1fr] grid-cols-[1fr_1fr] gap-[0px_0em] items-center'
                : '',
              label: 'font-semibold text-base text-wrap text-[#172b4d]',
              value: !fieldValue ? 'text-xs' : '',
              trigger: cn(
                'rounded-lg',
                getIsInValid(fieldName, formik, true) ? 'border-1 border-solid border-[red]' : ''
              ),
              errorMessage: 'absolute',
            }}
            isRequired={fromDetails && !isEditMode ? false : true}
            className="mb-4 opacity-100"
            renderValue={(items) => {
              return (
                <div className="flex flex-wrap gap-2">
                  {items.map((item) => (
                    <Chip
                      key={item.key}
                      className="rounded-md"
                      onClose={() => {
                        const selectedIndex = items.findIndex((a) => a.key === item.key);
                        items.splice(selectedIndex, 1);
                        formik.setFieldValue(fieldName, items.map((i) => i.key).join(','));
                      }}
                    >
                      {item.rendered}
                    </Chip>
                  ))}
                </div>
              );
            }}
          >
            {lovData
              .filter((lov) => lov.fieldName === field.lov_group_name)
              .map((item) => {
                const _value = item.value.includes('custom') ? 'custom' : item.id;
                return (
                  <SelectItem key={_value} value={_value}>
                    {item.label}
                  </SelectItem>
                );
              })}
          </Select>
        );
      case 'colorpicker':
        return (
          <div
            className={
              fromDetails
                ? 'grid auto-cols-[1fr] auto-rows-[1fr] grid-cols-[1fr_1fr] gap-[0px_0em] items-center'
                : 'flex gap-1 flex-col'
            }
            style={{ pointerEvents: fromDetails && !isEditMode ? 'none' : 'all' }}
          >
            <>
              <h5 className="font-semibold text-base text-[#172b4d]">
                {field.label}
                <span className="text-[#f31260]">*</span>
              </h5>
              {fromDetails ? null : <p className="font-medium text-sm">Pick a color</p>}
            </>

            <div className="flex items-center gap-4">
              {colorFields.map((cf) => (
                <ColorPicker
                  key={cf.id}
                  id={cf.id}
                  color={cf.color}
                  handleChange={(id: string, color: string) => {
                    const updatedColorPickers = colorFields.map((picker) =>
                      picker.id === id ? { ...picker, color } : picker
                    );
                    setColorFields(updatedColorPickers);
                    formik.setFieldValue(fieldName, JSON.stringify(updatedColorPickers));
                  }}
                  handleDelete={(id: string) => {
                    const updatedColorPickers = colorFields.filter((picker) => picker.id !== id);
                    setColorFields(updatedColorPickers);
                    formik.setFieldValue(fieldName, JSON.stringify(updatedColorPickers));
                  }}
                />
              ))}
              {(fromDetails && !isEditMode) || colorFields.length >= 5 ? null : (
                <ColorPicker
                  isAddNew
                  id={new Date().getTime().toString()}
                  color={'#FFFFFF'}
                  handleChange={(id: string, color: string) => {
                    const newObj = { id, color: color };
                    const updatedColorPickers = [...colorFields, newObj];
                    setColorFields(updatedColorPickers);
                    formik.setFieldValue(fieldName, JSON.stringify(updatedColorPickers));
                  }}
                  handleDelete={() => {
                    console.log('[handle color delete]');
                  }}
                />
              )}
            </div>
          </div>
        );
      default:
        console.error(`Unsupported field type ${field.field_type}`);
        return <p>Unsupported field type</p>;
    }
  };

  const renderGroups = (_groupName: string) => {
    return (
      <div className="flex gap-[1rem] flex-wrap justify-between" key={_groupName}>
        {fieldData[_groupName].map((field) => (
          <div
            style={{
              width:
                field.fields_per_row === 1
                  ? '100%'
                  : `calc(${Math.floor(100 / field.fields_per_row)}% - 1rem)`,
            }}
            key={field.id}
          >
            {renderField(_groupName, field)}
          </div>
        ))}
      </div>
    );
  };

  return <div className="flex gap-4 flex-col flex-wrap">{renderGroups(groupName)}</div>;
};
