import { Input, cn } from '@nextui-org/react';
import { signInWithMagicLink } from 'infra/providers/firebase';
import { Button } from 'presentation/components';
import { useState } from 'react';
import arrow_right from 'presentation/assets/arrow_right.svg';

export const MagicLink = ({ isChecked }: { isChecked: boolean }) => {
  const [email, setEmail] = useState('');

  function validateEmail(input: string) {
    const re = /\S+@\S+\.\S+/;
    return input ? re.test(input) : true;
  }

  return (
    <>
      <Input
        size="sm"
        type="email"
        variant="bordered"
        placeholder="Enter your email address"
        isClearable
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
        onClear={() => setEmail('')}
        className={cn('w-4/5 lg:min-w-[480px]', 'mb-6')}
        classNames={{
          base: 'min-h-[60px]',
          label: 'text-white',
          inputWrapper: 'px-7 h-12 min-h-[60px] shadow-none rounded-[20px]',
          input: 'text-[16px] text-[#232323] font-medium',
        }}
      />
      <Button
        size="lg"
        radius="full"
        disabled={!email}
        onClick={() => {
          signInWithMagicLink(email);
        }}
        className={cn(
          'w-4/5 lg:min-w-[480px] min-h-[60px]',
          'bg-[#99CF63]',
          'text-[16px] font-medium'
        )}
        isDisabled={!email || !validateEmail(email) || !isChecked}
        endContent={<img src={arrow_right} alt="arrow_right" className="w-5 h-5" />}
      >
        Continue
      </Button>
    </>
  );
};
