import { adminApi } from 'adminApp/api';
import { Lov } from 'adminApp/models/create-lov';
import { useState } from 'react';

export const useLovGroupNames = () => {
  const [groups, setGroups] = useState<{ id: string; label: string }[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getAll = async () => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllLovGroupNames();
      setGroups(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    getAllGroupNames: getAll,
    groupNames: groups,
    isFetchingGroupNames: isFetching,
  };
};

export const useLovFieldNames = () => {
  const [fields, setFields] = useState<{ id: string; label: string }[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getAll = async (groupName: string) => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllLovFieldNames(groupName);
      setFields(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    getAllFieldNames: getAll,
    fieldNames: fields,
    isFetchingFieldNames: isFetching,
  };
};

export const useLovsList = () => {
  const [lovs, setLovs] = useState<Lov[]>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getAllLovsByFieldName = async (fieldName: string) => {
    try {
      setIsFetching(true);
      const res = await adminApi.getLovByFieldName(fieldName);
      setLovs(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const getAllLovsByGroupName = async (groupName: string) => {
    try {
      setIsFetching(true);
      const res = await adminApi.getLovByGroupName(groupName);
      setLovs(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const deleteLov = async (lovId: string, fieldName: string, groupName: string) => {
    adminApi
      .deleteLov(lovId)
      .then(() => {
        if (fieldName) {
          getAllLovsByFieldName(fieldName);
        } else if (groupName) {
          getAllLovsByGroupName(groupName);
        }
        alert('Deleted LOV');
      })
      .catch(() => {
        alert('Unable to delete LOV');
      });
  };

  return {
    getAllLovsByFieldName,
    getAllLovsByGroupName,
    deleteLov,
    lovs,
    isFetchingLovs: isFetching,
  };
};
