import { adminApi } from 'adminApp/api';
import { useState } from 'react';

type LOVGroupName = { id: string; label: string };

export const useLovGroupNames = () => {
  const [isFetchingLOVGroupNames, setIsFetchingLOVGroupNames] = useState(false);
  const [lovGroupNames, setData] = useState<LOVGroupName[]>([]);

  const getAll = async () => {
    setIsFetchingLOVGroupNames(true);
    setData([]);
    adminApi
      .getAllLovFieldNames()
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        alert('Unable to get LOV group names');
      })
      .finally(() => {
        setIsFetchingLOVGroupNames(false);
      });
  };

  return {
    getAll,
    lovGroupNames,
    isFetchingLOVGroupNames,
  };
};
