import { Skeleton } from '@nextui-org/react';
import { useState } from 'react';

export const Image = ({ url, alt }: { url: string; alt: string }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Skeleton isLoaded={isLoaded} className="rounded">
      <figure>
        <img
          src={url}
          alt={alt}
          className="object-cover w-20 h-20 rounded"
          onLoad={() => {
            if (!isLoaded) {
              setIsLoaded(true);
            }
          }}
        />
      </figure>
    </Skeleton>
  );
};
