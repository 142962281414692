import { Chip, Textarea, cn } from '@nextui-org/react';
import { Button } from 'presentation/components';
import { WizardFooter } from './WizardFooter';
import { Inspiration } from 'domain/modules';

interface IStyleSelectionFormProps {
  onNext: () => void;
  onBack: () => void;
  setShowInspirations: (val: boolean) => void;
  selectedStyle: string;
  inspirations: Array<Inspiration>;
  selectedInspirations: Array<string>;
  styleDescription: string;
  setStyleDescription: (val: string) => void;
}

export const StyleSelectionForm = ({
  onNext,
  onBack,
  setShowInspirations,
  inspirations,
  selectedInspirations,
  styleDescription,
  setStyleDescription,
}: IStyleSelectionFormProps) => {
  return (
    <>
      <div className="grow flex flex-col gap-4 2xl:gap-8">
        {/* <div>
          <h5 className="text-[#232323] text-[16px] font-medium mb-2">Selected style</h5>
          <Chip
            radius="sm"
            classNames={{ base: 'bg-[#99CF63]', content: 'text-[#232323] text-sm' }}
          >
            {selectedStyle}
          </Chip>
        </div> */}

        <div className="flex items-baseline gap-4 flex-nowrap overflow-x-auto">
          {(selectedInspirations || []).map((inspirationId: string, index) => {
            const inspiration = inspirations.find((i) => i.id === inspirationId);

            return (
              <div key={index} className="h-[100px] w-[100px] bg-gray-100 rounded-md truncate">
                <img
                  src={inspiration?.media_url}
                  alt={inspiration?.style_name}
                  className="h-full w-full"
                />
              </div>
            );
          })}
          {selectedInspirations.length > 0 && (
            <Button
              variant="light"
              size="sm"
              className="text-[#0F5ED6] text-sm font-medium"
              onClick={() => setShowInspirations(true)}
            >
              Switch style Inspirations
            </Button>
          )}
        </div>

        <Textarea
          label="What do you like about the selected inspirations? (e.g., color, font, theme)"
          placeholder="Describe what stands out to you in the selected inspirations, such as typeface, graphics, background, layout, or color accents."
          labelPlacement="outside"
          description={`${styleDescription.length} of 750 characters`}
          maxLength={750}
          value={styleDescription}
          onChange={(ev) => setStyleDescription(ev.target.value)}
          disableAutosize
          classNames={{
            input: cn('h-[80px]', !styleDescription ? 'text-xs w-3/5 italic' : ''),
            label: 'text-[#232323] text-[16px] leading-[22px] font-medium',
            inputWrapper: styleDescription.length > 750 ? 'border-1 border-solid border-[red]' : '',
          }}
          errorMessage={styleDescription.length > 750 && 'Limit exceeded'}
        />
      </div>
      <WizardFooter onNext={onNext} onPrev={onBack} nextTitle="Choose a Dimension" />
    </>
  );
};
