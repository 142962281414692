import { Progress, cn } from '@nextui-org/react';
import { useProjectDetailContext } from '../../project-detail/context'; // Adjust the path as needed

interface IProps {
  title: string;
  totalSteps?: number;
  currentStep?: number;
  isInitialStep?: boolean;
}

export const CreateProjectHeader = ({
  totalSteps = 5,
  currentStep = 1,
  isInitialStep = false,
}: IProps) => {
  const { isProjectCreated } = useProjectDetailContext(); // Access isProjectCreated from context

  return (
    <div className={cn(!isInitialStep ? '2xl:mb-5' : '', 'mb-5')}>
      <h1
        className={cn(
          'text-[#0D1216] font-bold',
          'text-3xl 2xl:text-5xl',
          !isInitialStep ? 'mb-2' : ''
        )}
      >
        {isProjectCreated ? 'Edit Project' : 'Create Project'}
      </h1>
      {!isInitialStep && (
        <div className="flex items-center justify-between">
          <div className="w-[85%] 2xl:w-[450px]">
            <Progress
              isStriped
              color="default"
              value={(currentStep / totalSteps) * 100}
              className="min-w-[175px] max-w-[200px] h-2 progress-loader"
              classNames={{
                track: 'bg-[#F0F0F0]',
              }}
            />
          </div>
          <span className="text-xs text-[#828282]">
            Step {currentStep} of {totalSteps}
          </span>
        </div>
      )}
    </div>
  );
};
