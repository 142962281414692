import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
} from '@nextui-org/react';
import { useCreate } from './hooks/useCreate';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CreateCategoryDto } from 'adminApp/models/create-category';
import { useEffect } from 'react';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: CreateCategoryDto;
}

export const CreateCategory = ({ refreshList, Trigger, isUpdate, data }: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, update, reset } = useCreate();

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      min_credit: data ? String(data?.min_credit) : '',
      max_credit: data ? String(data?.max_credit) : '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      min_credit: Yup.number().required('Required'),
      max_credit: Yup.number().required('Required'),
    }),
    onSubmit: async (values) => {
      if (!isUpdate) {
        await create({
          max_credit: parseInt(values.max_credit, 10),
          min_credit: parseInt(values.min_credit, 10),
          name: values.name,
        });
      } else {
        if (data && data.id) {
          await update(data.id, {
            max_credit: parseInt(values.max_credit, 10),
            min_credit: parseInt(values.min_credit, 10),
            name: values.name,
          });
        }
      }

      reset();
      formik.resetForm();
      onClose();
      refreshList();
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        onClose={() => {
          reset();
          formik.resetForm();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Category
              </ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  label="Name"
                  placeholder="Enter a name"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                />
                <Input
                  type="number"
                  label="Minimum Credit"
                  placeholder="Enter a minimum credit"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.min_credit)}
                  name="min_credit"
                />
                <Input
                  type="number"
                  label="Maximum Credit"
                  placeholder="Enter a maximum credit"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.max_credit)}
                  name="max_credit"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => formik.submitForm()}
                  className="bg-black text-white"
                  disabled={isLoading || !formik.isValid}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Update' : 'Create'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
