import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@nextui-org/react';
import { useCreate } from './hooks/useCreate';
import { useEffect } from 'react';
import { FileUpload } from './FileUpload';
import { AssetUploadResponse } from 'infra/api';
import { ProjectAttachment } from 'adminApp/models/create-attachment';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: ProjectAttachment;
  projectId: string;
  projectIterationId: string;
}

export const AddSourceFilesThumbnail = ({
  refreshList,
  Trigger,
  isUpdate,
  data,
  projectId,
  projectIterationId,
}: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, reset } = useCreate();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const uploadAttachment = () => {
    onClose();
  };

  const handleSaveUploadFile = (asset: AssetUploadResponse, fileName: string) => {
    if (!isUpdate) {
      create({
        name: fileName,
        project_id: projectId,
        project_iteration_id: projectIterationId,
        assetId: asset.id,
        project_asset_type: 'SOURCE_FILES_THUMBNAIL',
      });
      refreshList();
    } else {
      if (data) {
        // update(data.id, {
        // project_id: projectId,
        // project_iteration_id: projectIterationId,
        // media_url: asset.url,
        // assetId: asset.id,
        // name: fileName,
        // });
        refreshList();
      }
    }
  };

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        onClose={() => {
          reset();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Attachment
              </ModalHeader>
              <ModalBody>
                <FileUpload
                  hasUploadedFiles={false}
                  path={`deliverables/${projectId}/${projectIterationId}`}
                  handleSaveUploadFile={handleSaveUploadFile}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={uploadAttachment}
                  className="bg-black text-white"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Done' : 'Done'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
