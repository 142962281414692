import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@nextui-org/react';
import { useCreate } from './hooks/useCreate';
import { useEffect } from 'react';
import { Inspiration } from 'adminApp/models/create-inspiration';
import { StyleType } from 'adminApp/models/create-form-with-combination';
import { FileUpload } from './FileUpload';
import { AssetUploadResponse } from 'infra/api';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: Inspiration;
  subcategoryId: string;
  categoryId: string;
  styleName: StyleType;
}

export const CreateInspiration = ({
  refreshList,
  Trigger,
  isUpdate,
  data,
  categoryId,
  subcategoryId,
  styleName,
}: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, update, reset } = useCreate();

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  const uploadInspirations = () => {
    onClose();
  };

  const handleSaveUploadFile = (asset: AssetUploadResponse) => {
    if (!isUpdate) {
      create({
        category_id: categoryId,
        subcategory_id: subcategoryId,
        style_name: styleName,
        media_url: asset.url,
        assetId: asset.id,
      });
      refreshList();
    } else {
      if (data) {
        update(data.id, {
          category_id: categoryId,
          subcategory_id: subcategoryId,
          style_name: styleName,
          media_url: asset.url,
          assetId: asset.id,
        });
        refreshList();
      }
    }
  };

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        onClose={() => {
          reset();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Inspiration
              </ModalHeader>
              <ModalBody>
                <FileUpload
                  hasUploadedFiles={false}
                  path={`inspirations/${categoryId}/${subcategoryId}/${styleName}`}
                  handleSaveUploadFile={handleSaveUploadFile}
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={uploadInspirations}
                  className="bg-black text-white"
                  disabled={isLoading}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Done' : 'Done'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
