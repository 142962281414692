import { adminApi } from 'adminApp/api';
import { Credits } from 'adminApp/models/create-credits';
import { StyleType } from 'adminApp/models/create-form-with-combination';
import { useState } from 'react';

type FieldCombinationArgs = {
  categoryId: string;
  subCategoryId: string | null;
  style_name: StyleType | null;
  typeId: string | null;
};

export const useGetAll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Credits[]>([]);

  const getAll = async (args: Partial<FieldCombinationArgs>) => {
    setIsLoading(true);
    setData([]);
    adminApi
      .getAllCreditsByCombination(args)
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        // alert('Unable to get credits');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteCredit = async (creditId: string, args: Partial<FieldCombinationArgs>) => {
    adminApi
      .deleteCredit(creditId)
      .then(() => {
        getAll(args);
        alert('Deleted credit');
      })
      .catch(() => {
        alert('Unable to delete credit');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    data,
    getAll,
    deleteCredit,
  };
};
