import { Modal, ModalBody, ModalContent } from '@nextui-org/react';
import payment_successful from 'presentation/assets/payment_successful.svg';
import { Button } from 'presentation/components';
import { useMemo } from 'react';

type SuccessType = 'credits' | 'plans';

export const PaymentSuccessful = ({
  paymentModalIsOpen,
  paymentModalOnClose,
  successType = 'plans' as SuccessType,
  planType,
  creditsCount,
}: {
  paymentModalIsOpen: boolean;
  paymentModalOnClose: () => void;
  successType: SuccessType;
  planType: string;
  creditsCount: number;
}) => {
  const packageName = useMemo(() => {
    const planname = planType || '';

    const formattedName = planname
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return formattedName;
  }, [planType]);

  const bodyContent: Record<SuccessType, { message: string; content: string }> = {
    credits: {
      message: 'Credits Successfully added!',
      content: `Your ${packageName} credits purchase of ${creditsCount} credits was successful. Enjoy uninterrupted access and let your creativity flourish!`,
    },
    plans: {
      message: 'Thank you for subscribing!',
      content: `You have successfully subscribed to our ${packageName} Plan, ensuring a month filled with top-notch services.`,
    },
  };

  const { message, content } = bodyContent[successType];

  return (
    <Modal isOpen={paymentModalIsOpen} size="xl" classNames={{ body: 'p-0 py-8 ' }} hideCloseButton>
      <ModalContent>
        {() => (
          <ModalBody>
            <img src={payment_successful} alt="Payment Successful" />
            <div className="flex flex-col items-center justify-center gap-6">
              <h3 className="text-[#0D1216] font-bold text-2xl">{message}</h3>
              <p className="text-[#828282] text-center font-medium w-4/5">{content}</p>
              <Button
                radius="md"
                onPress={paymentModalOnClose}
                className="bg-[#232323] text-white px-10 text-lg rounded-full"
              >
                Continue
              </Button>
            </div>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
