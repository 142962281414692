import axios from 'axios';
import { Company, ICompanyService } from 'domain/modules';
import { Lov } from 'domain/shared/models/lov.model';
import has from 'lodash/has';

class CompanyService implements ICompanyService {
  getCompanySetupLov(): Promise<Lov[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Lov[]>('/api/lov/groupName/companySetup')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getCompany(): Promise<Company> {
    return new Promise((resolve, reject) => {
      axios
        .get<Company>('/api/company')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
  createCompany(): Promise<Company> {
    return new Promise((resolve, reject) => {
      axios
        .post<Company>('/api/company')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
  updateCompany(company: Company, stepName?: string): Promise<Company> {
    return new Promise((resolve, reject) => {
      const params = stepName ? `?stepName=${stepName}` : '';
      axios
        .put<Company>(`/api/company${params}`, company)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
}

export const companyService = new CompanyService();
