import axios from 'axios';
import { Brand, IBrandService } from 'domain/modules/brand';
import has from 'lodash/has';

class BrandService implements IBrandService {
  createBrand(name: string): Promise<Brand> {
    return new Promise((resolve, reject) => {
      axios
        .post<Brand>('/api/brand', { name })
        .then((res) => {
          setTimeout(() => {
            resolve(res.data);
          }, 400);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  updateBrand(brand: Brand): Promise<Brand> {
    return new Promise((resolve, reject) => {
      axios
        .put<Brand>(`/api/brand/${brand.id}`, brand)
        .then((res) => {
          setTimeout(() => {
            resolve(res.data);
          }, 400);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getBrandById(id: string): Promise<Brand> {
    return new Promise((resolve, reject) => {
      axios
        .get<Brand>(`/api/brand/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getAllBrands(): Promise<Brand[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Brand[]>('/api/brand')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  deleteBrand(brandId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/brand/${brandId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
}

export const brandService = new BrandService();
