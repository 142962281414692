import { getMessaging, getToken } from 'firebase/messaging';
import { app } from './setup';
import { notificationService } from 'infra/api/modules/notification';

const messaging = getMessaging(app);

export const getNotificationToken = () => {
  const isdeviceTokensaved = sessionStorage.getItem('isdeviceTokensaved');
  if (isdeviceTokensaved === 'true') {
    return;
  }
  return new Promise<void>((resolve, reject) => {
    console.log('trying to fetch FCM token');
    getToken(messaging, { vapidKey: import.meta.env.VITE_WEB_PUSH_CERTICATE_KEY })
      .then(async (currentToken) => {
        if (currentToken) {
          sessionStorage.setItem('isdeviceTokensaved', 'true');
          await notificationService.registerDevice(currentToken);
        } else {
          requestPermission();
        }
        resolve();
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        reject();
      });
  });
};

const requestPermission = () => {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  });
};
