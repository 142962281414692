/* eslint-disable import/no-unused-modules */
import { Popover, PopoverContent, PopoverTrigger, cn } from '@nextui-org/react';
import { Button } from 'presentation/components';
import { useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';

export const ColorPicker = ({
  id,
  color,
  handleChange,
  handleDelete,
  isAddNew,
}: {
  id: string;
  color: string;
  isAddNew?: boolean;
  handleChange: (id: string, color: string) => void;
  handleDelete: (id: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);

  const handleChangeComplete = () => {
    handleChange(id, selectedColor);
    setIsOpen(false);
  };

  const onChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      placement="bottom"
      classNames={{
        base: ['before:bg-default-200'],
        content: [
          'py-0 px-0',
          'bg-gradient-to-br from-white to-default-300',
          'dark:from-default-100 dark:to-default-50',
        ],
      }}
    >
      <PopoverTrigger>
        <div className="flex flex-col items-center gap-1">
          <div className="w-16 h-16 rounded border relative" style={{ backgroundColor: color }}>
            {isAddNew ? (
              <span className="flex w-full h-full items-center justify-center cursor-pointer">
                <i className="ri-add-fill" />
              </span>
            ) : (
              <div
                className={cn(
                  'absolute top-[-5px] right-[-5px]',
                  'w-5 h-5 rounded-full',
                  'flex items-center justify-center',
                  'bg-black text-white'
                )}
                onClick={() => handleDelete(id)}
              >
                <i className="ri-close-fill ri-sm" />
              </div>
            )}
          </div>
          <p className={cn('text-[#0d1216b3] text-xs', isAddNew ? '' : 'uppercase')}>
            {isAddNew ? 'Add New' : color}
          </p>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <div className="bg-white">
          <SketchPicker color={selectedColor} onChange={onChange} />
          <Button
            size="sm"
            color="primary"
            className="rounded-none"
            fullWidth
            onClick={handleChangeComplete}
          >
            Save
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
