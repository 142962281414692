import { Chip } from '@nextui-org/react';
import { DynamicField } from 'application/modules/shared/hooks/useDynamicForm';
import { Lov } from 'domain/shared';

export const getDynamicFieldValue = (
  rootObj: Record<string, string>,
  ansKey: string,
  flatFields: DynamicField[],
  fieldData: Record<string, DynamicField[]>,
  lovData: Lov[]
) => {
  const fieldObj = flatFields.find((f) => f.field_name === ansKey);
  if (fieldObj?.field_type === 'text') {
    return rootObj[ansKey] || <></>;
  }
  if (fieldObj?.field_type === 'textArea') {
    return rootObj[ansKey] || <></>;
  }
  if (fieldObj?.field_type === 'number') {
    return rootObj[ansKey] || <></>;
  }
  if (fieldObj?.field_type === 'select') {
    const lov = lovData.find((l) => l.id === rootObj[ansKey]);
    return lov?.label || <></>;
  }
  if (fieldObj?.field_type === 'multiselect') {
    const values = ((rootObj[ansKey] as any) || '').split(',');
    const returnValues: any[] = [];
    values.forEach((value: any) => {
      const lov = lovData.find((l) => l.id === value);
      returnValues.push(lov?.label);
    });
    return (
      <div className="flex gap-2">
        {returnValues.map((v, index) => {
          if (!v) {
            return null;
          }
          return <Chip key={index}>{v}</Chip>;
        })}
      </div>
    );
  }
  if (fieldObj?.field_type === 'colorpicker') {
    let val;
    try {
      val = JSON.parse(rootObj[ansKey] as any);
    } catch (ex) {
      // Do Nothing
    }
    if (!val || !Array.isArray(val)) {
      return <span>-</span>;
    }
    return (
      <div className="flex gap-2">
        {val.map((i) => (
          <div
            style={{ height: '40px', width: '40px', borderRadius: 5, backgroundColor: i.color }}
            key={i.id}
          />
        ))}
      </div>
    );
  }
  // console.log(fieldObj, fieldData, lovData);
  return null;
};
