import { adminApi } from 'adminApp/api';
import { ProjectType } from 'adminApp/models/create-type';
import { useState } from 'react';

export const useType = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [typeList, setTypeList] = useState<ProjectType[]>([]);

  const getAllTypes = async (subCategoryId: string) => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllTypes(subCategoryId);
      setTypeList(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    typeList,
    getAllTypes,
  };
};
