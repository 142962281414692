import { adminApi } from 'adminApp/api';
import { Project } from 'adminApp/models/project';
import { useState } from 'react';

export const useProjects = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [projectList, setProjectList] = useState<Project[]>([]);

  const getAllProjects = async () => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllProjects();
      setProjectList(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    projectList,
    getAllProjects,
  };
};
