import { useEffect, useState } from 'react';
import { CreateField } from './Create';
import { FieldsTable } from './Table';
import { Button } from 'presentation/components';
import { useCategories } from './hooks/useCategories';
import { Select, SelectItem } from '@nextui-org/react';
import { useSubCategories } from './hooks/useSubCategories';
import { useType } from './hooks/useType';
import { styleNameList } from './columns';
import { StyleType } from 'adminApp/models/create-form-with-combination';

export const Field = () => {
  const [refetch, setRefetch] = useState(false);
  const { categoryList, getAllCategories, isFetching } = useCategories();
  const {
    subcategoryList,
    getAllSubCategories,
    isFetching: isFetchingSubCategoryList,
  } = useSubCategories();
  const { typeList, getAllTypes, isFetching: isFetchingTypesList } = useType();
  const [categoryId, setCategoryId] = useState<string>();
  const [subcategoryId, setSubCategoryId] = useState<string>();
  const [typeId, setTypeId] = useState<string>();
  const [styleType, setStyleType] = useState<StyleType>();

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (categoryId) {
      setSubCategoryId('');
      getAllSubCategories(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    if (categoryId && subcategoryId) {
      setTypeId('');
      getAllTypes(subcategoryId);
    }
  }, [categoryId, subcategoryId]);

  return (
    <div>
      <div className="mb-4 flex flex-row-reverse justify-between items-center">
        {!categoryId ? (
          <div />
        ) : (
          <CreateField
            categoryId={categoryId}
            subcategoryId={subcategoryId ? subcategoryId : null}
            typeID={typeId ? typeId : null}
            styleName={styleType ? styleType : null}
            Trigger={({ open }) => {
              return (
                <Button color="primary" className="bg-black text-white" onClick={open}>
                  Add Field
                </Button>
              );
            }}
            refreshList={() => {
              setRefetch(true);

              setTimeout(() => {
                setRefetch(false);
              }, 100);
            }}
          />
        )}
        <div className="flex gap-4 flex-wrap">
          <Select
            items={categoryList}
            label="Category"
            placeholder="Select a category"
            className="w-72"
            isLoading={isFetching}
            defaultSelectedKeys={categoryId ? [categoryId] : []}
            onChange={(ev) => {
              setSubCategoryId('');
              setCategoryId(ev.target.value);
            }}
            isRequired
          >
            {(item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.name}
              </SelectItem>
            )}
          </Select>
          {categoryId ? (
            <Select
              items={subcategoryList}
              label="Sub Category"
              placeholder="Select a sub category"
              className="w-72"
              isLoading={isFetchingSubCategoryList}
              defaultSelectedKeys={subcategoryId ? [subcategoryId] : []}
              onChange={(ev) => setSubCategoryId(ev.target.value)}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.name}
                </SelectItem>
              )}
            </Select>
          ) : null}
          {categoryId ? (
            <Select
              items={typeList}
              label="Type"
              placeholder="Select a type"
              className="w-72"
              isLoading={isFetchingTypesList}
              defaultSelectedKeys={typeId ? [typeId] : []}
              onChange={(ev) => setTypeId(ev.target.value)}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.name}
                </SelectItem>
              )}
            </Select>
          ) : null}
          {categoryId ? (
            <Select
              items={styleNameList}
              label="Style"
              placeholder="Select a style"
              className="w-60"
              defaultSelectedKeys={styleType ? [styleType] : []}
              onChange={(ev) => setStyleType(ev.target.value as StyleType)}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.name}
                </SelectItem>
              )}
            </Select>
          ) : null}
        </div>
      </div>
      {refetch || !categoryId ? null : (
        <FieldsTable
          categoryId={categoryId}
          subcategoryId={subcategoryId ? subcategoryId : null}
          typeId={typeId ? typeId : null}
          styleName={styleType ? styleType : null}
        />
      )}
    </div>
  );
};
