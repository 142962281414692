import { adminApi } from 'adminApp/api';
import { User } from 'domain/modules';
import { useState } from 'react';

export const useUsers = () => {
  const [users, setUsers] = useState<Array<User>>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getAll = async () => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllUsers();
      if (Array.isArray(res)) {
        setUsers(res);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    users,
    isFetching,
    getAll,
  };
};
