import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@nextui-org/react';
import { useGetAll } from './hooks/useGetAll';
import { useCallback, useEffect } from 'react';
import { columns } from './columns';
import { CreateProjectAttachment } from './Create';
import { Loading } from 'adminApp/SharedComponents';
import { ProjectAttachment } from 'adminApp/models/create-attachment';

export const AttachmentTable = ({
  projectId,
  projectIterationId,
}: {
  projectId: string;
  projectIterationId: string;
}) => {
  const { data, isLoading, getAll, deleteAttachment } = useGetAll();

  useEffect(() => {
    getAll(projectId, projectIterationId);
  }, [projectId, projectIterationId]);

  const renderCell = useCallback(
    (listItem: ProjectAttachment, columnKey: React.Key) => {
      const cellValue = listItem[columnKey as keyof ProjectAttachment];

      switch (columnKey) {
        case 'actions':
          return (
            <div className="relative flex items-center gap-4">
              <CreateProjectAttachment
                projectId={projectId}
                projectIterationId={projectIterationId}
                isUpdate
                data={listItem}
                Trigger={({ open }) => {
                  return (
                    <Tooltip content="Edit Table">
                      <span
                        className="text-lg text-default-400 cursor-pointer active:opacity-50"
                        onClick={open}
                      >
                        <i className="ri-edit-box-line" />
                      </span>
                    </Tooltip>
                  );
                }}
                refreshList={() => {
                  getAll(projectId, projectIterationId);
                }}
              />

              <Tooltip color="danger" content="Delete Type">
                <span
                  className="text-lg text-danger cursor-pointer active:opacity-50"
                  onClick={() => {
                    if (listItem.id) {
                      deleteAttachment(listItem.id, projectId, projectIterationId);
                    }
                  }}
                >
                  <i className="ri-delete-bin-5-line" />
                </span>
              </Tooltip>
            </div>
          );
        default:
          return cellValue;
      }
    },
    [projectId, projectIterationId]
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Table aria-label="Inspiration Table" isHeaderSticky isStriped>
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={data}>
        {(item: ProjectAttachment) => (
          <TableRow key={item.id}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
