import { Tab, Tabs, cn } from '@nextui-org/react';
import { InspirationCard } from './InspirationCard';
import { Inspiration } from 'domain/modules';
import { useEffect, useState } from 'react';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { WizardFooter } from './WizardFooter';

interface IInspirationSelectionProps {
  onPrev: () => void;
  setShowInspirations: (val: boolean) => void;
  styles: Array<{ id: string; name: string }>;
  inspirations: Array<Inspiration>;
  selectedDefaultInspirations: Array<string>;
  setSelectedStyle: (val: string) => void;
  saveInspirationSelection: (ids: Array<string>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setProjectInspiration: (val: Array<any>) => void;
  setSelectedInspirations: (val: Array<string>) => void;
  setStyleDescription: (val: string) => void;
  isLogoCategory: boolean;
  selectedStyle: string;
  fetchingInspirations: boolean;
}

export const InspirationSelection = ({
  onPrev,
  setShowInspirations,
  styles,
  inspirations,
  selectedDefaultInspirations: selectedInspirations,
  setSelectedStyle,
  saveInspirationSelection,
  setProjectInspiration,
  setSelectedInspirations,
  setStyleDescription,
  isLogoCategory,
  selectedStyle,
  fetchingInspirations,
}: IInspirationSelectionProps) => {
  const [tempInspirations, setTempInspirations] = useState<Array<string>>(
    selectedInspirations || []
  );

  useEffect(() => {
    setTempInspirations(selectedInspirations);
  }, [(selectedInspirations || []).length]);

  const onItemChange = (id: string, isSelected: boolean) => {
    const withoutSelection = tempInspirations.filter((i) => i !== id);
    if (isSelected) {
      withoutSelection.push(id);
    }
    setTempInspirations(withoutSelection);
  };

  const onChangeStyle = (value: string) => {
    if (selectedStyle !== value) {
      setSelectedStyle(value);
      setTempInspirations([]);
      setSelectedInspirations([]);
      setProjectInspiration([]);
      setStyleDescription('');
    }
  };

  const onNextStep = () => {
    // Validate the number of selected inspirations
    if (isLogoCategory && tempInspirations.length > 1) {
      alert('You can only choose one logo style');
      return;
    } else if (isLogoCategory && tempInspirations.length < 1) {
      alert('You should choose 1 logo style to continue to next step');
      return;
    }

    if (!isLogoCategory && (tempInspirations.length < 1 || tempInspirations.length > 2)) {
      alert('You need to select between 1 and 2 style inspirations.');
      return;
    }

    // If validation passes, save the selections and proceed
    saveInspirationSelection(tempInspirations);
    setShowInspirations(false);
  };

  return (
    <>
      <div className="grow flex flex-col gap-4 2xl:gap-8 mb-8">
        <div>
          <h4 className="text-[#232323] text-[16px] font-medium">
            Pick a Style
            <span className="text-[#CA0B4A]">*</span>
          </h4>
          <p className={cn('text-[#828282] italic', 'text-sm 2xl:text-medium', 'w-3/5')}>
            Choose from an array of design inspirations, curated by our designers, to inspire your
            vision.
          </p>
        </div>

        <Tabs
          radius="full"
          size="lg"
          shouldSelectOnPressUp
          classNames={{
            tabList: 'w-full bg-[#F0F0F0]',
            tabContent: 'text-black text-sm group-data-[selected=true]:text-white',
            cursor: 'bg-black text-white',
          }}
          className="hidden"
          onSelectionChange={(ev) => onChangeStyle(ev.toString())}
          selectedKey={selectedStyle}
        >
          {styles.map((style) => (
            <Tab key={style.id} title={style.name} shouldSelectOnPressUp={true} />
          ))}
        </Tabs>

        <div className="grow overflow-auto">
          {fetchingInspirations ? (
            <div className="flex flex-col justify-center items-center">
              <Player
                src={inspiration_loader}
                className="player"
                loop
                autoplay
                style={{
                  height: '20vh',
                }}
              />
              <p className="absolute pt-16">Infinite inspiration beneath each loading pixel.</p>
            </div>
          ) : (
            <div
              className={cn(
                'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 max-h-[50vh]',
                fetchingInspirations ? 'justify-center' : ''
              )}
            >
              {inspirations.map((i) => (
                <InspirationCard
                  key={i.id}
                  data={i}
                  isDefaultSelected={selectedInspirations.includes(i.id)}
                  onChange={onItemChange}
                  isLogoCategory={isLogoCategory}
                  tempInspirations={tempInspirations}
                />
              ))}
            </div>
          )}
        </div>
        <div>
          <p className={cn('italic font-semibold text-black', 'text-sm 2xl:text-medium')}>
            {isLogoCategory
              ? '*You can choose one logo style'
              : '*You can choose up to 2 inspirations within that category.'}
          </p>
        </div>
      </div>
      <div className="pb-6">
        <WizardFooter
          onNext={onNextStep}
          onPrev={onPrev}
          nextTitle="Describe Your Choice of Style"
        />
      </div>
    </>
  );
};
