import { useProjectDetailContext } from '../../../context';
import { ProjectIterations } from '../../common/ProjectIterations';

export const IterationsContainer = () => {
  const { project, refetchProject } = useProjectDetailContext();

  return (
    <ProjectIterations projectId={project.id} project={project} refetchProject={refetchProject} />
  );
};
