import { Project, RecreateProject, TaskStatusType } from 'domain/modules';
import { projectsService } from 'infra/api';
import { ApiError } from 'infra/api/utils/errorHandler';
import { useState } from 'react';

export const useProjectDetail = (projectDefaultData?: Project) => {
  const [project, setProject] = useState<Project | undefined>(projectDefaultData || undefined);
  const [isFetching, setIsFetching] = useState(true);

  const getProjectDetail = async (projectId: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getProjectDetail(projectId);
      setProject(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const createProject = async (
    name: string,
    category_id: string,
    sub_category_id: string,
    type_id: string
  ) => {
    try {
      setIsFetching(true);
      const data = await projectsService.createProject(name, category_id, sub_category_id, type_id);
      setProject(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const cloneProject = async (project_id: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.cloneProject(project_id);
      setProject(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const recreateProject = async (project_id: string, _data: RecreateProject) => {
    try {
      setIsFetching(true);
      const data = await projectsService.recreateProject(project_id, _data);
      setProject(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const updateProject = async (_project: Project) => {
    try {
      setIsFetching(true);
      const data = await projectsService.updateProjectData(_project);
      setProject(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const updateProjectStatus = async (project: Project, toStatus: TaskStatusType) => {
    try {
      if (project.status === toStatus) {
        return;
      }
      setIsFetching(true);
      const data = await projectsService.updateProjectStatus(project.id, toStatus);
      setProject(data);
      return data;
    } catch (ex) {
      const exData = ex as ApiError;
      if (exData.message) {
        alert(exData.message);
        return;
      }
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    project,
    isFetching,
    getProjectDetail,
    createProject,
    cloneProject,
    updateProject,
    updateProjectStatus,
    recreateProject,
  };
};
