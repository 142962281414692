import { IBaseProps } from '../PageContainer';
import { Activities } from './activity';
import { DetailsContainer } from './details';

const LeftContentRoot = ({ children }: IBaseProps) => {
  return <>{children}</>;
};

export const LeftContent = {
  Root: LeftContentRoot,
  Activity: Activities,
  Details: DetailsContainer,
};
