import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Avatar,
  useDisclosure,
  cn,
} from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import logoWithTextImage from './../../assets/logo/logo_with_title_black.png';
import logout_red from './../../assets/logout_red.svg';
import { Button } from '../Button';
import { Link } from '../Link';

import './styles.css';
import { Drawer } from '../drawer';
import { useAuth, useNotification } from 'application/modules';
import { LogoutModal } from '../LogoutModal';
import { NotificationsDrawer } from '../Notifications';
import { useEffect, useState } from 'react';
import unread_notifications from '../../assets/unread_notifications.svg';

const LogoWithText = () => <img src={logoWithTextImage} alt="Picassofusion" />;

const menus = [
  {
    route: '/workboard',
    label: 'My Workboard',
  },
  {
    route: '/projects/listing',
    label: 'My Projects',
  },
  {
    route: '/faq',
    label: "FAQ's",
  },
];

const sideMenus = [
  {
    route: '/brand',
    label: 'Brand Setup',
  },
  {
    route: '/account',
    label: 'My Account',
  },
  {
    route: '/logout',
    label: 'Logout',
    type: 'click',
  },
];

const footerContent = {
  country: 'INDIA',
  address: 'Workafella Highstreet, 431, Anna Salai, Teynampet, Chennai, Tamil Nadu 600018',
  email: 'hello@picasso.com',
  contact: '+044 4263 2026',
};

export default function Header() {
  const { beUser } = useAuth();
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { notifications, isFetching, getAll, markAsRead } = useNotification();
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    const unread = notifications.filter((n) => !n.isViewed);
    setUnreadNotifications(unread.length);
  }, [notifications]);

  return (
    <>
      <Navbar
        maxWidth="full"
        classNames={{
          base: 'px-4 bg-[#F9F9F9]',
        }}
      >
        <NavbarContent justify="start">
          <NavbarBrand>
            <span
              onClick={() => {
                navigate('/workboard');
              }}
              className="cursor-pointer"
            >
              <LogoWithText />
            </span>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-5 py-0 px-2.5 max-h-14" justify="center">
          {menus.map((menu) => (
            <NavbarItem
              key={menu.route}
              isActive={window.location.pathname.includes(menu.route)}
              className={cn(
                ' text-[#232323] font-medium text-[16px] py-2 px-4 tracking-[0.25px]',
                window.location.pathname.includes(menu.route)
                  ? 'bg-[#BAE289] rounded-full font-medium'
                  : ''
              )}
            >
              <Link href={menu.route}>{menu.label}</Link>
            </NavbarItem>
          ))}
        </NavbarContent>

        <NavbarContent as="div" className="items-center gap-5" justify="end">
          <Drawer
            containerClass="bg-white shadow-[0_25px_50px_-12px_rgba(255,255,255,0.1)]"
            renderTrigger={(callback: () => void) => {
              return (
                <Button isIconOnly variant="light" onClick={() => callback()}>
                  {unreadNotifications ? (
                    <img src={unread_notifications} alt="unread_notifications" />
                  ) : (
                    <i className="ri-notification-2-line ri-2x"></i>
                  )}
                </Button>
              );
            }}
            renderContent={(onClose) => (
              <NotificationsDrawer
                onClose={onClose}
                setUnreadNotifications={setUnreadNotifications}
                notifications={notifications}
                isFetching={isFetching}
                getAll={getAll}
                markAsRead={markAsRead}
              />
            )}
            contentTitle="Notifications"
          />
          <Drawer
            containerClass="bg-black dark text-foreground bg-background"
            renderTrigger={(onClose: () => void) => {
              return (
                <Avatar
                  as="button"
                  className="transition-transform"
                  color="primary"
                  name={beUser?.name?.slice(0, 1) || 'A'}
                  size="sm"
                  src={beUser?.profile_picture}
                  onClick={onClose}
                />
              );
            }}
            renderContent={(onClose: () => void) => {
              return (
                <div className="flex flex-col grow">
                  <ul className="flex-1">
                    <div className="flex flex-col gap-5">
                      {sideMenus.map((menu, index) => {
                        const onClickMenu = () => {
                          if (!menu.type) {
                            navigate(menu.route);
                          } else {
                            switch (menu.route) {
                              case '/logout':
                                onOpen();
                                break;
                              default:
                                console.log('Case not found');
                            }
                          }
                          onClose();
                        };
                        return (
                          <li key={menu.route}>
                            <Button
                              variant="light"
                              tabIndex={index}
                              role="button"
                              className={cn(
                                'text-white text-2xl font-medium data-[hover]:bg-transparent',
                                menu.route === '/logout' ? 'text-[#EB4335]' : ''
                              )}
                              onClick={onClickMenu}
                              startContent={
                                menu.route === '/logout' && (
                                  <img src={logout_red} alt="logout_red" />
                                )
                              }
                            >
                              {menu.label}
                            </Button>
                          </li>
                        );
                      })}
                    </div>
                  </ul>
                  <div className="text-sm text-[#A9A9A9]">
                    <p className="text-white mb-2">{footerContent.country}</p>
                    <p className="mb-8">{footerContent.address}</p>
                    <p>Email: {footerContent.email}</p>
                    <p className="mb-2">Contact: {footerContent.contact}</p>
                  </div>
                </div>
              );
            }}
          />
        </NavbarContent>
      </Navbar>

      <LogoutModal isOpen={isOpen} onOpenChange={onOpenChange} />
    </>
  );
}
