import { adminApi } from 'adminApp/api';
import { Category } from 'adminApp/models/create-category';
import { useState } from 'react';

export const useCategories = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [categoryList, setCategoryList] = useState<Category[]>([]);

  const getAllCategories = async () => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllCategory();
      setCategoryList(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    categoryList,
    getAllCategories,
  };
};
