import { Button } from '@nextui-org/button';
import { useProjectDetailContext } from '../../context';
import axios from 'axios';
import { ProjectDeliverables } from '../common/ProjectDeliverables';

export const RightContent = () => {
  const { project } = useProjectDetailContext();

  return (
    <div className="attachment_section sticky right-6 flex flex-col items-start">
      <div className="w-[420px] flex items-center justify-between">
        <h5 className="text-[#172B4D] text-medium mb-2 font-medium">Attachments</h5>
        {project.deliverable_item.length > 1 ? (
          <Button
            isIconOnly
            variant="light"
            className=""
            onPress={() =>
              window.open(
                `${axios.defaults.baseURL}/api/storage/zipDownload/${
                  project.iteration[project.iteration.length - 1].id
                }`,
                '_blank'
              )
            }
          >
            <i className="ri-download-2-line text-black text-lg hover:bg-transparent " />
          </Button>
        ) : null}
      </div>
      <ProjectDeliverables projectId={project.id} project={project} />
    </div>
  );
};
