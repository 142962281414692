import { createAsyncThunk } from '@reduxjs/toolkit';
import { COMPANY_ACTIONS } from './company.actions';
import { companyService } from 'infra/api';
import { Company } from 'domain/modules';

export const getCompany = createAsyncThunk(COMPANY_ACTIONS.GET_COMPANY, async () => {
  const company = await companyService.getCompany();
  return company;
});

export const createCompany = createAsyncThunk(COMPANY_ACTIONS.CREATE_COMPANY, async () => {
  const company = await companyService.createCompany();
  return company;
});

export const updateCompany = createAsyncThunk(
  COMPANY_ACTIONS.UPDATE_COMPANY,
  async (data: { data: Company; stepName: string }) => {
    const company = await companyService.updateCompany(data.data, data.stepName);
    return company;
  }
);

export const getCompanySetupLov = createAsyncThunk(
  COMPANY_ACTIONS.GET_COMPANY_SETUP_LOV,
  async () => {
    const companySetupLov = await companyService.getCompanySetupLov();
    return companySetupLov;
  }
);
