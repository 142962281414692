import { useState } from 'react';
import { CreateCategory } from './Create';
import { CategoryTable } from './Table';
import { Button } from 'presentation/components';

export const Category = () => {
  const [refetch, setRefetch] = useState(false);
  return (
    <div>
      <div className="mb-4 flex flex-row-reverse">
        <CreateCategory
          Trigger={({ open }) => {
            return (
              <Button color="primary" className="bg-black text-white" onClick={open}>
                Add Category
              </Button>
            );
          }}
          refreshList={() => {
            setRefetch(true);

            setTimeout(() => {
              setRefetch(false);
            }, 100);
          }}
        />
      </div>
      {refetch ? null : <CategoryTable />}
    </div>
  );
};
