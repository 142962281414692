import { Player } from '@lottiefiles/react-lottie-player';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';

export const LoadingIndicator = () => {
  return (
    <div className="flex items-center justify-center">
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '75vh',
        }}
      />
      <p className="absolute pt-36">Fetching Project Details...</p>
    </div>
  );
};
