import axios from 'axios';
import { Lov } from 'domain/shared/models/lov.model';
import has from 'lodash/has';

export interface DynamicFieldsResponse {
  id: string;
  label: string;
  detail_view_label: string;
  group_name: string;
  field_name: string;
  field_type: string;
  fields_per_row: number;
  style: string;
  lov_group_name: string;
  form_id: string;
  order: number | null;
  is_conditional: boolean;
  conditional_field_id?: string;
  conditional_group_id?: string;
  validations?: string;
}

class DynamicFieldsService {
  getFormFields(
    categoryId: string,
    subcategoryId: string,
    typeId: string | null,
    styleName: string | null
  ): Promise<Array<DynamicFieldsResponse>> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      searchParams.set('categoryId', String(categoryId));
      searchParams.set('subcategoryId', String(subcategoryId));
      typeId && searchParams.set('typeId', String(typeId));
      styleName && searchParams.set('styleName', String(styleName));

      axios
        .get<Array<DynamicFieldsResponse>>(`/api/dynamic-forms?${searchParams.toString()}`)
        .then((res) => {
          const sortedArray = res.data.sort((a, b) => {
            if (a.order && b.order) {
              return a.order - b.order;
            }
            return 0;
          });
          resolve(sortedArray);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getDynamicFormsLov(fieldName: string): Promise<Lov[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Lov[]>(`/api/lov/fieldName/${fieldName}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
}

export const dynamicFieldsService = new DynamicFieldsService();
