import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import { CreateFaqDto } from 'adminApp/models/create-faq';
import { useFormik } from 'formik';
import { Button } from 'presentation/components';
import * as Yup from 'yup';
import { useFaqCreate } from './hooks/useFaqCreate';
import { useEffect } from 'react';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: CreateFaqDto;
}

export const FaqCreate = ({ refreshList, Trigger, isUpdate, data }: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, update, reset } = useFaqCreate();

  const formik = useFormik({
    initialValues: {
      question: data?.question || '',
      answer: data?.answer || '',
    },
    validationSchema: Yup.object({
      question: Yup.string().required('Required'),
      answer: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      if (!isUpdate) {
        await create({
          question: values.question,
          answer: values.answer,
        });
      } else {
        if (data && data.id) {
          await update(data.id, {
            question: values.question,
            answer: values.answer,
          });
        }
      }

      reset();
      formik.resetForm();
      onClose();
      refreshList();
    },
  });

  useEffect(() => {
    if (isOpen) {
      reset();
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        scrollBehavior="inside"
        onClose={() => {
          reset();
          formik.resetForm();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Faq
              </ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  label="Question"
                  placeholder="Enter the Question"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.question}
                  name="question"
                />
                <Input
                  label="Answer"
                  placeholder="Enter the Answer"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.answer}
                  name="answer"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => formik.submitForm()}
                  className="bg-black text-white"
                  disabled={isLoading || !formik.isValid}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Update' : 'Create___'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
