import { subscriptionService } from 'infra/api';

export const useCheckout = () => {
  const getHostedUrl = async (priceId: string, type: 'plan' | 'addon' = 'plan') => {
    const data = await subscriptionService.getHostedUrl(priceId, type);

    return data;
  };
  return { getHostedUrl };
};
