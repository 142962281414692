import { Invoice } from 'domain/modules/subscription';
import { subscriptionService } from 'infra/api';
import { useState } from 'react';

export const useInvoice = () => {
  const [invoices, setInvoices] = useState<Array<Invoice> | null>(null);

  const get = async () => {
    try {
      const data = await subscriptionService.getUserInvoice();
      setInvoices(data);
    } catch (ex) {
      console.log(ex);
    }
  };

  return {
    invoices: invoices,
    get,
  };
};
