import { useAuth } from 'application/modules';
import { getUser } from 'application/modules/auth/auth.thunks';
import { useAppDispatch } from 'application/shared';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

interface IProps {
  component: JSX.Element;
}

export const AdminAuthGuard = ({ component }: IProps) => {
  const { user, beUser, isLoading } = useAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!beUser) {
      dispatch(getUser());
    }
  }, [isLoading, beUser, dispatch]);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!beUser) {
    return null;
  }

  return <React.Fragment>{component}</React.Fragment>;
};
