import axios, { AxiosError } from 'axios';
import { Notification } from 'domain/modules';
import { handleApiError } from 'infra/api/utils/errorHandler';

class NotificationService {
  registerDevice(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let deviceType = 'desktop';
      try {
        deviceType = window.navigator.userAgent;
      } catch (ex) {
        console.log(ex);
      }
      axios
        .post<void>('/api/notification/register-device', {
          notification_token: token,
          device_type: deviceType,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getAllNotifications = (): Promise<Notification[]> => {
    return new Promise((resolve, reject) => {
      axios
        .get<Notification[]>('/api/notification')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  };

  markNotificationAsRead = (notificationId: string): Promise<Notification[]> => {
    return new Promise((resolve, reject) => {
      axios
        .patch<Notification[]>(
          `/api/notification/update-as-viewed?notificationId=${notificationId}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  };
}

export const notificationService = new NotificationService();
