export const columns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'fieldName',
    label: 'Field Name',
  },
  {
    key: 'groupName',
    label: 'Group Name',
  },
  {
    key: 'value',
    label: 'Value',
  },
  {
    key: 'label',
    label: 'LABEL',
  },
  {
    key: 'order',
    label: 'ORDER',
  },
  {
    key: 'iconName',
    label: 'ICON NAME',
  },
  {
    key: 'dependentFieldName',
    label: 'DEPENDENT GROUP NAME',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
