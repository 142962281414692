import { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../components/Header';
import { Button } from 'presentation/components';
import { Brand } from 'domain/modules/brand';
import { brandService } from 'infra/api';
import { PageSkeleton } from 'presentation/components/Skeleton';
import { Logos } from '../components/fields';
import { Accordion, AccordionItem } from '@nextui-org/react';
import { Colors } from '../components/fields/Colors';
import { Fonts } from '../components/fields/Fonts';
import { Photos } from '../components/fields/Photos';
import { SocialMedia } from '../components/fields/SocialMedia';
import { useBrand } from 'application/modules/brand';
import _ from 'lodash';

interface FileData {
  id?: string;
  url: string;
  assetId?: string;
}

interface MediaItem {
  id?: string;
  type: string;
  url: string;
  brandId?: string;
}

interface FontFileData {
  id?: string;
  name: string;
  url: string;
  assetId?: string;
  brandId?: string;
}

const transformMediaStructure = (data: MediaItem[] | FontFileData[]) => {
  return data.map((d) => {
    delete d.brandId;
    if (d.id && d.id.startsWith('temp')) {
      delete d.id;
    }
    return {
      ...d,
    };
  });
};

export const BrandDetail = () => {
  const { getAll } = useBrand();
  const { brandId } = useParams();
  const navigate = useNavigate();
  const [brand, setBrand] = useState<Brand | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [logoFiles, setLogoFiles] = useState<FileData[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [photos, setPhotos] = useState<FileData[]>([]);
  const [socialMediaList, setSocialMediaList] = useState<MediaItem[]>([]);
  const [fontFiles, setFontFiles] = useState<FontFileData[]>([
    {
      id: `temp-1`,
      name: '',
      url: '',
    },
    {
      id: `temp-2`,
      name: '',
      url: '',
    },
  ]);

  const brandRef = useRef<Brand | null>(null);

  useEffect(() => {
    brandRef.current = brand;
    if (brand && brand.colors && Array.isArray(brand.colors)) {
      setColors(brand.colors);
    }
  }, [brand]);

  const fetchBrand = () => {
    if (!brandId) return;
    brandService
      .getBrandById(brandId)
      .then((_brand) => {
        setBrand(_brand);
        brandRef.current = _brand;
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    if (brandId && !brand && !isFetching) {
      setIsFetching(true);
      fetchBrand();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId]);

  const onSave = (shouldNavigate = true) => {
    if (brandRef.current) {
      if (_.difference(brandRef.current.logo, logoFiles) && shouldNavigate) {
        updateStaticObject('logo', logoFiles);
      }

      if (_.difference(brandRef.current.images, photos) && shouldNavigate) {
        updateStaticObject('images', photos);
      }

      if (_.difference(brandRef.current.font, fontFiles) && shouldNavigate) {
        updateStaticObject('font', transformMediaStructure(fontFiles));
      }

      if (_.difference(brandRef.current.socal_media, socialMediaList) && shouldNavigate) {
        updateStaticObject('socal_media', transformMediaStructure(socialMediaList));
      }

      setIsSaving(true);
      brandService
        .updateBrand(brandRef.current)
        .then(() => {
          getAll();
          if (shouldNavigate) {
            navigate('/brand');
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const updateStaticObject = (key: keyof Brand, value: unknown) => {
    if (brandRef.current) {
      /* eslint-disable @typescript-eslint/no-explicit-any */
      (brandRef.current as any)[key] = value as any;
    }
  };

  if (isFetching) {
    return <PageSkeleton />;
  }

  if (!brand) {
    return <p>Brand not found</p>;
  }

  if (!brandId) {
    return <Navigate to="/brand" />;
  }

  return (
    <div className="p-8">
      <Header
        title={brand.name}
        renderAction={
          <Button
            variant="bordered"
            isLoading={isSaving}
            isDisabled={isSaving}
            className="rounded-3xl  text-[#0D1216] text-medium font-medium px-8"
            onClick={() => onSave()}
          >
            Done
          </Button>
        }
        noBanner
        isTitleEditable
        updateStaticObject={updateStaticObject}
        onSave={onSave}
      />
      <div className="px-6 mt-6 pb-4">
        <Accordion
          variant="splitted"
          className="brand_detail_accordion"
          defaultSelectedKeys="all"
          selectionMode="multiple"
        >
          <AccordionItem
            key="logos"
            aria-label="Logo"
            title={<p className="font-black">{`Logo (${logoFiles.length})`}</p>}
            className="brand_detail_accordion_item"
            keepContentMounted={true}
          >
            <Logos
              brandId={brandId}
              updateStaticObject={updateStaticObject}
              data={brandRef.current?.logo || []}
              onSave={onSave}
              logoFiles={logoFiles}
              setLogoFiles={setLogoFiles}
            />
          </AccordionItem>
          <AccordionItem
            key="colors"
            aria-label="Colors"
            title={<p className="font-black">{`Colors (${colors.length})`}</p>}
            className="brand_detail_accordion_item"
            keepContentMounted={true}
          >
            <Colors
              updateStaticObject={updateStaticObject}
              data={brandRef.current?.colors || []}
              onSave={onSave}
              setColors={setColors}
            />
          </AccordionItem>
          <AccordionItem
            key="fonts"
            aria-label="Fonts"
            title={<p className="font-black">Fonts</p>}
            className="brand_detail_accordion_item"
            keepContentMounted={true}
          >
            <Fonts
              brandId={brandId}
              updateStaticObject={updateStaticObject}
              data={brandRef.current?.font || []}
              fontFiles={fontFiles}
              setFontFiles={setFontFiles}
            />
          </AccordionItem>
          <AccordionItem
            key="photos"
            aria-label="Photos"
            title={<p className="font-black">{`Photos (${photos.length})`}</p>}
            className="brand_detail_accordion_item"
            keepContentMounted={true}
          >
            <Photos
              brandId={brandId}
              updateStaticObject={updateStaticObject}
              data={brandRef.current?.images || []}
              onSave={onSave}
              photos={photos}
              setPhotos={setPhotos}
            />
          </AccordionItem>
          <AccordionItem
            key="socialMedia"
            aria-label="SocialMedia"
            title={<p className="font-black">{`Social Media (${socialMediaList.length})`}</p>}
            className="brand_detail_accordion_item"
            keepContentMounted={true}
          >
            <SocialMedia
              updateStaticObject={updateStaticObject}
              data={brandRef.current?.socal_media || []}
              socialMediaList={socialMediaList}
              setSocialMediaList={setSocialMediaList}
            />
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};
