import { extendVariants, Link as NextLink } from '@nextui-org/react';

export const Link = extendVariants(NextLink, {
  variants: {
    color: {
      black: 'text-[#232323]',
    },
    size: {
      md: 'font-semibold',
    },
  },
  defaultVariants: {
    color: 'black',
    size: 'md',
  },
});
