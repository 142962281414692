import { Modal, ModalBody, ModalContent, useDisclosure } from '@nextui-org/react';

import './style.css';
import { Wizard } from './Wizard';
import { Project } from 'domain/modules';
import { useAppDispatch } from 'application/shared';
import { getAllProjects } from 'application/modules/projects';
import { useState } from 'react';
import { ProjectCreatedWizard } from './components/ProjectCreatedWizard';
import { Button } from 'presentation/components';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  onComplete?: () => void;
}

export const CreateProject = ({ Trigger, onComplete }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [projectCredits, setProjectCredits] = useState(0);

  const onCloseModal = () => {
    dispatch(getAllProjects());
    onClose();
    setIsProjectCreated(false);
    onComplete && onComplete();
  };

  const onCompleteCreation = (credits: number) => {
    setProjectCredits(credits);
    setIsProjectCreated(true);
  };

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        size={isProjectCreated ? 'lg' : '2xl'}
        isOpen={isOpen}
        onClose={onCloseModal}
        isDismissable={false}
        closeButton={
          <Button isIconOnly variant="light">
            <i className="ri-close-fill ri-2x" />
          </Button>
        }
        classNames={{
          body: !isProjectCreated ? 'min-h-[80vh] flex flex-row p-0 gap-0' : '',
          closeButton: 'p-0 mr-8 mt-6 data-[hover]:bg-transparent text-black',
        }}
        hideCloseButton={isProjectCreated}
        className={isProjectCreated ? 'py-6' : ''}
      >
        <ModalContent>
          {() => (
            <ModalBody>
              {!isOpen ? null : isProjectCreated ? (
                <ProjectCreatedWizard onCloseModal={onCloseModal} projectCredits={projectCredits} />
              ) : (
                <Wizard onClose={onCloseModal} onCompleteCreation={onCompleteCreation} />
              )}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

interface IPropsControlled {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  project: Project;
}

export const CreateProjectCustomTrigger = ({ isOpen, onClose, project }: IPropsControlled) => {
  const dispatch = useAppDispatch();
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [projectCredits, setProjectCredits] = useState(0);

  const onCloseModal = () => {
    dispatch(getAllProjects());
    onClose();
  };

  const onCompleteCreation = (credits: number) => {
    setProjectCredits(credits);
    setIsProjectCreated(true);
  };

  return (
    <Modal
      size={isProjectCreated ? 'lg' : '2xl'}
      isOpen={isOpen}
      onClose={onClose}
      isDismissable={false}
      classNames={
        !isProjectCreated
          ? {
              body: 'min-h-[80vh] max-h-[85vh] flex flex-row p-0 gap-0',
            }
          : {
              body: 'min-h-[50vh] p-0 py-2 ',
            }
      }
    >
      <ModalContent>
        {() => (
          <ModalBody>
            {!isOpen ? null : isProjectCreated ? (
              <ProjectCreatedWizard onCloseModal={onCloseModal} projectCredits={projectCredits} />
            ) : (
              <Wizard
                onClose={onCloseModal}
                onCompleteCreation={onCompleteCreation}
                project={project}
              />
            )}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};
