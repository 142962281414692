// Start
import { createRoot } from 'react-dom/client';

import './index.css';
import 'tailwindcss/tailwind.css';

import App from './App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
