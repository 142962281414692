import { useCallback, useEffect } from 'react';
import { useFaq } from './hooks/useFaq';
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  Tooltip,
} from '@nextui-org/react';
import { CreateFaqDto } from 'adminApp/models/create-faq';
import { Loading } from 'adminApp/SharedComponents';
import { columns } from './columns';
import { FaqCreate } from './FaqCreate';

export const FaqTable = () => {
  const { faq, isFetchingFaq, getAllFaqs, deleteFaq } = useFaq();

  useEffect(() => {
    getAllFaqs();
  }, []);

  const renderCell = useCallback((listItem: CreateFaqDto, columnKey: React.Key) => {
    const cellValue = listItem[columnKey as keyof CreateFaqDto];

    switch (columnKey) {
      case 'actions':
        return (
          <div className="relative flex items-center gap-4">
            <FaqCreate
              isUpdate
              data={listItem}
              Trigger={({ open }) => {
                return (
                  <Tooltip content="Edit category">
                    <span
                      className="text-lg text-default-400 cursor-pointer active:opacity-50"
                      onClick={open}
                    >
                      <i className="ri-edit-box-line" />
                    </span>
                  </Tooltip>
                );
              }}
              refreshList={() => {
                getAllFaqs();
              }}
            />

            <Tooltip color="danger" content="Delete Faq">
              <span
                className="text-lg text-danger cursor-pointer active:opacity-50"
                onClick={() => {
                  if (listItem.id) {
                    deleteFaq(listItem.id);
                  }
                }}
              >
                <i className="ri-delete-bin-5-line" />
              </span>
            </Tooltip>
          </div>
        );
      default:
        return cellValue;
    }
  }, []);

  if (isFetchingFaq) {
    return <Loading />;
  }

  return (
    <Table aria-label="Example table with dynamic content" isHeaderSticky isStriped>
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={faq}>
        {(item: CreateFaqDto) => (
          <TableRow key={item.id}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
