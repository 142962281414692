import { adminApi } from 'adminApp/api';
import { CreateTypeDto } from 'adminApp/models/create-type';
import { useState } from 'react';

export const useCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CreateTypeDto | null>(null);

  const create = async (subCategoryId: string, data: CreateTypeDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.createType(subCategoryId, data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to create category');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const update = async (typeId: string, data: CreateTypeDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.updateType(typeId, data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to create category');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const reset = () => {
    setIsLoading(false);
    setData(null);
  };

  return {
    isLoading,
    data,
    create,
    update,
    reset,
  };
};
