import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
} from '@nextui-org/react';
import { useCallback, useEffect } from 'react';
import { columns } from './columns';
import { Loading } from 'adminApp/SharedComponents';
import { useLovsList } from './hooks/useLovList';
import { Lov } from 'adminApp/models/create-lov';
import { CreateLov } from './Create';

export const LovTable = ({ fieldName, groupName }: { fieldName: string; groupName: string }) => {
  const { lovs, getAllLovsByFieldName, getAllLovsByGroupName, deleteLov, isFetchingLovs } =
    useLovsList();

  useEffect(() => {
    getAll();
  }, [fieldName, groupName]);

  const getAll = () => {
    if (fieldName) {
      getAllLovsByFieldName(fieldName);
    } else if (groupName) {
      getAllLovsByGroupName(groupName);
    }
  };

  const renderCell = useCallback(
    (listItem: Lov, columnKey: React.Key) => {
      const cellValue = listItem[columnKey as keyof Lov];

      switch (columnKey) {
        case 'actions':
          return (
            <div className="relative flex items-center gap-4">
              <CreateLov
                isUpdate
                data={listItem}
                Trigger={({ open }) => {
                  return (
                    <Tooltip content="Edit LOV">
                      <span
                        className="text-lg text-default-400 cursor-pointer active:opacity-50"
                        onClick={open}
                      >
                        <i className="ri-edit-box-line" />
                      </span>
                    </Tooltip>
                  );
                }}
                refreshList={getAll}
              />

              <Tooltip color="danger" content="Delete Type">
                <span
                  className="text-lg text-danger cursor-pointer active:opacity-50"
                  onClick={() => {
                    if (listItem.id) {
                      deleteLov(listItem.id, listItem.fieldName, listItem.groupName);
                    }
                  }}
                >
                  <i className="ri-delete-bin-5-line" />
                </span>
              </Tooltip>
            </div>
          );
        default:
          return cellValue;
      }
    },
    [fieldName]
  );

  if (isFetchingLovs) {
    return <Loading />;
  }

  return (
    <Table aria-label="LOV items" isHeaderSticky isStriped>
      <TableHeader columns={columns}>
        {(column) => <TableColumn key={column.key}>{column.label}</TableColumn>}
      </TableHeader>
      <TableBody items={lovs}>
        {(item: Lov) => (
          <TableRow key={item.id}>
            {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
