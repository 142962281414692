import { useState } from 'react';

import './style.css';
import { Button } from '../Button';

type OnClickCallback = () => void;

interface IProps {
  renderTrigger: (arg0: OnClickCallback) => JSX.Element;
  renderContent: (arg0: OnClickCallback) => JSX.Element;
  containerClass: string;
  contentTitle?: string;
}

export const Drawer = ({
  renderTrigger,
  renderContent,
  containerClass,
  contentTitle = '',
}: IProps) => {
  const [open, setOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const closeDrawer = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setOpen(false);
    }, 310);
  };

  return (
    <>
      {renderTrigger && renderTrigger(() => setOpen(!open))}
      {!open && !isClosing ? null : (
        <DrawerContent
          renderContent={renderContent}
          containerClass={containerClass}
          onClose={closeDrawer}
          isClosing={isClosing}
          contentTitle={contentTitle}
        />
      )}
    </>
  );
};

interface IDrawerContentProps {
  renderContent: (arg0: OnClickCallback) => JSX.Element;
  onClose: () => void;
  containerClass: string;
  isClosing: boolean;
  contentTitle?: string;
}

const DrawerContent = ({
  renderContent,
  onClose,
  containerClass,
  isClosing,
  contentTitle,
}: IDrawerContentProps) => {
  const animationName = isClosing ? 'animate-drawer-slide-out' : 'animate-drawer-slide-in';
  return (
    <div
      className={[
        'drawer_container sm:w-72 lg:w-[388px] 2xl:w-[400px] fixed top-0 right-0 h-screen overflow-y-auto z-10 flex flex-col p-[32px]',
        animationName,
        containerClass,
      ].join(' ')}
    >
      <div className="flex columns-2 justify-center mb-4">
        <h3 className="w-full leading-10 font-bold text-3xl">{contentTitle}</h3>
        <Button isIconOnly variant="light" onClick={() => onClose()}>
          <i className="ri-close-fill ri-2x font-bold" />
        </Button>
      </div>
      {renderContent && renderContent(onClose)}
    </div>
  );
};
