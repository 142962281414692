import { adminApi } from 'adminApp/api';
import { SubCategory } from 'adminApp/models/create-sub-category';
import { useState } from 'react';

export const useSubCategories = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [subcategoryList, setSubCategoryList] = useState<SubCategory[]>([]);

  const getAllSubCategories = async (categoryId: string) => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllSubCategory(categoryId);
      setSubCategoryList(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    subcategoryList,
    getAllSubCategories,
  };
};
