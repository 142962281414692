export const columns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'question',
    label: 'Question',
  },
  {
    key: 'answer',
    label: 'Answer',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
