import { Popover, PopoverContent, PopoverTrigger, cn } from '@nextui-org/react';
import { Brand } from 'domain/modules/brand';
import { Button } from 'presentation/components';
import { useEffect, useState } from 'react';
import { SketchPicker, ColorResult } from 'react-color';

const ColorPicker = ({
  id,
  color,
  handleChange,
  handleDelete,
}: {
  id: string;
  color: string;
  handleChange: (id: string, color: string) => void;
  handleDelete: (id: string) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(color);

  const handleChangeComplete = () => {
    handleChange(id, selectedColor);
    setIsOpen(false);
  };

  const onChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      placement="bottom"
      classNames={{
        base: ['before:bg-default-200'],
        content: [
          'py-0 px-0',
          'bg-gradient-to-br from-white to-default-300',
          'dark:from-default-100 dark:to-default-50',
        ],
      }}
    >
      <PopoverTrigger>
        <div className="flex flex-col items-center gap-1">
          <div className="w-16 h-16 rounded border relative" style={{ backgroundColor: color }}>
            <div
              className={cn(
                'absolute top-[-5px] right-[-5px]',
                'w-5 h-5 rounded-full',
                'flex items-center justify-center',
                'bg-black text-white'
              )}
              onClick={() => handleDelete(id)}
            >
              <i className="ri-close-fill ri-sm" />
            </div>
          </div>
          <p className="text-[#0d1216b3] text-xs uppercase">{color}</p>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <div className="bg-white">
          <SketchPicker color={selectedColor} onChange={onChange} />
          <Button
            size="sm"
            color="primary"
            className="rounded-none"
            fullWidth
            onClick={handleChangeComplete}
          >
            Save
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

interface IProps {
  updateStaticObject: (key: keyof Brand, colors: Array<string>) => void;
  data: Array<string> | undefined;
  onSave: (shouldNavigate: boolean) => void;
  setColors: (val: Array<string>) => void;
}

export const Colors = ({ updateStaticObject, data, onSave, setColors: setBrandColors }: IProps) => {
  const [colors, setColors] = useState<{ [k: string]: string }>({});

  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState('FFFFFF');

  useEffect(() => {
    if (Array.isArray(data)) {
      const obj: { [k: string]: string } = {};
      data.forEach((d, id) => {
        obj[`${id}`] = d;
      });

      setColors(obj);
    }
  }, [data]);

  const handleChangeComplete = () => {
    handleChange(new Date().getTime().toString(), selectedColor);
    setIsOpen(false);
  };

  const onChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
  };

  const handleChange = (id: string, color: string) => {
    const newValues = {
      ...colors,
      [id]: color,
    };
    setColors(newValues);
    setBrandColors(Object.keys(newValues).map((i) => newValues[i]));
    updateStaticObject(
      'colors',
      Object.keys(newValues).map((i) => newValues[i])
    );
    onSave(false);
  };

  const handleDelete = (id: string) => {
    const tempColors = { ...colors };
    delete tempColors[id];

    setColors(tempColors);
    setBrandColors(Object.keys(tempColors).map((i) => tempColors[i]));
    updateStaticObject(
      'colors',
      Object.keys(tempColors).map((i) => tempColors[i])
    );
  };

  return (
    <div className="field_container colors_container flex items-center gap-4">
      {Object.keys(colors).map((key: string) => {
        const color = colors[key];
        return (
          <ColorPicker
            key={key}
            id={key}
            color={color}
            handleChange={handleChange}
            handleDelete={handleDelete}
          />
        );
      })}
      {Object.keys(colors).length >= 6 ? null : (
        <Popover
          isOpen={isOpen}
          onOpenChange={(open) => setIsOpen(open)}
          placement="bottom"
          classNames={{
            base: ['before:bg-default-200'],
            content: [
              'py-0 px-0',
              'bg-gradient-to-br from-white to-default-300',
              'dark:from-default-100 dark:to-default-50',
            ],
          }}
        >
          <PopoverTrigger>
            <div className="flex flex-col items-center gap-1">
              <div
                className={cn(
                  'w-16 h-16 rounded',
                  'bg-white border',
                  'flex justify-center items-center',
                  'hover:bg-gray-100 cursor-pointer'
                )}
              >
                <i className="ri-add-fill ri-xl" />
              </div>
              <p className="text-[#0d1216b3] text-xs">Add new</p>
            </div>
          </PopoverTrigger>
          <PopoverContent>
            <div className="bg-white">
              <SketchPicker color={selectedColor} onChange={onChange} />
              <Button
                size="sm"
                color="primary"
                className="rounded-none"
                fullWidth
                onClick={handleChangeComplete}
              >
                Save
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      )}
    </div>
  );
};
