/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tabs, Tab } from '@nextui-org/tabs';
import { CommentsContainer } from './comments';
import { IterationsContainer } from './iterations';
import { Chip } from '@nextui-org/react';
import { useProjectDetailContext } from '../../../context';

interface IProps {
  items: Array<any>;
}

const ActivityContainer = ({ items }: IProps) => {
  const { project, commentsCount } = useProjectDetailContext();

  const getCount = (itemId: string) => {
    if (itemId === 'iterations') {
      const actualLength = project.iteration.length === 1 ? 0 : project.iteration.length;
      return actualLength > 0 ? actualLength : 0;
    } else if (itemId === 'comments') {
      return commentsCount;
    }
    return 0;
  };

  return (
    <div className="w-[80%]">
      <Tabs aria-label="Activities" variant="underlined" items={items}>
        {items.map((item) => (
          <Tab
            key={item.id}
            title={
              <div className="flex items-center gap-2 px-4">
                <Chip
                  size="sm"
                  variant="faded"
                  className="bg-[#99CF63] border-[#99CF63] text-white"
                >
                  {getCount(item.id)}
                </Chip>
                <span>{item.label}</span>
              </div>
            }
          >
            <item.content />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export const Activities = {
  Root: ActivityContainer,
  Comments: CommentsContainer,
  Iterations: IterationsContainer,
};
