import axios from 'axios';
import has from 'lodash/has';

export interface AssetUploadResponse {
  id: string;
  key: string;
  url: string;
  userId: string;
}

class AssetsService {
  upload(file: File, path: string): Promise<AssetUploadResponse> {
    return new Promise((resolve, reject) => {
      const form = new FormData();
      form.append('file', file);
      form.append('folderPath', path);

      axios
        .post<AssetUploadResponse>('/api/storage/upload', form)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  deleteAsset(assetId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/storage/${assetId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
}

export const assetsService = new AssetsService();
