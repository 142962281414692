import { Select, SelectItem, cn } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { WizardFooter } from '../components/WizardFooter';
import { LeftPanel } from '../components/LeftPanel';
import { Project } from 'domain/modules';
import { useBrand } from 'application/modules/brand';
import { CreateProjectSteps } from '../Wizard';
import { useProjectDetail } from 'application/modules/projects';
import { CreateProjectHeader } from '../components/Header';

interface IProps {
  project?: Project;
  isFetching: boolean;
  onChangeStep: (stepName: CreateProjectSteps, projectId?: string) => void;
}

export const BrandSelection = (props: IProps) => {
  const { getAll, brandList, isFetchingList } = useBrand();
  const { updateProject } = useProjectDetail();
  const [selectedBrandId, setSelectedBrandId] = useState(
    props.project && props.project.brand_id ? String(props.project.brand_id) : ''
  );

  useEffect(() => {
    getAll();
  }, []);

  const onNext = async () => {
    if (props.project) {
      const tempProject = { ...props.project };
      if (selectedBrandId) {
        tempProject.brand_id = selectedBrandId;
      } else {
        tempProject.brand_id = null;
      }
      const data = await updateProject(tempProject);
      if (data) {
        props.onChangeStep('descriptionAndNarration', data.id);
      }
    }
  };

  const onChangeBrand = (value: string) => {
    if (value.includes('create_new')) {
      window.open('/brand');
    } else {
      setSelectedBrandId(value);
    }
  };

  const onPrev = () => {
    props.onChangeStep('customQuestions');
  };

  const onSkip = () => {
    props.onChangeStep('descriptionAndNarration');
  };

  return (
    <LeftPanel>
      <>
        <CreateProjectHeader title="Create Design Task" totalSteps={6} currentStep={4} />
        <div className="grow flex flex-col gap-6">
          <Select
            variant="bordered"
            isLoading={isFetchingList}
            items={[
              ...new Set([
                ...brandList,
                ...[
                  {
                    id: 'create_new',
                    name: '+ Setup New Brand',
                  },
                ],
              ]),
            ]}
            label="Brand Preset"
            placeholder="Choose a Brand Preset"
            labelPlacement="outside"
            onChange={(ev) => onChangeBrand(ev.target.value)}
            defaultSelectedKeys={selectedBrandId ? [selectedBrandId] : ''}
            endContent={
              <i
                className="ri-refresh-line"
                onClick={() => {
                  getAll();
                }}
              ></i>
            }
            classNames={{
              label: 'text-[#CA0B4A] leading-[22px] font-medium 2xl:text-xl',
              value: !selectedBrandId ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
              innerWrapper: 'pl-3',
              trigger: cn(
                'rounded-[16px] min-h-[48px] shadow-none data-[focus=true]:border-default'
              ),
            }}
          >
            {(brand) => <SelectItem key={brand.id}>{brand.name}</SelectItem>}
          </Select>
        </div>
        <WizardFooter
          onNext={onNext}
          onPrev={onPrev}
          onSkip={onSkip}
          nextTitle="Enter the Design Content"
        />
      </>
    </LeftPanel>
  );
};
