import { Dropdown, DropdownTrigger, cn } from '@nextui-org/react';
import { Button } from '../Button';
import { Project } from 'domain/modules';
import { DropdownItemCustom } from './DropdownItemCustom';

interface IProps {
  task: Project;
  updateStatus: () => void;
  openProject: (val: boolean) => void;
  confirmationModalOnOpen: () => void;
  setIsCopy: (val: boolean) => void;
  projectsInLine: () => Project[];
}

export const DynamicDropdown = ({
  task,
  updateStatus,
  openProject,
  confirmationModalOnOpen,
  setIsCopy,
  projectsInLine,
}: IProps) => {
  if (task.status === 'IN_PROGRESS' || task.status === 'COMPLETED') {
    return null;
  }

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button isIconOnly size="sm" variant="light">
          <i className={cn('ri-more-2-fill ri-xl')} />
        </Button>
      </DropdownTrigger>

      <DropdownItemCustom
        status={task.status}
        openProject={openProject}
        updateStatus={updateStatus}
        confirmationModalOnOpen={confirmationModalOnOpen}
        setIsCopy={setIsCopy}
        projectsInLine={projectsInLine}
      />
    </Dropdown>
  );
};
