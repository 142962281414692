import { cn, Input, InputProps } from '@nextui-org/react';
import './styles.css';

export const CustomInputLayout = ({ label, ...props }: InputProps) => (
  <div className="custom-input-wrapper">
    <span
      className={cn(
        'custom-input-label pointer-events-none font-semibold text-base text-wrap',
        props.isRequired ? `after:content-['*'] after:text-danger after:ml-0.5` : '',
        `subpixel-antialiased block after:ml-0.5 will-change-auto !duration-200 !ease-out motion-reduce:transition-none transition-[transform,color,left,opacity] group-data-[filled-within=true]:text-foreground group-data-[filled-within=true]:pointer-events-auto pb-0 z-20 top-1/2 group-data-[filled-within=true]:left-0 left-3 group-data-[filled-within=true]:-translate-y-[calc(100%_+_theme(fontSize.small)/2_+_20px)] pe-2 max-w-full text-ellipsis overflow-hidden`
      )}
    >
      {label}
    </span>
    <Input {...props} />
  </div>
);
