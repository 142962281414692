import { Button } from 'presentation/components';
import { cn } from '@nextui-org/system';

interface IProps {
  onNext?: () => void;
  onPrev?: () => void;
  onSkip?: () => void;
  nextTitle?: string;
  disableNext?: boolean;
  disclaimerText?: string;
  isLastStep?: boolean;
}

export const WizardFooter = ({
  onNext,
  onPrev,
  onSkip,
  nextTitle,
  disableNext,
  disclaimerText,
  isLastStep,
}: IProps) => {
  return (
    <>
      {disclaimerText ? (
        <div className="flex justify-start mb-4">
          <p
            className={cn(
              'w-3/5',
              'text-[#8F8F8F] text-[13px] italic font-medium leading-[18.5px]'
            )}
          >
            {disclaimerText}
          </p>
        </div>
      ) : null}

      {onNext && nextTitle ? (
        <div className="flex justify-end mb-2">
          <p className={cn('text-[#8F8F8F] text-xs leading-[18.5px] tracking-[0.16px]')}>
            {nextTitle}
          </p>
        </div>
      ) : null}

      <div className="flex items-center justify-between pb-4">
        <div>
          {onPrev ? (
            <Button
              variant="bordered"
              onPress={onPrev}
              className={cn(
                'border border-solid border-black',
                'py-3 px-6',
                'flex items-center justify-center',
                'text-black text-[16px] font-medium'
              )}
              startContent={<i className="ri-arrow-left-line" />}
            >
              Back
            </Button>
          ) : null}
        </div>

        <div className="flex items-center gap-2">
          {onSkip ? (
            <Button
              onPress={onSkip}
              variant="light"
              className={cn(
                'text-[#4E4E4E] font-medium p-0',
                'text-sm',
                // '2xl:text-xl',
                'data-[hover]:bg-transparent'
              )}
            >
              Skip for now
            </Button>
          ) : null}
          {onNext ? (
            <Button
              variant="bordered"
              radius="lg"
              onPress={onNext}
              className={cn(
                isLastStep ? 'bg-[#99CF63]' : '',
                isLastStep ? 'border-none' : 'border border-solid border-black',
                'py-3 px-8',
                'flex items-center justify-center',
                'text-black text-[16px] font-medium'
              )}
              endContent={isLastStep ? null : <i className="ri-arrow-right-line" />}
              isDisabled={disableNext}
            >
              {isLastStep ? 'Done' : 'Next'}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );
};
