import {
  Auth,
  OAuthProvider,
  GoogleAuthProvider,
  User,
  UserCredential,
  signInWithPopup,
  getAuth,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  onAuthStateChanged,
} from 'firebase/auth';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { app } from './setup';
import { MAGIC_LINK_EMAIL } from './constants';

const auth: Auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider('microsoft.com');
const analytics = getAnalytics(app);

microsoftProvider.setCustomParameters({
  tenant: import.meta.env.VITE_MICROSOFT_TENANT_ID,
});

const actionCodeSettings = {
  url: `${window.location.origin}/signin/complete`,
  handleCodeInApp: true,
};

export const log = (eventName: string) => {
  logEvent(analytics, eventName);
};

// eslint-disable-next-line import/no-unused-modules
export const customLogWithType = (eventName: string, type: object) => {
  logEvent(analytics, eventName, type);
};

export const signInWithGoogle = () => {
  return new Promise((resolve, reject) => {
    googleProvider.setCustomParameters({
      prompt: 'select_account',
    });
    signInWithPopup(auth, googleProvider)
      .then((res: UserCredential) => {
        resolve(res.user as User);
        window.sessionStorage.setItem('showBrandPopup', 'true');
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

export const signInWithMicrosoft = () => {
  return new Promise((resolve, reject) => {
    microsoftProvider.setCustomParameters({
      prompt: 'select_account',
    });
    signInWithPopup(auth, microsoftProvider)
      .then((res: UserCredential) => {
        resolve(res.user as User);
        window.sessionStorage.setItem('showBrandPopup', 'true');
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

export const signInWithMagicLink = (email: string) => {
  sendSignInLinkToEmail(auth, email, {
    ...actionCodeSettings,
    url: actionCodeSettings.url + `?email=${email}`,
  })
    .then(() => {
      alert(`Magic link sent to ${email}`);
      window.localStorage.setItem(MAGIC_LINK_EMAIL, email);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const confirmSignInWithMagicLink = (email: string) => {
  return new Promise((resolve, reject) => {
    signInWithEmailLink(auth, email, window.location.href)
      .then((res) => {
        console.log(res.user as User);
        resolve(res.user as User);
        window.sessionStorage.setItem('showBrandPopup', 'true');
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logOut = () => {
  auth
    .signOut()
    .then(() => {
      console.log('logged out');
      sessionStorage.removeItem('showBrandPopup');
    })
    .catch((error) => {
      console.log(error.message);
    });
};

export const listenToAuthChanges = (onLoggedIn: (user: User) => void, onLoggedOut: () => void) => {
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      onLoggedIn(user);
    } else {
      onLoggedOut();
    }
  });
};
