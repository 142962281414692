import { adminApi } from 'adminApp/api';
import {
  CreateAttachmentDto,
  CreateProjectAttachmentDto,
  ProjectAttachment,
} from 'adminApp/models/create-attachment';
import { useState } from 'react';

export const useCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ProjectAttachment | null>(null);

  const create = async (data: CreateAttachmentDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.createAttachment(data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to create attachment');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const update = async (inspirationId: string, data: CreateProjectAttachmentDto) => {
    console.log('[Not implemented]', inspirationId, data);
  };

  const reset = () => {
    setIsLoading(false);
    setData(null);
  };

  return {
    isLoading,
    data,
    create,
    update,
    reset,
  };
};
