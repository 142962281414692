import './style.css';

interface IProps {
  type?: keyof typeof helperTexts;
}

export const NoSubscriptionAvailable = (props: IProps) => {
  const helperText = props.type ? helperTexts[props.type] : helperTexts.default;
  return (
    <div className="no_project_available_wrapper text-black mx-6 rounded-xl">
      <p className="text-7xl font-normal">{helperText.title}</p>
      <p className="mt-4 max-w-unit-8xl text-center">{helperText.description}</p>
    </div>
  );
};

const helperTexts = {
  default: {
    title: 'Your blank canvas awaits!',
    description: `Turn your ideas into stunning visuals. Click on "Create Project" to start your design journey.`,
  },
  noSearchResults: {
    title: 'No search results!',
    description:
      'Please refine your search with different keywords or more specific details for better results.',
  },
  noResultsYet: {
    title: 'No results yet!',
    description: 'Files will be available only when the project is in the “In Review” state.',
  },
};
