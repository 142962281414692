import { adminApi } from 'adminApp/api';
import { CreateLovDto } from 'adminApp/models/create-lov';
import { useState } from 'react';

export const useCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CreateLovDto | null>(null);

  const create = async (data: CreateLovDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.createLov(data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to create LOV');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const update = async (lovId: string, data: CreateLovDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.updateLov(lovId, data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to update LOV');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const reset = () => {
    setIsLoading(false);
    setData(null);
  };

  return {
    isLoading,
    data,
    create,
    update,
    reset,
  };
};
