import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  getUser,
  loginWithGoogle,
  loginWithMagiclink,
  loginWithMicrosoft,
  logout,
  updateUser,
} from './auth.thunks';
import { User as FirebaseUser } from 'firebase/auth';
import { User } from 'domain/modules';

interface AuthState {
  user: FirebaseUser | null;
  beUser: User | null;
  isLoading: boolean;
  fetchingBeUser: boolean;
}

const initialState: AuthState = {
  user: null,
  beUser: null,
  isLoading: false,
  fetchingBeUser: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setFirebaseUser: (state, action: PayloadAction<FirebaseUser | null>) => {
      state.user = action.payload;
    },
    authReset: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginWithGoogle.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload as FirebaseUser;
    });

    builder.addCase(loginWithGoogle.rejected, (state) => {
      state.isLoading = false;
      state.user = null;
    });

    builder.addCase(loginWithMicrosoft.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(loginWithMicrosoft.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload as FirebaseUser;
    });

    builder.addCase(loginWithMicrosoft.rejected, (state) => {
      state.isLoading = false;
      state.user = null;
    });

    builder.addCase(loginWithMagiclink.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(loginWithMagiclink.fulfilled, (state, action) => {
      state.isLoading = false;
      state.user = action.payload as FirebaseUser;
    });

    builder.addCase(getUser.rejected, (state) => {
      state.fetchingBeUser = false;
      state.user = null;
      state.beUser = null;
    });

    builder.addCase(getUser.pending, (state) => {
      state.fetchingBeUser = true;
    });

    builder.addCase(getUser.fulfilled, (state, action) => {
      state.fetchingBeUser = false;
      state.beUser = action.payload as User;
    });
    builder.addCase(updateUser.rejected, (state) => {
      state.fetchingBeUser = false;
      state.user = null;
      state.beUser = null;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.fetchingBeUser = true;
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.fetchingBeUser = false;
      state.beUser = action.payload as User;
    });

    builder.addCase(loginWithMagiclink.rejected, (state) => {
      state.isLoading = false;
      state.user = null;
    });

    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false;
      state.user = null;
      state.beUser = null;
    });
  },
});

export const { setFirebaseUser, authReset } = authSlice.actions;

export default authSlice.reducer;
