import { BoardColumnsType } from 'application/modules/projects/types';
import { canTransition } from 'application/modules/projects/utils';
import { TaskStatusType } from 'domain/modules';
import { DropResult } from 'react-beautiful-dnd';

export const onDragEnd = (
  result: DropResult,
  columns: BoardColumnsType,
  setColumns: (arg: BoardColumnsType) => void
) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (
    !canTransition(source.droppableId as TaskStatusType, destination.droppableId as TaskStatusType)
  ) {
    return;
  }

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId as TaskStatusType];
    const destColumn = columns[destination.droppableId as TaskStatusType];
    if (sourceColumn && destColumn) {
      const sourceItems = [...sourceColumn.tasks];
      const destItems = [...destColumn.tasks];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          tasks: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          tasks: destItems,
        },
      });
    }
  } else {
    const column = columns[source.droppableId as TaskStatusType];
    if (column) {
      const copiedItems = [...column.tasks];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          tasks: copiedItems,
        },
      });
    }
  }
};
