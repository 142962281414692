import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Autocomplete,
  AutocompleteItem,
} from '@nextui-org/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CreateLovDto } from 'adminApp/models/create-lov';
import { useCreate } from './hooks/useCreate';
import { useLovFieldNames, useLovGroupNames } from './hooks/useLovList';
import { useEffect } from 'react';

interface IProps {
  Trigger: React.ComponentType<{
    open: () => void;
  }>;
  refreshList: () => void;
  isUpdate?: boolean;
  data?: CreateLovDto;
}

export const CreateLov = ({ refreshList, Trigger, isUpdate, data }: IProps) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { isLoading, create, update, reset } = useCreate();
  const { groupNames, getAllGroupNames, isFetchingGroupNames } = useLovGroupNames();
  const { fieldNames, getAllFieldNames, isFetchingFieldNames } = useLovFieldNames();

  const formik = useFormik({
    initialValues: {
      fieldName: data?.fieldName || '',
      groupName: data?.groupName || '',
      value: data?.value || '',
      label: data?.label || '',
      order: String(data?.order) || '',
      iconName: data?.iconName || '',
      dependentFieldName: data?.dependentFieldName || '',
    },
    validationSchema: Yup.object({
      fieldName: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      groupName: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      value: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      label: Yup.string().max(50, 'Must be 15 characters or less').required('Required'),
      order: Yup.number().required('Required'),
    }),
    onSubmit: async (values) => {
      if (!isUpdate) {
        await create({
          fieldName: values.fieldName,
          groupName: values.groupName,
          value: values.value,
          label: values.label,
          order: parseInt(values.order),
          iconName: values.iconName,
          dependentFieldName: values.dependentFieldName,
        });
      } else {
        if (data && data.id) {
          await update(data.id, {
            fieldName: values.fieldName,
            groupName: values.groupName,
            value: values.value,
            label: values.label,
            order: parseInt(values.order),
            iconName: values.iconName,
            dependentFieldName: values.dependentFieldName,
          });
        }
      }
      reset();
      formik.resetForm();
      onClose();
      refreshList();
    },
  });

  useEffect(() => {
    getAllGroupNames();
  }, []);

  useEffect(() => {
    console.log(formik.values.groupName);
    if (formik.values.groupName) {
      getAllFieldNames(formik.values.groupName);
    }
  }, [formik.values.groupName]);

  return (
    <>
      <Trigger open={onOpen} />
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center"
        onClose={() => {
          reset();
          formik.resetForm();
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {isUpdate ? 'Update' : 'Create'} Category
              </ModalHeader>
              <ModalBody>
                <Autocomplete
                  defaultItems={groupNames}
                  allowsCustomValue
                  label="Group"
                  placeholder="Select a group"
                  className="w-96"
                  defaultSelectedKey={formik.values.groupName ? formik.values.groupName : ''}
                  onInputChange={(val) => formik.setFieldValue('groupName', val)}
                  isLoading={isFetchingGroupNames}
                  labelPlacement="outside"
                  name="groupName"
                >
                  {(item) => (
                    <AutocompleteItem key={item.label} value={item.label}>
                      {item.label}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
                <Autocomplete
                  defaultItems={fieldNames}
                  allowsCustomValue
                  label="Field"
                  placeholder="Select a field"
                  className="w-96"
                  defaultSelectedKey={formik.values.fieldName ? formik.values.fieldName : ''}
                  onInputChange={(val) => formik.setFieldValue('fieldName', val)}
                  isLoading={isFetchingFieldNames}
                  labelPlacement="outside"
                  name="fieldName"
                >
                  {(item) => (
                    <AutocompleteItem key={item.label} value={item.label}>
                      {item.label}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
                <Input
                  autoFocus
                  label="Label"
                  placeholder="Enter a label"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.label}
                  name="label"
                />
                <Input
                  label="Value"
                  placeholder="Enter a value"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.value}
                  name="value"
                />
                <Input
                  label="Order"
                  type="number"
                  placeholder="Enter a order"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={String(formik.values.order)}
                  name="order"
                />
                <Input
                  autoFocus
                  label="Icon Name"
                  placeholder="Enter a icon name"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.iconName}
                  name="iconName"
                />
                <Input
                  autoFocus
                  label="Dependent Group name"
                  placeholder="Enter a label"
                  classNames={{
                    inputWrapper: 'rounded-md',
                  }}
                  labelPlacement="outside"
                  onChange={formik.handleChange}
                  value={formik.values.dependentFieldName}
                  name="dependentFieldName"
                />
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="flat" onPress={onClose}>
                  Close
                </Button>
                <Button
                  color="primary"
                  onPress={() => formik.submitForm()}
                  className="bg-black text-white"
                  disabled={isLoading || !formik.isValid}
                  isLoading={isLoading}
                >
                  {isUpdate ? 'Update' : 'Create'}
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
