import { Button } from 'presentation/components';
import { FaqCreate } from './FaqCreate';
import { useState } from 'react';
import { FaqTable } from './FaqTable';

export const FaqManagement = () => {
  const [refetch, setRefetch] = useState(false);

  return (
    <div>
      <div className="flex flex-row-reverse mb-4">
        <FaqCreate
          Trigger={({ open }) => {
            return (
              <Button color="primary" className="bg-black text-white" onClick={open}>
                Add FAQ
              </Button>
            );
          }}
          refreshList={() => {
            setRefetch(true);

            setTimeout(() => {
              setRefetch(false);
            }, 100);
          }}
        />
      </div>

      {refetch ? <p>Is Fetching...</p> : <FaqTable />}
    </div>
  );
};
