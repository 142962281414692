import { useAppDispatch, useAppSelector } from 'application/shared';
import { createCompany, getCompany, getCompanySetupLov, updateCompany } from '../company.thunks';
import { Company } from 'domain/modules';
import { getUser } from 'application/modules/auth/auth.thunks';

export const useCompany = () => {
  const dispatch = useAppDispatch();
  const { company, isFetching, companySetupLov } = useAppSelector((state) => state.company);

  const fetchLov = () => {
    dispatch(getCompanySetupLov());
  };

  const get = () => {
    dispatch(getCompany());
  };

  const create = async () => {
    await dispatch(createCompany());
    await dispatch(getUser());
  };

  const update = async (data: Company, stepName: string) => {
    return await dispatch(updateCompany({ data, stepName }));
  };

  const getLovDataForField = (fieldName: string) => {
    return companySetupLov.filter((c) => c.fieldName === fieldName);
  };

  return {
    company,
    get,
    create,
    update,
    getLovDataForField,
    companySetupLov,
    fetchLov,
    isFetching,
  };
};
