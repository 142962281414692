import { Modal, ModalBody, ModalContent } from '@nextui-org/react';
import { Button } from 'presentation/components/Button';

interface IProps {
  title: string;
  isCopy: boolean;
  confirmationModalIsOpen: boolean;
  confirmationModalOnOpenChange: () => void;
  onDelete: () => void;
  copyProject: () => void;
}

export const ConfirmationPopup = ({
  isCopy,
  confirmationModalIsOpen,
  confirmationModalOnOpenChange,
  onDelete,
  copyProject,
}: IProps) => {
  return (
    <Modal
      isOpen={confirmationModalIsOpen}
      onOpenChange={confirmationModalOnOpenChange}
      hideCloseButton
      className="py-8"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="flex flex-col items-center justify-center gap-10">
                <h3 className="text-black text-xl text-center">
                  {isCopy
                    ? 'Are you sure you want to copy this task and edit the details later.'
                    : 'Are you sure you want to delete this task?'}
                </h3>

                <div className="flex items-center gap-3">
                  <Button radius="lg" onPress={onClose} className="bg-[#F0F0F0] text-black px-10">
                    No, cancel
                  </Button>

                  <Button
                    radius="md"
                    onClick={isCopy ? copyProject : onDelete}
                    className="bg-[#99CF63] text-black px-10"
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
