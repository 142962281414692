import { Tab, Tabs } from '@nextui-org/react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AccountSidePanel } from '../../components/AccountSidePanel';
import { Key, useEffect } from 'react';
import { useSubscription } from 'application/modules/subscription';

export const AccountScreen = () => {
  const navigate = useNavigate();
  const { get, subscription, isFetchingSubscription, getIsCreditsPurchased } = useSubscription();

  useEffect(() => {
    if (!subscription) {
      get();
    }
  }, []);

  const onSelectionChange = (tabName: 'account' | 'plans' | Key) => {
    if (tabName === 'account') {
      navigate('/account', { replace: true });
    } else {
      navigate(tabName as 'account' | 'plans', { replace: true });
    }
  };

  return (
    <div className="flex flex-col mx-6 h-full">
      <h1 className="text-2xl font-black mt-2">My Account</h1>
      <div className="py-6 flex items-start gap-2 h-full">
        <div className="w-[365px] bg-[#F9FAFE] h-full flex flex-col justify-center items-center">
          <AccountSidePanel
            getIsCreditsPurchased={getIsCreditsPurchased}
            subscription={subscription}
            isFetchingSubscription={isFetchingSubscription}
          />
        </div>
        <div className="grow flex flex-col gap-10">
          <Tabs
            aria-label="My Accounts Tab"
            onSelectionChange={onSelectionChange}
            variant="underlined"
            classNames={{
              tabList: 'gap-6 w-full relative rounded-none p-0 border-b border-divider',
              cursor: 'w-full bg-black',
              tab: 'max-w-fit px-4 h-8',
              tabContent: 'text-[#787486] group-data-[selected=true]:text-[#0D1216]',
            }}
          >
            <Tab key="account" title="Account Details" />
            <Tab key="plans" title="My Plans & Purchases" isDisabled={!subscription} />
          </Tabs>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
