/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useProjectDetail } from 'application/modules/projects';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingIndicator } from '../components/common/LoadingIndicator';
import { Project, TaskStatusType } from 'domain/modules';

interface IBaseProps {
  children?: ReactNode;
}

interface IContext {
  project: Project;
  isEditMode: boolean;
  isProjectCreated: boolean;
  commentsCount: number;
  setEditMode: (val: boolean) => void;
  updateProject: (project: Project) => Promise<Project | undefined>;
  updateStatus: (project: Project, toStatus: TaskStatusType) => Promise<Project | undefined>;
  refetchProject: () => void;
  hasARequestedIteration: () => boolean;
  setCommentsCount: (val: number) => void;
}

const ProjectDetailContext = createContext<IContext>({
  project: undefined,
  isEditMode: false,
  isProjectCreated: false,
  commentsCount: 0,
  setEditMode: () => {},
  updateProject: () => {},
  updateStatus: () => {},
  refetchProject: () => {},
  hasARequestedIteration: () => false,
  setCommentsCount: () => {},
} as IContext | any);

export const useProjectDetailContext = () => {
  const context = useContext(ProjectDetailContext);

  if (!context) {
    throw new Error('useProjectDetailContext must be used within a ProjectDetailProvider');
  }

  return context;
};

export const ProjectDetailProvider = ({ children }: IBaseProps) => {
  const { projectId } = useParams();
  const [isEditMode, setEditMode] = useState(false);
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [project, setProject] = useState<Project | undefined>(undefined); // Use local state for project
  const [commentsCount, setCommentsCount] = useState(0);
  const {
    getProjectDetail,
    updateProject,
    isFetching,
    updateProjectStatus: updateStatus,
  } = useProjectDetail();

  useEffect(() => {
    if (projectId) {
      const fetchProject = async () => {
        const fetchedProject = await getProjectDetail(projectId);
        if (fetchedProject) {
          setProject(fetchedProject);
          setIsProjectCreated(true); // Set isProjectCreated after successful fetch
        } else {
          setIsProjectCreated(false); // Ensure isProjectCreated is false if no project is fetched
        }
      };

      fetchProject();
    }
  }, [projectId]); // Only re-run effect if projectId changes

  const refetchProject = () => {
    if (projectId) {
      const fetchProject = async () => {
        const fetchedProject = await getProjectDetail(projectId);
        if (fetchedProject) {
          setProject(fetchedProject);
          setIsProjectCreated(true);
        }
      };

      fetchProject();
    }
  };

  const hasARequestedIteration = () => {
    if (project) {
      return project.iteration.filter((p) => p.isActive === false).length > 0;
    }
    return false;
  };

  if (isFetching || project === undefined) {
    return <LoadingIndicator />;
  }

  return (
    <ProjectDetailContext.Provider
      value={{
        project,
        isEditMode,
        isProjectCreated,
        commentsCount,
        setCommentsCount,
        setEditMode,
        updateStatus,
        refetchProject,
        updateProject,
        hasARequestedIteration,
      }}
    >
      {children}
    </ProjectDetailContext.Provider>
  );
};
