import { useNavigate, useSearchParams } from 'react-router-dom';
import { Header } from './header';
import { useEffect, useState } from 'react';
import { useProjectAttachmentListingByMonth } from 'application/modules/projects';
import { Card, Input } from '@nextui-org/react';
import { ProjectListingByMonthActualObj } from 'domain/modules';
import { NoSubscriptionAvailable } from './NoSubscriptionAvailable';
import search_icon from 'presentation/assets/search_icon.svg';
import my_project_thumbnail from 'presentation/assets/my_project_thumbnail.svg';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { LazyImage } from 'presentation/components/LazyImage';

export const ListingsByMonth = () => {
  const [searchParams] = useSearchParams();
  const { getAll, isFetching, projects } = useProjectAttachmentListingByMonth();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [tempProject, setTempProjects] = useState(projects);

  useEffect(() => {
    const date = searchParams.get('date');
    if (date) {
      const dateStr = decodeURIComponent(date);
      getAll(dateStr);
    }
  }, [searchParams]);

  useEffect(() => {
    if (projects) {
      setTempProjects(projects);
    }
  }, [projects]);

  useEffect(() => {
    const filteredProjects: ProjectListingByMonthActualObj[] = [];
    projects.map((project) => {
      if (project.name.toLowerCase().includes(searchText.toLowerCase())) {
        filteredProjects.push(project);
      }

      return null;
    });

    setTempProjects(filteredProjects);
  }, [searchText]);

  const onOpenItem = (item: ProjectListingByMonthActualObj) => {
    navigate(`/projects/listing/project/${item.id}?projectLabel=${item.name}`);
  };

  const pageTitle = () => {
    const label = searchParams.get('monthLabel') || 'Project';
    return decodeURIComponent(label);
  };

  if (isFetching) {
    return (
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '80vh',
        }}
      />
    );
  }

  if (!projects || !Array.isArray(projects) || projects.length === 0) {
    return <NoSubscriptionAvailable />;
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <Header title={pageTitle()} />
        <Input
          variant="bordered"
          placeholder="Search anything...."
          className="w-3/5"
          value={searchText}
          onChange={(ev) => setSearchText(ev.target.value)}
          startContent={<img src={search_icon} alt="search_icon" />}
        />
      </div>
      {!Array.isArray(tempProject) || tempProject.length === 0 ? (
        <NoSubscriptionAvailable type="noSearchResults" />
      ) : (
        <div className="flex flex-wrap gap-6 mt-4">
          {tempProject.map((project, index) => {
            return (
              <div className="cursor-pointer" key={index} onClick={() => onOpenItem(project)}>
                <Card radius="lg" className="border-none h-[250px] w-[250px]">
                  <LazyImage
                    alt={project.name}
                    className="object-cover h-[250px] w-[250px]"
                    height="100%"
                    src={project.urls.length <= 0 ? my_project_thumbnail : project.urls[0]?.url}
                    width="100%"
                  />
                </Card>
                <p className="text-sm text-black text-center mt-2 font-medium">{project.name}</p>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
