import { Faq } from 'domain/modules/faq';
import { faqService } from 'infra/api/modules/faq';
import { useState } from 'react';

export const useFaq = () => {
  const [isFetchingFaq, setIsFetchingFaq] = useState(false);
  const [faqs, setFaqs] = useState<Faq[]>([]);

  const getFaq = async () => {
    try {
      setIsFetchingFaq(true);
      const data = await faqService.getFaqQuestions();
      setFaqs(data);
      return data;
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetchingFaq(false);
    }
  };

  return {
    isFetchingFaq,
    faqs,
    getFaq,
  };
};
