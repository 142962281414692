import { createSlice } from '@reduxjs/toolkit';
import { createBrand, getAllBrands } from './brand.thunks';
import { Brand } from 'domain/modules/brand';

interface BrandState {
  brand: Brand | null;
  isCreating: boolean;
  errorCreating: null | string;

  brandList: Array<Brand>;
  isFetchingList: boolean;
}

const initialState: BrandState = {
  brand: null,
  brandList: [],
  isCreating: false,
  errorCreating: null,
  isFetchingList: false,
};

export const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.errorCreating = null;
    },
    brandReset: () => {
      return {
        ...initialState,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBrand.pending, (state) => {
      state.isCreating = true;
      state.errorCreating = null;
    });

    builder.addCase(createBrand.fulfilled, (state, action) => {
      state.isCreating = false;
      state.errorCreating = null;
      state.brand = action.payload;
    });

    builder.addCase(createBrand.rejected, (state) => {
      state.isCreating = false;
      state.brand = null;
      state.errorCreating = 'Unable to create brand at the moment';
    });

    builder.addCase(getAllBrands.pending, (state) => {
      state.isFetchingList = true;
    });

    builder.addCase(getAllBrands.fulfilled, (state, action) => {
      state.brandList = action.payload;
      state.isFetchingList = false;
    });

    builder.addCase(getAllBrands.rejected, (state) => {
      state.brandList = [];
      state.isFetchingList = false;
    });
  },
});

export const { clearErrors, brandReset } = brandSlice.actions;

export default brandSlice.reducer;
