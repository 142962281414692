import { useProjectAttachmentListing } from 'application/modules/projects';
import { Header } from './header';
import { useEffect } from 'react';
import { Card, CardBody, cn } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { ProjectsListingActualObj } from 'domain/modules';
import { useSubscription } from 'application/modules/subscription';
import { NoSubscriptionAvailable } from './NoSubscriptionAvailable';
import folder_thumbnail from 'presentation/assets/folder.png';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { LazyImage } from 'presentation/components/LazyImage';

export const Listings = () => {
  const { getAll, isFetching, projects } = useProjectAttachmentListing();
  const { get, subscription, isFetchingSubscription } = useSubscription();
  const navigate = useNavigate();

  useEffect(() => {
    getAll();
    get();
  }, []);

  const onOpenItem = (key: string, item: ProjectsListingActualObj) => {
    const searchParams = new URLSearchParams();
    searchParams.set('date', encodeURIComponent(item.date));
    searchParams.set('monthLabel', encodeURIComponent(key));

    navigate(`/projects/listing/month?${searchParams}`);
  };

  if (isFetching || isFetchingSubscription) {
    return (
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '80vh',
        }}
      />
    );
  }

  return (
    <>
      <Header title="My Projects" noBack />
      {!subscription || Object.keys(projects).length === 0 ? (
        <NoSubscriptionAvailable />
      ) : (
        <div className="flex gap-6 mt-4">
          {Object.keys(projects).map((key, index) => {
            return (
              <Card className={cn('border-none', 'py-1 px-3 w-[300px]')} radius="lg" key={index}>
                <CardBody
                  className="justify-between cursor-pointer"
                  onClick={() => onOpenItem(key, projects[key])}
                >
                  <div className="flex gap-4">
                    <LazyImage
                      alt={key}
                      className="object-cover h-[60px] w-[60px]"
                      src={folder_thumbnail}
                      width="100%"
                    />
                    <div className="flex flex-col gap-2 items-start justify-center">
                      <h4 className={cn('text-black text-xl font-semibold', 'text-center')}>
                        {key}
                      </h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
};
