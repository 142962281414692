import { Category, SubCategory, Type } from 'domain/modules';
import { projectsService } from 'infra/api';
import { useState } from 'react';

export const useProjectCategory = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [types, setTypes] = useState<Type[]>([]);

  const [isFetching, setIsFetching] = useState('');

  const getAllCategories = async () => {
    try {
      setIsFetching('categories');
      const data = await projectsService.getCategories();
      setCategories(data);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching('');
    }
  };

  const getSubCategoriesForCategory = async (categoryId: string) => {
    try {
      setIsFetching('subcategories');
      const data = await projectsService.getSubCategories(categoryId);
      setSubCategories(data);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching('');
    }
  };

  const getTypes = async (subCategoryId: string) => {
    try {
      setIsFetching('types');
      const data = await projectsService.getTypes(subCategoryId);
      setTypes(data);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching('');
    }
  };

  return {
    isFetching,
    categories,
    subCategories,
    types,
    getAllCategories,
    getSubCategoriesForCategory,
    getTypes,
  };
};
