import { TaskStatusType } from 'domain/modules';

export const displayProjectStatus = (status: TaskStatusType) => {
  switch (status) {
    case 'IN_ACTIVE':
      return 'To Do';
    case 'TODO':
      return 'To Do';
    case 'START_DESIGNING':
      return 'Start Designing';
    case 'IN_PROGRESS':
      return 'In Progress';
    case 'IN_REVIEW':
      return 'In Review';
    case 'COMPLETED':
      return 'Completed';
    default:
      return null;
  }
};
