import { extendVariants, Button as NextButton } from '@nextui-org/react';

export const Button = extendVariants(NextButton, {
  variants: {
    size: {
      sm: 'font-medium',
      lg: 'font-medium',
      md: 'font-medium',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});
