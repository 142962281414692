import { createAsyncThunk } from '@reduxjs/toolkit';
import { BRAND_ACTIONS } from './brand.actions';
import { brandService } from 'infra/api';

export const createBrand = createAsyncThunk(BRAND_ACTIONS.CREATE_BRAND, async (name: string) => {
  const company = await brandService.createBrand(name);
  return company;
});

export const getAllBrands = createAsyncThunk(BRAND_ACTIONS.GET_ALL_BRANDS, async () => {
  const companies = await brandService.getAllBrands();
  return companies;
});
