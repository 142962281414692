import { Modal, ModalBody, ModalContent } from '@nextui-org/react';
import { Button } from 'presentation/components';
import delete_modal_icon from 'presentation/assets/modal_images/delete_modal_icon.png';
import { Brand } from 'domain/modules/brand';
import { useBrand } from 'application/modules/brand';

interface IProps {
  isOpen: boolean;
  onOpenChange: () => void;
  brand: Brand;
}

export const BrandDeleteCard = ({ isOpen, onOpenChange, brand }: IProps) => {
  const { deleteBrand } = useBrand();

  const onDelete = () => {
    try {
      deleteBrand(brand.id);
    } catch (ex) {
      console.log(ex);
    }
  };

  return (
    <Modal size="md" isOpen={isOpen} onOpenChange={onOpenChange}>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="flex flex-col items-center justify-center gap-6">
                <img
                  src={delete_modal_icon}
                  alt="delete_modal_icon"
                  className="w-[450px] h-[150px] object-cover"
                />
                <h3 className="text-[#0D1216] font-bold text-xl text-center">
                  Are you sure you want to delete{' '}
                  <span className="italic">{`"${brand.name}"`}</span>
                </h3>

                <div className="flex items-center gap-2">
                  <Button
                    className="bg-[#F1F3F5] rounded-full px-6 text-[#0D1216] text-sm font-semibold"
                    onPress={onClose}
                  >
                    Cancel
                  </Button>

                  <Button
                    onPress={onDelete}
                    className="bg-[#232323] rounded-[500px] px-6 text-[#FFF]"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
