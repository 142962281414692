import { StyleType } from 'adminApp/models/create-form-with-combination';
import { Inspiration } from 'domain/modules';
import { projectsService } from 'infra/api';
import { useCallback, useState } from 'react';

export const useInspirations = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [inspirations, setInspirations] = useState<Inspiration[]>([]);

  const [selectedInspirations, setSelectedInspirations] = useState<Array<string>>([]);

  const getAllInsprations = async (
    categoryId: string,
    subCategoryId: string,
    styleName: StyleType
  ) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getInspirationsForCategory(
        categoryId,
        subCategoryId,
        styleName
      );
      setInspirations(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const getInspirationsByStyleType = useCallback(
    (styleType: string) => {
      return inspirations.filter((ins) => ins.style_name === styleType);
    },
    [inspirations]
  );

  return {
    isFetching,
    inspirations,
    selectedInspirations,
    getAllInsprations,
    getInspirationsByStyleType,
    setSelectedInspirations,
  };
};
