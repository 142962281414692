import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_ACTIONS } from './auth.actions';
import {
  confirmSignInWithMagicLink,
  logOut,
  signInWithGoogle,
  signInWithMicrosoft,
} from 'infra/providers/firebase';
import { userService } from 'infra/api/modules/user/user.service';
import { User } from 'domain/modules';

type UserWithToken = User & { accessToken: string };

export const loginWithGoogle = createAsyncThunk(AUTH_ACTIONS.LOGIN_GOOGLE_USER, async () => {
  const user = await signInWithGoogle();
  userService.setBearerToken((user as UserWithToken).accessToken);
  return user;
});

export const loginWithMicrosoft = createAsyncThunk(AUTH_ACTIONS.LOGIN_MICROSOFT_USER, async () => {
  const user = await signInWithMicrosoft();
  userService.setBearerToken((user as UserWithToken).accessToken);
  return user;
});

export const loginWithMagiclink = createAsyncThunk(
  AUTH_ACTIONS.LOGIN_MAGIC_LINK_USER,
  async (email: string) => {
    const user = await confirmSignInWithMagicLink(email);
    userService.setBearerToken((user as UserWithToken).accessToken);
    return user;
  }
);

export const logout = createAsyncThunk(AUTH_ACTIONS.LOGOUT_USER, async () => {
  await logOut();
  return null;
});

export const getUser = createAsyncThunk(AUTH_ACTIONS.GET_USER, async () => {
  const user = await userService.getUser();
  return user;
});

export const updateUser = createAsyncThunk(AUTH_ACTIONS.UPDATE_USER, async (user: User) => {
  const updatedUser = await userService.updateUser(user);
  return updatedUser;
});
