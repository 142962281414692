import { useAppDispatch, useAppSelector } from 'application/shared';
import { getUser, logout, updateUser } from '../auth.thunks';
import { User } from 'domain/modules';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const { user, beUser, fetchingBeUser } = useAppSelector((state) => state.auth);

  const _logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    dispatch(logout());
  };

  const _getUser = () => {
    dispatch(getUser());
  };

  const _updateUser = (user: User) => {
    dispatch(updateUser(user));
  };

  return {
    user,
    beUser,
    getUser: _getUser,
    updateUser: _updateUser,
    logout: _logout,
    isLoading: fetchingBeUser,
  };
};
