export const styleNameList = [
  {
    id: 'Standard',
    name: 'Standard',
  },
  {
    id: 'Intermediate',
    name: 'Intermediate',
  },
  {
    id: 'Pro',
    name: 'Pro',
  },
];

export const columns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'categoryId',
    label: 'Category ID',
  },
  {
    key: 'subcategoryId',
    label: 'Sub Category ID',
  },
  {
    key: 'typeId',
    label: 'Type ID',
  },
  {
    key: 'styleType',
    label: 'Style Type',
  },
  {
    key: 'lovId',
    label: 'LOV ID',
  },
  {
    key: 'valueCalculation',
    label: 'Value Calculation',
  },
  {
    key: 'rangeStart',
    label: 'Range Start',
  },
  {
    key: 'rangeEnd',
    label: 'Range End',
  },
  {
    key: 'credits',
    label: 'Credits',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
