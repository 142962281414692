import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './types';
import { AssetUploadResponse, assetsService } from 'infra/api';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAssetsUpload = () => {
  const upload = (path: string, file: File): Promise<AssetUploadResponse> => {
    return assetsService.upload(file, path);
  };

  const deleteAsset = (assetId: string) => {
    return assetsService.deleteAsset(assetId);
  };

  return {
    upload,
    deleteAsset,
  };
};
