/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton } from '@nextui-org/react';
import { useState } from 'react';

export const LazyImage = ({ src, alt, className, ...rest }: any) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <Skeleton isLoaded={isLoaded} className="rounded">
      <figure {...rest}>
        <img
          src={src}
          alt={alt}
          className={className}
          onLoad={() => {
            if (!isLoaded) {
              setIsLoaded(true);
            }
          }}
        />
      </figure>
    </Skeleton>
  );
};
