import { useAuth } from 'application/modules';
import { getUser } from 'application/modules/auth/auth.thunks';
import { useAppDispatch } from 'application/shared';
import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

interface IProps {
  component: JSX.Element;
}

export const AuthGuard = ({ component }: IProps) => {
  const { user, beUser, isLoading } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!beUser) {
      dispatch(getUser());
    }
  }, [isLoading, beUser, dispatch]);

  useEffect(() => {
    if (window.location.pathname === '/') {
      navigate('/workboard');
    }
  }, []);

  useEffect(() => {
    if (user && beUser && !window.location.pathname.includes('/company/onboarding')) {
      if (!beUser.company_id || beUser.company_step_number !== 'complete') {
        navigate(`/company/onboarding/step${beUser.company_step_number}`);
      }
    }
  }, [user, beUser, navigate]);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!beUser) {
    return null;
  }

  return <React.Fragment>{component}</React.Fragment>;
};
