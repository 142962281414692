import { Modal, ModalBody, ModalContent } from '@nextui-org/react';
import { Button } from '../Button';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'application/modules';

interface IProps {
  isOpen: boolean;
  onOpenChange: () => void;
}

export const LogoutModal = ({ isOpen, onOpenChange }: IProps) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const userLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <Modal size="md" isOpen={isOpen} onOpenChange={onOpenChange} className="py-8" hideCloseButton>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="flex flex-col items-center justify-between gap-10">
                <h3 className="text-black font-semibold text-2xl">Sure you want to exit?</h3>

                <div className="flex items-center gap-3">
                  <Button radius="lg" onPress={onClose} className="bg-[#F0F0F0] text-black px-10">
                    No, cancel
                  </Button>
                  <Button
                    radius="md"
                    onPress={userLogout}
                    className="bg-[#99CF63] text-black px-10"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
