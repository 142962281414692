import { useInspirations } from 'application/modules/projects';
import { useProjectDetailContext } from '../../../context';
import { useEffect } from 'react';
import { StyleType } from 'adminApp/models/create-form-with-combination';
import { transformCamelToTitle } from './utils';
import './styles.css';
import { getDynamicFieldValue } from './dyanmic-fields';
import { useDynamicForm } from 'application/modules/shared/hooks/useDynamicForm';
import { FileRenderer } from 'presentation/components/FileRenderer';

export const DetailsContainer = () => {
  const { project } = useProjectDetailContext();
  const { isFetching: isFetchingInspirations, inspirations, getAllInsprations } = useInspirations();
  const { getFieldData, flatFields, fieldData, lovData } = useDynamicForm();

  useEffect(() => {
    getAllInsprations(
      project.category_id,
      project.sub_category_id,
      project.style_name as StyleType
    );

    getFieldData(project);
  }, [project]);

  // Define categories to exclude
  const excludedCategories = ['Logos & Branding'];

  // Check if the current category is in the excluded categories
  const shouldShowContextField = !excludedCategories.includes(project.category?.name);

  return (
    <div className="flex flex-col gap-4 pb-6">
      <div className="pd_field_container">
        <span className="pd_label">Title:</span>
        <span className="pd_value">{project.name}</span>
      </div>
      <div className="pd_field_container">
        <span className="pd_label">Description:</span>
        <span className="pd_value">{project.description}</span>
      </div>
      {shouldShowContextField && (
        <div className="pd_field_container">
          <span className="pd_label">Context:</span>
          <span className="pd_value">{project.context || '-'}</span>
        </div>
      )}
      <div className="grid grid-cols-2">
        <div className="grid grid-cols-2">
          <span className="pd_label">Category</span>
          <span className="pd_value">{project.category?.name || '-'}</span>
        </div>
        <div className="grid grid-cols-2">
          <span className="pd_label">Sub - Category</span>
          <span className="pd_value">{project.sub_category?.name || '-'}</span>
        </div>
      </div>
      <div className="grid grid-cols-2">
        {/* <div className="grid grid-cols-2">
          <span className="pd_label">Style</span>
          <span className="pd_value">{project.style_name || '-'}</span>
        </div> */}
        {project.brand ? (
          <div className="grid grid-cols-2">
            <span className="pd_label">Brand</span>
            <span className="pd_value">{project?.brand?.name || '-'}</span>
          </div>
        ) : null}
      </div>
      <div className="pd_field_container">
        <span className="pd_label">Style Description:</span>
        <span className="pd_value">{project.style_description || '-'}</span>
      </div>
      {/* <div className="grid grid-cols-6">
        <span className="pd_label">Style References</span>
        <span className="flex gap-4 flex-wrap col-span-4">
          {isFetchingInspirations
            ? null
            : Array.isArray(project.project_inspiration)
            ? project.project_inspiration.map((ins) => {
                const inspiration = inspirations.find((i) => i.id === ins.inspiration_id);
                return (
                  <div key={ins.id} className="h-[70px] w-[70px] bg-gray-100 rounded-md truncate">
                    <img
                      src={inspiration?.media_url}
                      alt={inspiration?.style_name}
                      className="h-full w-full"
                    />
                  </div>
                );
              })
            : ''}
        </span>
      </div> */}
      <div className="flex flex-col gap-2">
        {!project.questions_set
          ? null
          : Object.keys(project.questions_set as any).map((questionKey) => {
              const rootObj: any = (project.questions_set as any)[questionKey];
              return Object.keys(rootObj).map((ansKey) => {
                const currentFieldObj = flatFields.find((i) => i.field_name === ansKey);
                let _label = transformCamelToTitle(ansKey);

                if (currentFieldObj && currentFieldObj.detail_view_label) {
                  _label = currentFieldObj.detail_view_label;
                }

                return (
                  <div key={ansKey} className="pd_field_container">
                    <span className="pd_label">{_label}: </span>
                    <span className="pd_value">
                      {getDynamicFieldValue(rootObj, ansKey, flatFields, fieldData, lovData)}
                    </span>
                  </div>
                );
              });
            })}
      </div>
      <div className="pd_field_container flex flex-col gap-2">
        <span className="pd_label">Media References:</span>
        {!project.project_voice_note ||
        !Array.isArray(project.project_voice_note) ||
        project.project_voice_note.length <= 0 ? (
          <span className="pd_value hint">No media references added</span>
        ) : (
          <div className="flex flex-col gap-2">
            {project.project_voice_note.map((voice) => (
              <div key={voice.assetId} className="flex flex-col">
                <audio controls preload="auto">
                  <source src={voice.media_url} type="audio/mpeg"></source>
                  <source src={voice.media_url} type="audio/ogg"></source>
                </audio>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <span className="pd_label">Attachments</span>
        <div className="flex gap-2 flex-wrap">
          {!project.project_media_reference ||
          !Array.isArray(project.project_media_reference) ||
          project.project_media_reference.length <= 0 ? (
            <span className="pd_value hint">No attachments attached</span>
          ) : (
            <FileRenderer files={project.project_media_reference} size="sm" />
          )}
        </div>
      </div>
      <div className="pd_field_container">
        <span className="pd_label">Reference Links:</span>
        {!project.reference_links ||
        !Array.isArray(project.reference_links?.links) ||
        project.reference_links.links.filter((i) => i).length <= 0 ? (
          <span className="pd_value hint">No reference links added</span>
        ) : (
          project.reference_links.links
            .filter((i) => i)
            .map((link, index) => (
              <a href={link} key={index} target="_blank" rel="noreferrer" className="pd_value">
                {link}
              </a>
            ))
        )}
      </div>
    </div>
  );
};
