import { adminApi } from 'adminApp/api';
import { Project } from 'adminApp/models/project';
import { useState } from 'react';

export const useProjects = (projectDefaultData?: Project) => {
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [project, setProject] = useState<Project | undefined>(projectDefaultData || undefined);

  const getAll = async (userId: string) => {
    try {
      setIsFetching(true);
      const res = await adminApi.getAllProjectsOfCompany(userId);
      if (Array.isArray(res)) {
        setProjects(res);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const getProjectById = async (projectId: string) => {
    try {
      setIsFetching(true);
      const res = await adminApi.getProjectById(projectId);
      if (res) {
        setProject(res);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    projects,
    project,
    isFetching,
    getAll,
    getProjectById,
  };
};
