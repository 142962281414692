interface IProps {
  children: React.ReactElement;
  leftPanelHidden?: boolean;
}

export const LeftPanel = ({ children, leftPanelHidden = false }: IProps) => {
  return (
    <>
      {leftPanelHidden ? null : (
        <div className="min-w-[30px] 2xl:w-[40px] min-h-[80vh] bg-[#99CF63] modal_left_bg" />
      )}
      <div className="grow max-h-[85vh] min-h-[80vh] overflow-auto">
        <div className="px-12 py-8 flex flex-col h-full">{children}</div>
      </div>
    </>
  );
};
