import { Notification } from 'domain/modules';
import { notificationService } from 'infra/api/modules/notification';
import { useState } from 'react';

export const useNotification = () => {
  const [notifications, setNotifications] = useState<Array<Notification>>([]);
  const [isFetching, setIsFetching] = useState(false);

  const getAll = async () => {
    try {
      setIsFetching(true);
      const res = await notificationService.getAllNotifications();
      setNotifications(res);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const markAsRead = async (notificationId: string) => {
    try {
      const res = await notificationService.markNotificationAsRead(notificationId);
      return res;
    } catch (ex) {
      console.log(ex);
    } finally {
      // Do nothing
    }
  };

  return {
    notifications,
    isFetching,
    getAll,
    markAsRead,
  };
};
