import { StyleType } from 'adminApp/models/create-form-with-combination';
import axios, { AxiosError } from 'axios';
import {
  Category,
  Comments,
  DeliveryAssetType,
  IProjectsService,
  Inspiration,
  Project,
  ProjectAttachmentResponse,
  ProjectDeliverable,
  ProjectListingByMonth,
  ProjectListingByProject,
  ProjectsListing,
  RecreateProject,
  Replies,
  SubCategory,
  TaskStatusType,
  Type,
} from 'domain/modules';
import { ProjectIteration } from 'domain/modules/projects/models/iteration.model';
import { handleApiError } from 'infra/api/utils/errorHandler';
import has from 'lodash/fp/has';
class ProjectsService implements IProjectsService {
  getAllProjects(): Promise<Array<Project>> {
    return new Promise((resolve, reject) => {
      axios
        .get<Array<Project>>('/api/project')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  updateProject(projectId: string, toStatus: TaskStatusType): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .patch<Project>(`/api/project/status/${projectId}/${toStatus}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  deleteProject(projectId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/project/${projectId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getProjectDetail(projectId: string): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .get<Project>(`/api/project/${projectId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  createProject(
    name: string,
    category_id: string,
    sub_category_id: string,
    type_id?: string
  ): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .post<Project>(`/api/project`, {
          name,
          category_id,
          sub_category_id,
          ...(type_id ? { type_id: type_id } : {}),
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  cloneProject(project_id: string): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .post<Project>(`/api/project/${project_id}/clone`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  recreateProject(projectId: string, data: RecreateProject): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .put<Project>(`/api/project/recreate/${projectId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  updateProjectData(data: Project): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .put<Project>(`/api/project/${data.id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getCategories(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Category[]>(`/api/project/categories`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getSubCategories(categoryId: string): Promise<SubCategory[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<SubCategory[]>(`/api/project/categories/${categoryId}/subcategories`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getTypes(subCategoryId: string): Promise<Type[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Type[]>(`/api/project/subcategories/${subCategoryId}/types`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getInspirationsForCategory(
    categoryId: string,
    subCategoryId: string,
    styleType: StyleType
  ): Promise<Inspiration[]> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (categoryId) {
        searchParams.set('category_id', String(categoryId));
      }
      if (subCategoryId) {
        searchParams.set('subcategory_id', String(subCategoryId));
      }
      if (styleType) {
        searchParams.set('style_name', String(styleType));
      }
      axios
        .get<Inspiration[]>(`/api/project/inspirations?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  updateProjectStatus(projectId: string, toStatus: TaskStatusType): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .patch<Project>(`/api/project/status/${projectId}/${toStatus}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getProjectComments(projectId: string): Promise<Comments[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Comments[]>(`/api/project/${projectId}/comments`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  createProjectComment(projectId: string, data: Comments): Promise<Comments> {
    return new Promise((resolve, reject) => {
      axios
        .post<Comments>(`/api/project/${projectId}/comments`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  createReplyComment(data: Replies): Promise<Replies> {
    return new Promise((resolve, reject) => {
      axios
        .post<Replies>(`/api/project/comments/replies`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getLatestAttachment(projectId: string): Promise<ProjectDeliverable[]> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (projectId) {
        searchParams.set('project_id', String(projectId));
      }
      axios
        .get<ProjectDeliverable[]>(`/api/project/attachment/latest?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          handleApiError(ex, reject);
        });
    });
  }

  getProjectAttachmentListing(): Promise<ProjectsListing> {
    return new Promise((resolve, reject) => {
      axios
        .get<ProjectsListing>(`/api/project/listing/attachments`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          handleApiError(ex, reject);
        });
    });
  }

  getProjectsAttachmentByMonth(date: string): Promise<ProjectListingByMonth> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (date) {
        searchParams.set('date', String(date));
      }
      axios
        .get<ProjectListingByMonth>(`/api/project/listing/attachments/bymonth?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          handleApiError(ex, reject);
        });
    });
  }

  getProjectsAttachmentByProject(project_id: string): Promise<ProjectListingByProject> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (project_id) {
        searchParams.set('project_id', String(project_id));
      }
      axios
        .get<ProjectListingByProject>(`/api/project/listing/attachments/byproject?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          handleApiError(ex, reject);
        });
    });
  }

  createIteration(projectId: string, body: ProjectIteration): Promise<ProjectIteration> {
    return new Promise((resolve, reject) => {
      axios
        .post<ProjectIteration>(`/api/project/${projectId}/iterations`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getIterationById(project_id: string, iteration_id: string): Promise<ProjectIteration> {
    return new Promise((resolve, reject) => {
      axios
        .get<ProjectIteration>(`/api/project/${project_id}/iterations/${iteration_id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          handleApiError(ex, reject);
        });
    });
  }

  getProjectIterations(project_id: string): Promise<ProjectIteration[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<ProjectIteration[]>(`/api/project/${project_id}/iterations`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          handleApiError(ex, reject);
        });
    });
  }

  deleteIteration(project_id: string, iteration_id: string) {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/project/${project_id}/iterations/${iteration_id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  // New Deliverables
  getAttachments(projectId: string, iterationId: string) {
    return new Promise((resolve, reject) => {
      axios
        .get<ProjectAttachmentResponse>(
          `api/project/attachment?project_id=${projectId}&project_iteration_id=${iterationId}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getAttachmentsByType(projectId: string, assteType: DeliveryAssetType) {
    return new Promise((resolve, reject) => {
      axios
        .get<ProjectAttachmentResponse>(
          `api/project/attachment/bytype?project_id=${projectId}&project_asset_type=${assteType}`
        )
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }

  getAllAttachments(projectId: string) {
    return new Promise((resolve, reject) => {
      axios
        .get<ProjectAttachmentResponse>(`api/project/attachment?project_id=${projectId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex: AxiosError) => {
          handleApiError(ex, reject);
        });
    });
  }
}

export const projectsService = new ProjectsService();
