import { cn, useCheckbox, VisuallyHidden } from '@nextui-org/react';
import brandCollaterals from 'presentation/assets/onboarding/brandCollaterals.png';
import decksOrPresentation from 'presentation/assets/onboarding/decksOrPresentation.png';
import digitalAds from 'presentation/assets/onboarding/digitalAds.png';
import illustration from 'presentation/assets/onboarding/illustration.png';
import merchandiseGoodies from 'presentation/assets/onboarding/merchandiseGoodies.png';
import socialMediaGraphics from 'presentation/assets/onboarding/socialMediaGraphics.png';
import tradeShowEssentials from 'presentation/assets/onboarding/tradeShowEssentials.png';
import videos from 'presentation/assets/onboarding/videos.png';

/* eslint-disable @typescript-eslint/no-explicit-any */
const icons: any = {
  brandCollaterals,
  decksOrPresentation,
  digitalAds,
  illustration,
  merchandiseGoodies,
  socialMediaGraphics,
  tradeShowEssentials,
  videos,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const CustomServiceSelection = (props: any) => {
  const { isSelected, getBaseProps, getInputProps } = useCheckbox({
    ...props,
  });

  return (
    <label {...getBaseProps()}>
      <VisuallyHidden>
        <input {...getInputProps()} />
      </VisuallyHidden>
      <div
        className={cn(
          'w-40 h-[68px] md:w-44 md:h-24 lg:w-48 lg:h-28 xl:w-56 2xl:w-64',
          'inline-flex flex-col items-center justify-center',
          'bg-white m-0 rounded-lg gap-1 pb-1 px-2 border border-solid border-[#F0F0F0]',
          'hover:bg-[#F0F0F0] hover:rounded-xl',
          'data-[selected=true]:bg-[#F0F0F0] data-[selected=true]:rounded-xl',
          'data-[selected=true]:border data-[selected=true]:border-solid data-[selected=true]:border-[black]'
        )}
        data-selected={isSelected}
      >
        <div
          className={cn(
            'w-11 h-11 md:w-12 md:h-12',
            'rounded-full flex items-center justify-center m-0'
          )}
        >
          <img src={icons[props.value]} alt={props.label} />
        </div>
        <p className={cn('m-0', 'text-[10px] md:text-sm')}>{props.label}</p>
      </div>
    </label>
  );
};
