import { AxiosError, InternalAxiosRequestConfig } from 'axios';

export interface ApiError {
  code: string;
  message: string;
}

export const handleApiError = (
  error: AxiosError,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  callback: (result: ApiError | InternalAxiosRequestConfig<any> | undefined) => void
) => {
  try {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const data = error.response.data as any;

      callback({
        code: data.code || error.response.status,
        message: data.message,
      });
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      callback({
        code: 'NO_RESPONSE',
        message: 'No Response',
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      callback({
        code: 'UNEXPECTED_ERROR',
        message: 'Unexpected Error',
      });
    }
    return;
  } catch {
    callback(error.config);
    return;
  }
  callback(error.config);
};
