import { cn } from '@nextui-org/system';
import { IColumn } from 'application/modules/projects/types';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';

interface IProps {
  column: IColumn;
  children: React.ReactElement;
  canShowPlaceholder: boolean;
  disableColumn: boolean;
}

export const KanbanColumn = ({ column, children, canShowPlaceholder, disableColumn }: IProps) => {
  const { children: childrenArray } = children.props;
  const isItemAvailable =
    childrenArray && Array.isArray(childrenArray) && childrenArray.length > 0 ? true : false;

    const placeHolderLabelsRequired = ['IN PROGRESS', 'IN REVIEW'];

    return (
      <div
        key={column.id}
        className={cn(
          'w-[480px]',
          'bg-[#FDFDFD] rounded-lg',
          'border border-solid border-[#E8E8E8] flex flex-col gap-0 truncate',
          disableColumn ? 'hidden' : ''
        )}
      >
        <div className={cn('w-full py-2', 'bg-[#F0F0F0] border-b-[#F4F4F4] border-b border-solid')}>
          <p className={cn('text-[#313131] text-center text-[16px] font-medium uppercase')}>
            {column.label}
          </p>
        </div>
        <Droppable key={column.id} droppableId={column.id}>
          {(provided: DroppableProvided) => (
            <div
              className={cn('grow flex flex-col overflow-y-auto overflow-x-hidden px-2 py-4')}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {children}
              {column.label === 'START DESIGNING' && !isItemAvailable && canShowPlaceholder ? (
                <p
                  className={cn(
                    'text-[#8F8F8F] text-xs italic leading-5',
                    'text-left text-wrap pt-60'
                  )}
                >
                  Move your to-do items here to get started with design!
                </p>
              ) : (
                placeHolderLabelsRequired.includes(column.label) && (
                  <p
                    className={cn(
                      'text-[#8F8F8F] text-xs italic leading-5',
                      'text-left text-wrap pt-60'
                    )}
                  >
                    Only admins have access to move to this state.
                  </p>
                )
              )}
            </div>
          )}
        </Droppable>
      </div>
    );
};
