import { useState } from 'react';
import { TitleAndCategory } from './steps/TitleAndCategory';
import { StyleSelection } from './steps/StyleSelection';
import { CustomQuestions } from './steps/CustomQuestions';
import { BrandSelection } from './steps/BrandSelection';
import { AdditionalInputs } from './steps/AdditionalInputs';
import { useProjectDetail } from 'application/modules/projects';
import { Project } from 'domain/modules';
import { DescriptionAndNarration } from './steps/DescriptionAndNarration';

export type CreateProjectSteps =
  | 'titleAndCategory'
  | 'styleSelection'
  | 'customQuestions'
  | 'brandSelection'
  | 'descriptionAndNarration'
  | 'additionalQuestions'
  | 'completed';

export const Wizard = ({
  project: projectDefaultData,
  onCompleteCreation,
}: {
  onClose: () => void;
  project?: Project;
  onCompleteCreation: (credits: number) => void;
}) => {
  const [stepName, setStepName] = useState<CreateProjectSteps>('titleAndCategory');
  const [fromStyle, setFromStyle] = useState<boolean | undefined>(false);
  const { project, isFetching, getProjectDetail } = useProjectDetail(projectDefaultData);

  const onChangeStep = (step: CreateProjectSteps, projectId?: string, fromStep?: boolean) => {
    if (step === 'completed') {
      onCompleteCreation(project?.credits ?? 0);
      return;
    }
    setStepName(step);
    setFromStyle(fromStep);
    if (projectId) {
      getProjectDetail(projectId);
    }
  };

  switch (stepName) {
    case 'titleAndCategory':
      return (
        <TitleAndCategory project={project} isFetching={isFetching} onChangeStep={onChangeStep} />
      );
    case 'styleSelection':
      return (
        <StyleSelection project={project} isFetching={isFetching} onChangeStep={onChangeStep} />
      );
    case 'customQuestions':
      return (
        <CustomQuestions
          project={project}
          isFetching={isFetching}
          onChangeStep={onChangeStep}
          fromStyle={fromStyle}
        />
      );
    case 'brandSelection':
      return (
        <BrandSelection project={project} isFetching={isFetching} onChangeStep={onChangeStep} />
      );
    case 'descriptionAndNarration':
      return (
        <DescriptionAndNarration
          project={project}
          isFetching={isFetching}
          onChangeStep={onChangeStep}
        />
      );
    case 'additionalQuestions':
      return (
        <AdditionalInputs project={project} isFetching={isFetching} onChangeStep={onChangeStep} />
      );
    default:
      return null;
  }
};
