import { LeftPanel } from '../components/LeftPanel';
import { Project } from 'domain/modules';
import { WizardFooter } from '../components/WizardFooter';
import { CreateProjectSteps } from '../Wizard';
import { CreateProjectHeader } from '../components/Header';
import { useEffect, useState } from 'react';
import { DynamicForms } from 'presentation/components/DynamicForm';
import { useFormRender } from './hooks/useFormRender';
import { useProjectDetail } from 'application/modules/projects';
import { has } from 'lodash';
import { LOGO_CATEGORY_ID } from '../constants';

interface IProps {
  project?: Project;
  isFetching: boolean;
  onChangeStep: (stepName: CreateProjectSteps, projectId?: string) => void;
  fromStyle?: boolean;
}

export const CustomQuestions = (props: IProps) => {
  const { project } = props;
  const { updateProject } = useProjectDetail();
  const { fieldData, lovData, formik, getFields } = useFormRender(
    async (submitValues: Record<string, unknown>) => {
      if (props.project) {
        const tempProject = { ...props.project };
        if (!tempProject.questions_set) {
          tempProject.questions_set = {};
        }
        tempProject.questions_set = {
          ...tempProject.questions_set,
          ...submitValues,
        };
        const data = await updateProject(tempProject);
        if (data) {
          if (project?.category_id === LOGO_CATEGORY_ID) {
            props.onChangeStep('descriptionAndNarration', data.id);
          } else {
            props.onChangeStep('brandSelection', data.id);
          }
        }
      }
    },
    props.project
  );
  const [currentGroupStep, setCurrentGroupStep] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    if (project) {
      getFields(project);
    }
  }, [project]);

  useEffect(() => {
    if (has(formik.values, 'group5') && !props.fromStyle) {
      setCurrentGroupStep(4);
    }
  }, []);

  const onNext = () => {
    setShowErrorMessage(true);
    const totalGroupSteps = Object.keys(fieldData).length;
    if (totalGroupSteps === currentGroupStep + 1) {
      if (formik) {
        if (formik.isValid) {
          formik.submitForm();
        }
      }
    } else {
      const currentGroupKey = Object.keys(fieldData)[currentGroupStep];
      if (Object.keys(formik.values).length && !has(formik.errors, currentGroupKey)) {
        setCurrentGroupStep(currentGroupStep + 1);
        setShowErrorMessage(false);
      }
    }
  };

  const onPrev = () => {
    if (currentGroupStep === 0) {
      props.onChangeStep('titleAndCategory');
    } else {
      setCurrentGroupStep(currentGroupStep - 1);
    }
  };

  return (
    <LeftPanel>
      <>
        {Object.keys(fieldData).map((groupName, index, arr) => {
          if (currentGroupStep !== index) {
            return null;
          }
          return (
            <>
              <CreateProjectHeader
                title="Create Design Task"
                totalSteps={project?.category_id === LOGO_CATEGORY_ID ? 4 + arr.length : 6}
                currentStep={2 + (currentGroupStep + 1)}
              />
              <div className="grow flex flex-col gap-4">
                <DynamicForms
                  fieldData={fieldData}
                  groupName={groupName}
                  lovData={lovData}
                  formik={formik}
                  showErrorMessage={showErrorMessage}
                />
              </div>
              <WizardFooter
                onNext={onNext}
                onPrev={onPrev}
                nextTitle={project?.category_id !== LOGO_CATEGORY_ID ? 'Choose Brand preset' : ''}
              />
            </>
          );
        })}
      </>
    </LeftPanel>
  );
};
