import { DeliveryAssetType, ProjectAttachmentResponse, ProjectDeliverable } from 'domain/modules';
import { projectsService } from 'infra/api';
import { useState } from 'react';

export const useProjectDeliverables = () => {
  const [deliverable, setDeliverable] = useState<ProjectDeliverable[]>([]);
  const [deliveryAttachments, setDeliveryAttachments] = useState<ProjectAttachmentResponse[]>([]);
  const [attachmentsForAllIterations, setAttachmentsForAllIterations] = useState<
    ProjectAttachmentResponse[]
  >([]);
  const [attachmentsByType, setAttachmentsByType] = useState<ProjectAttachmentResponse[]>([]);

  const [isFetching, setIsFetching] = useState(false);

  const getAllDeliverables = async (projectId: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getLatestAttachment(projectId);
      if (Array.isArray(data)) {
        setDeliverable(data);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const getAttachmentsByType = async (projectId: string, assteType: DeliveryAssetType) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getAttachmentsByType(projectId, assteType);
      if (Array.isArray(data)) {
        setAttachmentsByType(data);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const getAttachments = async (projectId: string, iterationId: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getAttachments(projectId, iterationId);
      if (Array.isArray(data)) {
        setDeliveryAttachments(data);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  const getAllAttachments = async (projectId: string) => {
    try {
      setIsFetching(true);
      const data = await projectsService.getAllAttachments(projectId);
      if (Array.isArray(data)) {
        setAttachmentsForAllIterations(data);
      }
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    deliveryAttachments,
    deliverable,
    attachmentsForAllIterations,
    attachmentsByType,
    getAllDeliverables,
    getAttachments,
    getAllAttachments,
    getAttachmentsByType,
  };
};
