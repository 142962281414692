export const columns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'assetId',
    label: 'Asset ID',
  },
  {
    key: 'project_id',
    label: 'Project ID',
  },
  {
    key: 'project_iteration_id',
    label: 'Project Iteration ID',
  },
  {
    key: 'media_url',
    label: 'Media URL',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
