import { useAppDispatch, useAppSelector } from 'application/shared';
import { getAllProjects } from '../projects.thunks';
import { useMemo } from 'react';
import { BoardColumnsType } from '../types';
import { columnList } from '../constants';
import { TaskStatusType } from 'domain/modules';
import { projectsService } from 'infra/api';

export const useWorkboard = () => {
  const { isFetchingProjectList, projectsList } = useAppSelector((state) => state.project);
  const dispatch = useAppDispatch();

  const getAll = () => {
    return dispatch(getAllProjects());
  };

  const updateTaskStatus = async (projectId: string, toStatus: TaskStatusType) => {
    try {
      const data = await projectsService.updateProject(projectId, toStatus);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  };

  const data = useMemo(() => {
    const result: BoardColumnsType = {};

    columnList.map((c) => {
      if (!result[c.id]) {
        result[c.id] = {
          name: c.id,
          tasks: [],
        };
      }
    });

    projectsList.forEach((project) => {
      let _status = project.status;
      _status = project.status === 'IN_ACTIVE' ? 'TODO' : project.status;

      if (!result[_status]) {
        result[_status] = {
          name: _status,
          tasks: [],
        };
      }

      result[_status]?.tasks.push(project);
    });

    return result;
  }, [projectsList]);

  return {
    isFetchingProjectList,
    data,
    projectsList,
    getAll,
    updateTaskStatus,
  };
};
