import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { Step4 } from './Step4';
import { Step5 } from './Step5';

export const Steps = {
  step1: Step1,
  step2: Step2,
  step3: Step3,
  step4: Step4,
  // step5: Step5,
};

export const StepFieldName = {
  step1: 'picassoPurpose',
  step2: 'whoIsUsing',
  step3: 'knowAboutPicasso',
  step4: 'picassoServices',
  // step5: 'connectToDiscord'
};

export type StepsKeys = keyof typeof Steps;
