import axios from 'axios';
import { Invoice, Subscription } from 'domain/modules/subscription/models';
import { AddonPlan, Plan } from 'domain/modules/subscription/models/plans.model';
import { ISubscriptionService } from 'domain/modules/subscription/services';
import { has } from 'lodash';

class SubscriptionService implements ISubscriptionService {
  getAllPlans(): Promise<Array<Plan>> {
    return new Promise((resolve, reject) => {
      axios
        .get<Array<Plan>>('/api/credit-payments/plans')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getAllAddOnPlans(): Promise<Array<AddonPlan>> {
    return new Promise((resolve, reject) => {
      axios
        .get<Array<AddonPlan>>(`/api/credit-payments/addons`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getUserSubscriptions(): Promise<Subscription> {
    return new Promise((resolve, reject) => {
      axios
        .get<Subscription>('/api/credit-payments/subscription')
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getUserInvoice(): Promise<Invoice[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<{ invoice: Invoice }[]>('/api/credit-payments/invoice')
        .then((res) => {
          if (Array.isArray(res.data)) {
            resolve(res.data.map((d) => d.invoice));
            return;
          }
          reject('Invalid response');
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }

  getHostedUrl(priceId: string, type: 'plan' | 'addon'): Promise<string> {
    return new Promise((resolve, reject) => {
      axios
        .post<string>(`/api/credit-payments/hosted-page`, {
          priceId,
          type,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          if (has(ex, 'response.data.errors[0].title')) {
            const error = ex.response.data.errors[0].title;
            reject(error === 'Invalid ID' ? 'Invalid username or password' : error);
          } else {
            reject(ex);
          }
        });
    });
  }
}

export const subscriptionService = new SubscriptionService();
