export const styleNameList = [
  {
    id: 'Standard',
    name: 'Standard',
  },
  {
    id: 'Intermediate',
    name: 'Intermediate',
  },
  {
    id: 'Pro',
    name: 'Pro',
  },
];

export const fieldTypes = [
  {
    id: 'text',
    label: 'Text',
  },
  {
    id: 'textArea',
    label: 'Text Area',
  },
  {
    id: 'number',
    label: 'Number',
  },
  {
    id: 'select',
    label: 'Dropdown',
  },
  {
    id: 'multiselect',
    label: 'Multi Select',
  },
  {
    id: 'colorpicker',
    label: 'Color Picker',
  },
];

export const columns = [
  {
    key: 'id',
    label: 'ID',
  },
  {
    key: 'label',
    label: 'LABEL',
  },
  {
    key: 'detail_view_label',
    label: 'DETAIL_VIEW_LABEL',
  },
  {
    key: 'group_name',
    label: 'GROUP NAME',
  },
  {
    key: 'field_name',
    label: 'FIELD NAME',
  },
  {
    key: 'field_type',
    label: 'FIELD TYPE',
  },
  {
    key: 'fields_per_row',
    label: 'FIELDS PER ROW',
  },
  {
    key: 'lov_group_name',
    label: 'LOV GROUP NAME',
  },
  {
    key: 'order',
    label: 'ORDER',
  },
  {
    key: 'is_conditional',
    label: 'IS CONDITIONAL',
  },
  // {
  //   key: 'conditional_field_id',
  //   label: 'CONDITIONAL FIELD ID',
  // },
  {
    key: 'conditional_group_id',
    label: 'CONDITIONAL GROUP ID',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];
