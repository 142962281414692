import { NextUIProvider } from '@nextui-org/react';
import { ReduxProvider } from 'application/shared';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { RootRoute } from 'presentation/routes/RootRoute';

axios.defaults.baseURL = import.meta.env.VITE_API_HOST;

// Deploy
function App() {
  return (
    <div className="App h-full">
      <ReduxProvider>
        <NextUIProvider className="h-full">
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            maxSnack={3}
          >
            <RootRoute />
          </SnackbarProvider>
        </NextUIProvider>
      </ReduxProvider>
    </div>
  );
}

export default App;
