import { useAssetsUpload } from 'application/shared';
import { useState } from 'react';
import { AssetUploadResponse } from 'infra/api';
import { Button, CircularProgress } from '@nextui-org/react';
import { FileUploader } from 'react-drag-drop-files';

interface IFileUploadProps {
  path: string;
  hasUploadedFiles: boolean;
  handleSaveUploadFile: (id: string, asset: AssetUploadResponse) => void;
  fileLength: number;
  id: string;
}

const supportedFileTypes = ['TTF', 'ZIP'];

export const FontUploader = ({
  path,
  hasUploadedFiles,
  handleSaveUploadFile,
  fileLength,
  id,
}: IFileUploadProps) => {
  const [loading, setIsLoading] = useState(false);
  const { upload } = useAssetsUpload();

  const handleChange = async (file: File) => {
    try {
      setIsLoading(true);
      const filePath = await upload(path, file);
      handleSaveUploadFile(id, filePath);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  if (fileLength >= 5) {
    return null;
  }

  return (
    <FileUploader handleChange={handleChange} name="file" types={supportedFileTypes}>
      {hasUploadedFiles ? (
        <Button isIconOnly variant="light">
          {loading ? (
            <CircularProgress aria-label="Loading..." size="sm" />
          ) : (
            <i className="ri-upload-cloud-2-line" />
          )}
        </Button>
      ) : (
        <Button isIconOnly variant="light" isDisabled>
          {loading ? (
            <CircularProgress aria-label="Loading..." size="sm" />
          ) : (
            <i className="ri-upload-cloud-2-line" />
          )}
        </Button>
      )}
    </FileUploader>
  );
};

{
  /* <div className="bg-[#40576d12] w-20 h-20 rounded border flex justify-center items-center hover:bg-gray-100 cursor-pointer">
          {loading ? (
            <CircularProgress aria-label="Loading..." size="sm" />
          ) : (
            <div className="text-center">
              <i className="ri-upload-cloud-2-line" />
              <p className="text-xs font-light">Add new</p>
            </div>
          )}
        </div> */
}
