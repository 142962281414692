import { cn } from '@nextui-org/system';
import { Subscription } from 'domain/modules';
import { Button } from '../Button';
import { transformSecondsToMs } from 'presentation/utils/dateInSecondsToMS';
import './style.css';

interface IProps {
  subscription: Subscription | null;
  timeRemaining: number;
  onOpen: () => void;
}

export const PlanNoticeBanner = ({ subscription, timeRemaining, onOpen }: IProps) => {
  if (subscription && transformSecondsToMs(subscription.period_end_date) < new Date().getTime()) {
    return (
      <div
        className={cn(
          'w-full min-h-14',
          'flex items-center justify-center gap-4',
          ' bg-[#FBF2F2]',
          'mb-6'
        )}
      >
        <h1 className={cn('text-[#232323] text-lg font-medium')}>You plan has expired!</h1>
        <Button
          className="bg-black rounded-full py-1 px-8 text-white text-[16px] font-bold"
          onClick={() => onOpen()}
        >
          Upgrade plan
        </Button>
      </div>
    );
  }

  return (
    <>
      {subscription && timeRemaining <= 5 ? (
        <div
          className={cn('w-full min-h-10 mb-6', 'flex items-center justify-center', 'bg-[#F2F6FB]')}
        >
          <h1 className={cn('text_gradient_timer')}>
            {timeRemaining === 0 ? message.TODAY() : message.IN_A_FEW_DAYS(timeRemaining)}
          </h1>
        </div>
      ) : null}
    </>
  );
};

const message = {
  TODAY: () => 'Your plan is expiring today!',
  IN_A_FEW_DAYS: (timeRemaining: number) =>
    `Your plan will be expired in ${timeRemaining} day${timeRemaining === 1 ? '' : 's'}!`,
};
