import { Progress, cn, useDisclosure } from '@nextui-org/react';
import dayjs from 'dayjs';
import { useWorkboard } from 'application/modules/projects';
import { useSubscription } from 'application/modules/subscription';
import { TaskStatusType } from 'domain/modules';
import { Button } from 'presentation/components';
import { KanbanBoard } from 'presentation/components/KanbanBoard';
import { NoSubscription } from 'presentation/components/KanbanBoard/components/NoSubscription';
import { CreateProject } from 'presentation/modules/company/screens/createProject';
import { SubscribeToCredits } from 'presentation/modules/subscription/screens/subscribeToCredits';
import { useEffect, useState } from 'react';
// import { useBrand } from 'application/modules/brand';
// import { NoBrandsAvailable } from 'presentation/components/KanbanBoard/components/NoBrandsAvailable';
import { PlanNoticeBanner } from 'presentation/components/PlanNoticeBanner';
import { PaymentSuccessful } from 'presentation/modules/subscription/components/PaymentSuccessful';
import { transformSecondsToMs } from 'presentation/utils/dateInSecondsToMS';

import './style.css';

export const WorkboardScreen = () => {
  const { getAll, updateTaskStatus } = useWorkboard();
  const {
    getPlans,
    plans,
    get,
    subscription,
    isFetchingSubscription,
    isFetchingPlans,
    getIsPlanPurchased,
    getIsCreditsPurchased,
  } = useSubscription();

  // const { getAll: getAllBrands, brandList } = useBrand();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const {
  //   isOpen: brandModalIsOpen,
  //   onOpen: brandModalOnOpen,
  //   onOpenChange: brandModalOnOpenChange,
  // } = useDisclosure();
  const {
    isOpen: paymentModalIsOpen,
    onOpen: paymentModalOnOpen,
    onClose: paymentModalOnClose,
  } = useDisclosure();

  const [timeRemaining, setTimeRemaining] = useState(-1);

  useEffect(() => {
    getAll();
    getPlans();
    get()
      .then((res) => {
        if (!res) {
          onOpen();
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
    // getAllBrands();
    // if (location.state && location.state.paymentSuccessful) {
    //   setAfterSubscription(true);
    //   paymentModalOnOpen();
    //   location.state = {};
    // }
  }, []);

  // useEffect(() => {
  //   if (
  //     subscription &&
  //     brandList &&
  //     brandList.length === 0 &&
  //     !paymentModalIsOpen &&
  //     window.sessionStorage.getItem('showBrandPopup')
  //   ) {
  //     setTimeout(() => {
  //       brandModalOnOpen();
  //     }, 4000);
  //   }
  // }, [subscription, paymentModalIsOpen]);

  const updateProjectStatus = async (projectId: string, status: TaskStatusType) => {
    await updateTaskStatus(projectId, status);
    getAll();
    get();
  };

  useEffect(() => {
    if (subscription) {
      const endDate = dayjs(transformSecondsToMs(subscription.period_end_date));
      const currentDate = dayjs();
      const timeDifference = endDate.diff(currentDate, 'days');

      console.log(timeDifference);

      if (isNaN(timeDifference)) {
        setTimeRemaining(-1);
      } else {
        setTimeRemaining(timeDifference);
      }
    }
  }, [subscription]);

  return (
    <>
      <div className="h-full w-full flex flex-col items-center overflow-x-hidden">
        <PlanNoticeBanner
          subscription={subscription}
          timeRemaining={timeRemaining}
          onOpen={onOpen}
        />
        <div className="w-full pl-10 pr-6 pt-7 flex items-start justify-between">
          <h1 className="font-bold text-3xl 2xl:text-4xl">My Workboard</h1>
          <div className="flex flex-col items-end">
            <div className="flex gap-4 items-center mb-3 pr-2">
              <SubscribeToCredits
                isWorkboard
                isDisabled={!subscription}
                refreshAll={getIsCreditsPurchased}
              />
              <CreateProject
                Trigger={({ open }) => {
                  return (
                    <Button
                      className={cn('button_background', 'bg-[#99CF63] text-black font-medium')}
                      onClick={() => {
                        if (!isFetchingSubscription && !subscription) {
                          onOpen();
                        } else if (subscription) {
                          open();
                        }
                      }}
                    >
                      Create Project
                    </Button>
                  );
                }}
              />
            </div>

            {subscription ? (
              <div className="flex items-center justify-between px-2 gap-1">
                <Progress
                  isStriped
                  color="default"
                  value={(subscription.used_credits / subscription.total_purchased_credits) * 100}
                  className="min-w-[175px] max-w-[200px] h-2 progress-loader"
                  classNames={{
                    track: 'bg-[#F0F0F0]',
                  }}
                />
                <p className="text-[#828282] text-xs font-medium min-w-[215px] text-right">
                  {subscription.used_credits} of {subscription.total_purchased_credits} Design
                  credits used
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-full grow">
          <KanbanBoard
            updateProjectStatus={updateProjectStatus}
            subscription={subscription}
            getSubscriptionData={get}
            isFetchingSubscription={isFetchingSubscription}
            onOpen={onOpen}
          />
        </div>
      </div>

      <NoSubscription
        isOpen={isOpen}
        plans={plans}
        isFetchingPlans={isFetchingPlans}
        onCloseSubscription={(status: string) => {
          if (status === 'skip') onClose();
          else {
            getIsPlanPurchased(() => {
              onClose();
              paymentModalOnOpen();
            });
          }
        }}
      />
      {/* <NoBrandsAvailable
        brandModalIsOpen={brandModalIsOpen}
        brandModalOnOpenChange={brandModalOnOpenChange}
      /> */}
      <PaymentSuccessful
        paymentModalIsOpen={paymentModalIsOpen}
        paymentModalOnClose={paymentModalOnClose}
        successType="plans"
        planType={subscription?.name || ''}
        creditsCount={0}
      />
    </>
  );
};
