import { VisuallyHidden, cn, useCheckbox } from '@nextui-org/react';
import { Inspiration } from 'domain/modules';
import { useEffect, useState } from 'react';

interface IProps {
  data: Inspiration;
  isDefaultSelected: boolean;
  onChange: (id: string, isSelected: boolean) => void;
  isLogoCategory?: boolean;
  tempInspirations?: Array<string>;
}

export const InspirationCard = ({
  data,
  isDefaultSelected,
  onChange,
  isLogoCategory,
  tempInspirations,
}: IProps) => {
  const [isSelected, setIsSelected] = useState(isDefaultSelected);
  const { getBaseProps, getLabelProps, getInputProps } = useCheckbox();

  useEffect(() => {
    onChange(data.id, isSelected);
  }, [isSelected]);

  const onSelectItem = (e: { target: { checked: boolean } }) => {
    if (tempInspirations) {
      if (!e.target.checked) {
        setIsSelected(e.target.checked);
        return;
      }
      if (isLogoCategory && tempInspirations.length < 1) {
        setIsSelected(e.target.checked);
      } else if (!isLogoCategory && tempInspirations.length < 2) {
        setIsSelected(e.target.checked);
      }
    } else {
      setIsSelected(e.target.checked);
    }
  };

  return (
    <div
      className={cn(
        'max-w-[250px] max-h-[165px]',
        'flex-shrink-0 flex-grow-0 rounded-lg bg-gray-100 truncate'
      )}
    >
      <label {...getBaseProps()} className="h-full w-full relative">
        <VisuallyHidden>
          <input {...getInputProps()} onChange={onSelectItem} checked={isSelected} />
        </VisuallyHidden>
        <div style={{ position: 'relative', height: '100%', width: '100%' }}>
          <img
            src={data.media_url}
            {...getLabelProps()}
            className="h-full w-full object-cover"
            alt="Inspiration"
          />
          {isSelected ? (
            <i
              className="ri-radio-button-line ri-2x absolute top-0 text-gray-100"
              style={{ right: '10px' }}
            />
          ) : (
            <i
              className="ri-circle-fill ri-2x absolute top-0 text-gray-100"
              style={{ right: '10px' }}
            />
          )}
        </div>
      </label>
    </div>
  );
};
