/* eslint-disable @typescript-eslint/no-explicit-any */
import { DynamicField, useDynamicForm } from 'application/modules/shared/hooks/useDynamicForm';
import { Project } from 'domain/modules';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { get } from 'lodash';
import * as Yup from 'yup';

export const useFormRender = (
  onSubmit: (values: Record<string, unknown>) => void,
  project?: Project
) => {
  const { getFieldData, flatFields, fieldData, lovData } = useDynamicForm();
  const [fields, setFields] = useState<Record<string, DynamicField[]>>({});

  const getFieldValidations = () => {
    const validations: Record<string, any> = {};
    Object.keys(fieldData).map((groupName: string) => {
      let validationsData: Record<string, any> = {};
      fieldData[groupName].forEach((field) => {
        if (!field.is_conditional) {
          validationsData = {
            ...validationsData,
            [field.field_name]: Yup.string().required('Required'),
          };
        } else {
          const { conditional_group_id } = field;
          const conditionalField = flatFields.find(
            (f) => String(f.id) === String(conditional_group_id)
          );
          const conditionalfieldName = `${conditionalField?.group_name}.${conditionalField?.field_name}`;
          const schema: any = field.field_type === 'number' ? Yup.number() : Yup.string();

          validationsData = {
            ...validationsData,
            [field.field_name]: schema
              .test('required', 'Required', function (value: any, context: any) {
                let result = true;
                /* eslint-disable @typescript-eslint/no-unused-vars */
                const [_parent1, parent2] = context.from as any;
                const cVal = get(parent2.value, conditionalfieldName);
                if (cVal === 'custom' && !value) {
                  result = false;
                }

                return result;
              })
              .test('max', 'Maximum value error', function (value: any) {
                const result = true;
                if (field.validations) {
                  try {
                    const _validations =
                      typeof field.validations === 'string'
                        ? JSON.parse(field.validations)
                        : field.validations;
                    if (_validations.max && value > _validations.max) {
                      return false;
                    }
                  } catch (ex) {
                    console.log(ex);
                  }
                }
                return result;
              })
              .test('min', 'Minimum value error', function (value: any) {
                const result = true;
                if (field.validations) {
                  try {
                    const _validations =
                      typeof field.validations === 'string'
                        ? JSON.parse(field.validations)
                        : field.validations;
                    if (_validations.min && value < _validations.min) {
                      return false;
                    }
                  } catch (ex) {
                    console.log(ex);
                  }
                }
                return result;
              }),
          };
        }
      });
      validations[groupName] = Yup.object(validationsData);
    });
    return validations;
  };

  const formik = useFormik({
    initialValues: project?.questions_set || {},
    validationSchema: Yup.object().shape({
      ...getFieldValidations(),
    }),
    validateOnBlur: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const getFields = (project: Project) => {
    if (project) {
      getFieldData(project);
    }
  };

  useEffect(() => {
    const newFormData: Record<string, DynamicField[]> = {};
    Object.keys(fieldData).forEach((groupName: string) => {
      fieldData[groupName].forEach((field: DynamicField) => {
        if (field.is_conditional) {
          const fieldName = `${field?.group_name}.${field?.field_name}`;
          const { conditional_group_id } = field;
          const conditionalField = flatFields.find(
            (f) => String(f.id) === String(conditional_group_id)
          );
          const conditionalfieldName = `${conditionalField?.group_name}.${conditionalField?.field_name}`;
          const conditionalFieldValue = String(get(formik.values, conditionalfieldName)) || '';

          if (conditionalFieldValue === 'custom') {
            if (!newFormData[groupName]) {
              newFormData[groupName] = [];
            }
            newFormData[groupName].push(field);
          } else {
            formik.setFieldValue(fieldName, undefined);
          }
        } else {
          if (!newFormData[groupName]) {
            newFormData[groupName] = [];
          }

          newFormData[groupName].push(field);
        }
      });
    });
    setFields(newFormData);
  }, [formik.values, fieldData, flatFields]);

  return {
    fieldData: fields,
    lovData,
    formik,
    getFields,
  };
};
