import { Card, CardBody, CardFooter, CardHeader, Chip, cn, useDisclosure } from '@nextui-org/react';
import { useProjectDetail } from 'application/modules/projects';
import { STATE_TRANSITIONS } from 'application/modules/projects/constants';
import { useProjectStatus } from 'application/modules/projects/hooks/useProjectStatus';
import { canTransition } from 'application/modules/projects/utils';
import { Project, Subscription } from 'domain/modules';
import { Button } from 'presentation/components/Button';
import { DynamicDropdown } from 'presentation/components/DynamicDropdown';
import { CreateProjectCustomTrigger } from 'presentation/modules/company/screens/createProject';
import { useState } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { InsufficientCredits } from './InsufficientCredits';
import { ConfirmationPopup } from './ConfirmationPopup';

interface IProps {
  task: Project;
  index: number;
  projectsList: Array<Project>;
  subscription: Subscription | null;
  getSubscriptionData: () => void;
}

export const TaskCard = ({
  task,
  index,
  projectsList,
  subscription,
  getSubscriptionData,
}: IProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCopy, setIsCopy] = useState<boolean>(true);
  const { isOpen: modalIsOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: creditsModalIsOpen,
    onOpen: creditsModalOnOpen,
    onClose: creditsModalOnClose,
    onOpenChange: creditsModalOnOpenChange,
  } = useDisclosure();
  const {
    isOpen: confirmationModalIsOpen,
    onOpen: confirmationModalOnOpen,
    onOpenChange: confirmationModalOnOpenChange,
    onClose: confirmationModalOnClose,
  } = useDisclosure();
  const { updateTaskStatus, getAll, deleteProject } = useProjectStatus();
  const { cloneProject, updateProject } = useProjectDetail();
  const navigate = useNavigate();

  const projectsInLine = () => {
    const temp = projectsList.filter(
      (project) =>
        project.status === 'START_DESIGNING' ||
        project.status === 'IN_PROGRESS' ||
        project.status === 'IN_REVIEW'
    );

    if (temp.length > 0) {
      return temp;
    }
    return [];
  };

  const isCreditsExhausted = () => {
    if (subscription) {
      const totalCredits = subscription.total_purchased_credits;
      const usedCredits = subscription.used_credits;

      return totalCredits - usedCredits < task.credits ? true : false;
    } else {
      return false;
    }
  };

  const updateStatus = async () => {
    const nextState = STATE_TRANSITIONS[task.status]
      ? STATE_TRANSITIONS[task.status][0]
      : task.status;
    const projectsInLine = projectsList.filter(
      (project) =>
        project.status === 'START_DESIGNING' ||
        project.status === 'IN_PROGRESS' ||
        project.status === 'IN_REVIEW'
    );
    const isIterationRequested = task.iteration.filter((p) => p.isActive === false).length > 0;
    if (nextState && nextState !== task.status) {
      if (nextState === 'START_DESIGNING' && projectsInLine.length > 0) {
        alert(`Couldn't move current project to next state`);
      } else if (nextState === 'START_DESIGNING' && isCreditsExhausted()) {
        creditsModalOnOpen();
      } else if (nextState === 'COMPLETED' && isIterationRequested) {
        alert(`Couldn't move current project to next state as Iteration is Requested`);
      } else {
        if (canTransition(task.status, nextState)) {
          await updateTaskStatus(task.id, nextState);
          getAll();
          getSubscriptionData();
        }
      }
    }
  };

  const onDelete = async () => {
    await deleteProject(task.id);
    getAll();
    confirmationModalOnClose();
  };

  const openProject = (isEdit = false) => {
    if (isEdit) {
      // onOpen();
      navigate(`/projects/${task.id}`, { state: { edit: true } });
    } else {
      navigate(`/projects/${task.id}`);
    }
  };

  const copyProject = async () => {
    const data = await cloneProject(task.id);

    if (data) {
      data.project_display_id = `SD_${data.unique_id}`;
      const newData = await updateProject(data);
      if (newData) {
        getAll();
        confirmationModalOnClose();
      }
    }
  };

  const hasARequestedIteration = () => {
    return task?.iteration?.filter((i) => i.isActive === false).length > 0;
  };

  const hasInProgressIteration = () => {
    if (task.status === 'IN_PROGRESS') {
      if (task?.iteration.length === 1) {
        return false;
      }
      const totalIterationList = task?.iteration?.filter((i) => i.isActive === true);
      return (totalIterationList || []).length > 0;
    }
    return false;
  };

  const hasACompletedIteration = () => {
    if (task.status !== 'IN_REVIEW') {
      return false;
    }
    const totalActiveTasks = task?.iteration?.filter((i) => i.isActive === true).length;

    if (task?.iteration.length === 1) {
      return false;
    }

    return totalActiveTasks === task?.iteration.length;
  };

  return (
    <>
      <Draggable
        draggableId={String(task.id)}
        key={String(task.id)}
        index={index}
        isDragDisabled={task.status === 'IN_PROGRESS' || task.status === 'COMPLETED'}
      >
        {(provided: DraggableProvided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className="mb-4 min-h-[40px]"
            style={{
              ...provided.draggableProps.style,
            }}
          >
            <Card
              classNames={{
                base: cn(
                  'bg-white rounded-lg shadow-none border border-[#828282] gap-4 transition-all p-[10px] m-0'
                ),
                header: 'flex items-center justify-between max-h-[24px] p-0',
                body: 'flex flex-col p-0',
                footer: 'flex p-0 gap-1 items-center max-h-3 px-[4px]',
              }}
            >
              <CardHeader>
                <div className="flex items-center gap-1 truncate">
                  <Button
                    isIconOnly
                    size="sm"
                    variant="light"
                    className="p-0"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    {isOpen ? (
                      <i className="ri-arrow-down-s-line ri-2x" />
                    ) : (
                      <i className="ri-arrow-right-s-line ri-2x" />
                    )}
                  </Button>
                  <p
                    className={cn(
                      'text-[#232323] text-sm font-medium leading-4',
                      'truncate py-1 rounded-md  text-ellipsis'
                    )}
                    title={task.name}
                  >
                    {task.project_display_id}_{task.name}
                  </p>
                </div>
                <DynamicDropdown
                  task={task}
                  updateStatus={updateStatus}
                  openProject={openProject}
                  confirmationModalOnOpen={confirmationModalOnOpen}
                  setIsCopy={setIsCopy}
                  projectsInLine={projectsInLine}
                />
              </CardHeader>
              {!isOpen ? null : (
                <>
                  <CardBody className="cursor-pointer">
                    {hasARequestedIteration() ? (
                      <div className="bg-[#5050501a] py-1 px-4 mb-3">
                        <span className="text-xs text-black font-medium">Iteration Requested</span>
                      </div>
                    ) : null}
                    {hasACompletedIteration() ? (
                      <div className="bg-[#5050501a] py-1 px-4 mb-3">
                        <span className="text-xs text-black font-medium">Iteration Completed</span>
                      </div>
                    ) : null}
                    {hasInProgressIteration() && (
                      <div className="bg-[#5050501a] py-1 px-4 mb-3">
                        <span className="text-xs text-black font-medium">
                          Iteration In Progress
                        </span>
                      </div>
                    )}
                    <div className=" flex flex-col gap-3">
                      <div className="flex flex-col " onClick={() => openProject()}>
                        <p className="text-[10px] text-[#8F8F8F] font-medium">Description:</p>
                        <p className="text-black text-xs truncate text-wrap line-clamp-2 ">
                          {task.description || 'NA'}
                        </p>
                      </div>
                      <div
                        className="flex flex-col justify-between items-start gap-2"
                        onClick={() => openProject()}
                      >
                        <p className="truncate text-xs">
                          <span className="text-[#828282] text-[10px] font-medium mr-1">
                            Category:
                          </span>
                          <span className="text-black ext-xs">{task.category.name || 'NA'}</span>
                        </p>

                        <Chip
                          variant="bordered"
                          size="sm"
                          className={cn(
                            'border-1 border-solid',
                            task.status === 'IN_ACTIVE' ? ' border-[#828282]' : ' border-[#99CF63]'
                          )}
                          classNames={{
                            content: 'text-[#232323] text-[10px] font-medium',
                          }}
                        >
                          {task.status === 'IN_ACTIVE' ? 'Draft' : `${task.credits} Design Credits`}
                        </Chip>
                      </div>
                    </div>
                    <CreateProjectCustomTrigger
                      project={task}
                      isOpen={modalIsOpen}
                      onClose={onClose}
                      onOpen={onOpen}
                    />
                  </CardBody>
                  <CardFooter>
                    <Chip
                      size="sm"
                      variant="light"
                      startContent={<i className="ri-folder-6-line" />}
                      className="text-[#828282] text-[10px] font-medium p-0"
                    >
                      {task.deliverable_item && Array.isArray(task.deliverable_item)
                        ? task.deliverable_item.length
                        : 0}{' '}
                      files
                    </Chip>
                    <Chip
                      size="sm"
                      variant="light"
                      startContent={<i className="ri-message-3-line" />}
                      className="text-[#828282] text-[10px] font-medium p-0"
                    >
                      {task.comments && Array.isArray(task.comments) ? task.comments.length : 0}{' '}
                      comments
                    </Chip>

                    {task.iteration &&
                    Array.isArray(task.iteration) &&
                    task.iteration.length > 0 ? (
                      <Chip
                        size="sm"
                        variant="light"
                        startContent={<i className="ri-folder-6-line" />}
                        className="text-[#787486] text-[10px] font-medium p-0"
                      >
                        {task.iteration.length - 1} iterations
                      </Chip>
                    ) : null}
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        )}
      </Draggable>

      <InsufficientCredits
        isOpen={creditsModalIsOpen}
        onOpen={creditsModalOnOpen}
        onClose={creditsModalOnClose}
        creditsModalOnOpenChange={(...args) => {
          getSubscriptionData();
          creditsModalOnOpenChange(...args);
        }}
      />

      <ConfirmationPopup
        title={task.name}
        isCopy={isCopy}
        confirmationModalIsOpen={confirmationModalIsOpen}
        confirmationModalOnOpenChange={confirmationModalOnOpenChange}
        onDelete={onDelete}
        copyProject={copyProject}
      />
    </>
  );
};
