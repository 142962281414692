import CommentStructure from '../CommentStructure.tsx/Index';
import InputField from '../InputField/Index';
import './CommentSection.css';
import { useContext, useState } from 'react';
import { GlobalContext } from '../../context/Provider';
import _ from 'lodash';
import { Button } from 'presentation/components';

interface CommentSectionProps {
  overlayStyle?: object;
  logIn: {
    loginLink: string;
    signupLink: string;
  };
  hrStyle?: object;
  titleStyle?: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  customNoComment?: Function;
}

const CommentSection = ({ logIn }: CommentSectionProps) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const globalStore: any = useContext(GlobalContext);
  const [showAll, setShowAll] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [isHidden, setIsHidden] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const toggleShowButton = () => {
    setShowButton(!showButton);
  };

  return (
    <div className="overlay">
      <InputField formStyle={{ margin: '10px 0px' }} imgDiv={{ margin: 0 }} />

      {globalStore.data.map(
        (i: {
          userId: string;
          comId: string;
          fullName: string;
          avatarUrl: string;
          text: string;
          userProfile?: string;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          replies: Array<any> | undefined;
          updatedAt: string;
        }) => {
          return (
            <div key={i.comId} style={{ marginBottom: '12px' }}>
              <CommentStructure
                info={i}
                editMode={_.indexOf(globalStore.editArr, i.comId) === -1 ? false : true}
                replyMode={_.indexOf(globalStore.replyArr, i.comId) === -1 ? false : true}
                logIn={logIn}
              />
              <div className="replySection">
                {i.replies && i.replies.length > 0 ? (
                  <>
                    {!isHidden &&
                      i.replies.slice(0, showAll ? i.replies.length : 3).map((j) => {
                        return (
                          <div key={j.comId}>
                            <CommentStructure
                              info={j}
                              parentId={i.comId}
                              editMode={
                                _.indexOf(globalStore.editArr, j.comId) === -1 ? false : true
                              }
                              replyMode={
                                _.indexOf(globalStore.replyArr, j.comId) === -1 ? false : true
                              }
                              logIn={logIn}
                            />
                          </div>
                        );
                      })}

                    {i.replies.length > 3 && showButton && (
                      <Button
                        variant="light"
                        className="text-[#45BCFF] text-sm"
                        onClick={() => {
                          toggleShowAll();
                          toggleShowButton();
                          setIsHidden(false);
                        }}
                      >
                        {isHidden
                          ? `Show All (${i.replies.length})`
                          : `Show More (${i.replies.length - 3})`}
                      </Button>
                    )}

                    {!showButton && (
                      <Button
                        variant="light"
                        className="text-[#45BCFF] text-sm"
                        onClick={() => {
                          toggleShowAll();
                          toggleShowButton();
                          setIsHidden(true);
                        }}
                      >
                        Hide All
                      </Button>
                    )}

                    {showAll && (
                      <Button
                        variant="light"
                        className="text-[#45BCFF] text-sm"
                        onClick={() => {
                          toggleShowAll();
                          toggleShowButton();
                        }}
                      >
                        Show Less
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default CommentSection;
