export const stylesData = [
  {
    id: 'Standard',
    name: 'Standard',
  },
  {
    id: 'Intermediate',
    name: 'Intermediate',
  },
  {
    id: 'Pro',
    name: 'Pro',
  },
];

export const LOGO_CATEGORY_ID = 'b52ab647-9e1c-48a9-8ffb-56680115c127';
