import { adminApi } from 'adminApp/api';
import { CreateFaqDto } from 'adminApp/models/create-faq';
import { useState } from 'react';

export const useFaqCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CreateFaqDto | null>(null);

  const create = async (data: CreateFaqDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.createFaq(data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to create category');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const update = async (categoryId: string, data: CreateFaqDto) => {
    console.log(categoryId, data);
  };

  const reset = () => {
    setIsLoading(false);
    setData(null);
  };
  return {
    isLoading,
    create,
    update,
    reset,
    data,
  };
};
