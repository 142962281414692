import { brandIntroduction } from 'presentation/constants';
import { CreateBrand } from '../useCases/CreateBand';
import tick_icon from 'presentation/assets/tick_icon.svg';
import { Brand } from 'domain/modules/brand';
import { useState } from 'react';
import { Input } from '@nextui-org/react';
import { Button } from 'presentation/components';

interface IProps {
  title?: string;
  renderAction?: React.ReactElement;
  noBanner?: boolean;
  isTitleEditable?: boolean;
  updateStaticObject?: (key: keyof Brand, name: string) => void;
  onSave?: (shouldNavigate: boolean) => void;
}

export const Header = ({
  title = 'Brand Setup',
  renderAction,
  noBanner = false,
  isTitleEditable = false,
  updateStaticObject,
  onSave,
}: IProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [newTitle, setNewTitle] = useState(title);

  const isTitleValid = newTitle && newTitle.length <= 40 ? true : false;

  const onChangeTitle = () => {
    if (updateStaticObject && onSave && isTitleValid) {
      updateStaticObject('name', newTitle);
      setIsEditMode(!isEditMode);
      onSave(false);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between px-6 pt-3">
        <div className="flex items-center gap-2">
          {isEditMode ? (
            <Input
              variant="underlined"
              size="sm"
              className="w-[400px]"
              value={newTitle}
              onChange={(ev) => setNewTitle(ev.target.value)}
              errorMessage={newTitle.length > 40 && 'Maximum number of characters is 40'}
            />
          ) : (
            <h1 className="text-[#0D1216] text-4xl font-bold">{title}</h1>
          )}
          {isTitleEditable ? (
            <Button
              isIconOnly
              variant="light"
              onClick={() => onChangeTitle()}
              className="cursor-pointer hover:bg-none"
            >
              {isEditMode ? (
                <i className="ri-check-line ri-xl" />
              ) : (
                <i className="ri-pencil-line ri-xl" />
              )}
            </Button>
          ) : null}
        </div>

        {renderAction ? renderAction : <CreateBrand />}
      </div>
      {noBanner ? null : (
        <div className="brand_header_banner rounded-md bg-black mx-6 mt-4 text-white px-6 py-4">
          <h1 className="font-bold text-xl mb-3">Introducing your Brand</h1>
          {brandIntroduction.map((intro, index) => (
            <div key={index} className="flex items-start gap-2 mb-2">
              <img src={tick_icon} alt="tick_icon" />
              <p className="w-5/12 text-sm">{intro}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
