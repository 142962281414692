import { TaskStatusType } from 'domain/modules';
import { STATE_TRANSITIONS } from './constants';

export const canTransition = (fromState: TaskStatusType, toState: TaskStatusType): boolean => {
  if (fromState === toState) {
    return true;
  }

  if (STATE_TRANSITIONS[fromState]) {
    if (STATE_TRANSITIONS[fromState].includes(toState)) {
      return true;
    }
  }

  return false;
};
