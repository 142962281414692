import { adminApi } from 'adminApp/api';
import { CreateInspirationDto } from 'adminApp/models/create-inspiration';
import { useState } from 'react';

export const useCreate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CreateInspirationDto | null>(null);

  const create = async (data: CreateInspirationDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.createInspiration(data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to create category');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const update = async (inspirationId: string, data: CreateInspirationDto) => {
    setIsLoading(true);
    setData(null);

    try {
      const res = await adminApi.updateInspiration(inspirationId, data);
      setData(res);
    } catch (ex) {
      console.log(ex);
      alert('Unable to create inspiration');
    } finally {
      setIsLoading(false);
    }
    return;
  };

  const reset = () => {
    setIsLoading(false);
    setData(null);
  };

  return {
    isLoading,
    data,
    create,
    update,
    reset,
  };
};
