import { Card, CardBody, Tab, Tabs } from '@nextui-org/react';
import { Category } from './Category';
import { SubCategory } from './SubCategory';
import { Type } from './Type';
import { Lov } from './lov';
import { Field } from './field';
import { Credits } from './credits';
import { Inspiration } from './Inspiration';
import { ProjectAttachment } from './ProjectAttachment';
import { ProjectManagement } from './projectManagement';
import { Button } from 'presentation/components';
import { useAuth } from 'application/modules';
import { CommentsManagement } from './Comments';
import { FaqManagement } from './Faq';

const tabs = [
  {
    id: 'category',
    label: 'Category',
    element: <Category />,
  },
  {
    id: 'subcategory',
    label: 'Sub Category',
    element: <SubCategory />,
  },
  {
    id: 'type',
    label: 'Type',
    element: <Type />,
  },
  {
    id: 'inspiration',
    label: 'Inspiration',
    element: <Inspiration />,
  },
  {
    id: 'projectAttachment',
    label: 'Project Attachment',
    element: <ProjectAttachment />,
  },
  {
    id: 'lov',
    label: 'List of values',
    element: <Lov />,
  },
  {
    id: 'field',
    label: 'Field',
    element: <Field />,
  },
  {
    id: 'credits',
    label: 'Credits',
    element: <Credits />,
  },
  {
    id: 'projectManagement',
    label: 'Project Management',
    element: <ProjectManagement />,
  },
  {
    id: 'commentsManagement',
    label: 'Comments',
    element: <CommentsManagement />,
  },
  {
    id: 'faqManagement',
    label: 'FAQ',
    element: <FaqManagement />,
  },
];

export const AdminApp = () => {
  const { logout } = useAuth();
  return (
    <>
      <div className="absolute right-8 top-4">
        <Button onClick={logout}>Logout</Button>
      </div>
      <div className="h-full flex flex-col">
        <Tabs
          aria-label="Admin Panel"
          classNames={{
            base: 'justify-center mt-4',
            panel: 'mx-6',
          }}
        >
          {tabs.map((tab) => {
            return (
              <Tab key={tab.id} title={tab.label}>
                <Card>
                  <CardBody>{tab.element}</CardBody>
                </Card>
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </>
  );
};
