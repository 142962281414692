import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'application/modules';

interface IProps {
  component: JSX.Element;
}

export const UnAuthGuard = ({ component }: IProps) => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/workboard" replace />;
  }

  return <React.Fragment>{component}</React.Fragment>;
};
