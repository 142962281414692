import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectItem,
} from '@nextui-org/react';
import { useCompanies } from './hooks/useCompanies';
import { useProjects } from './hooks/useProjects';
import { useEffect, useState } from 'react';
import { StatusSelection } from './StatusSelection';

export const ProjectManagement = () => {
  const { companies, isFetching: isFetchingCompanies, getAll: getAllCompanies } = useCompanies();
  const {
    projects,
    isFetching: isFetchingProjects,
    getAll: getAllProject,
    getProjectById,
    project,
  } = useProjects();

  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedProject, setSelectedProject] = useState('');

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    if (selectedProject) {
      getProjectById(selectedProject);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (selectedCompany) {
      getAllProject(selectedCompany);
    }
  }, [selectedCompany]);

  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-2xl font-semibold">Project Management</h1>

      <Select
        placeholder="Select a company"
        label="Company"
        labelPlacement="outside"
        className="max-w-xs"
        isLoading={isFetchingCompanies}
        defaultSelectedKeys={selectedCompany ? [selectedCompany] : []}
        onChange={(ev) => setSelectedCompany(ev.target.value)}
      >
        {companies.map((company) => (
          <SelectItem key={String(company.id)} value={String(company.id)}>
            {company.id}
          </SelectItem>
        ))}
      </Select>
      <div className="flex gap-4 items-end">
        <Select
          placeholder="Select a project"
          label="Project"
          labelPlacement="outside"
          className="max-w-xs"
          isLoading={isFetchingProjects}
          defaultSelectedKeys={selectedProject ? [selectedProject] : []}
          onChange={(ev) => setSelectedProject(ev.target.value)}
        >
          {projects.map((project) => (
            <SelectItem key={project.id} value={project.name}>
              {project.name}
            </SelectItem>
          ))}
        </Select>
        {selectedProject ? (
          <Popover placement="bottom" showArrow offset={10}>
            <PopoverTrigger>
              <Button variant="ghost" size="sm">
                Update project status
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[240px]">
              {(titleProps) => (
                <div className="px-1 py-2 w-full">
                  <p className="text-small font-bold text-foreground" {...titleProps}>
                    Update project status
                  </p>
                  <div className="mt-2 flex flex-col gap-2 w-full">
                    <StatusSelection projectId={selectedProject} project={project} />
                  </div>
                </div>
              )}
            </PopoverContent>
          </Popover>
        ) : null}
      </div>
    </div>
  );
};
