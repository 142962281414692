import { adminApi } from 'adminApp/api';
import { DynamicFields } from 'adminApp/models/create-dynamic-field';
import { StyleType } from 'adminApp/models/create-form-with-combination';
import { useState } from 'react';

export type FieldCombinationArgs = {
  categoryId: string;
  subCategoryId: string | null;
  style_name: StyleType | null;
  typeId: string | null;
};

export const useGetAll = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<DynamicFields[]>([]);

  const getAll = async (args: Partial<FieldCombinationArgs>) => {
    setIsLoading(true);
    setData([]);
    adminApi
      .getFieldsByCombination(args)
      .then((res) => {
        setData(res);
      })
      .catch(() => {
        alert('Unable to get types');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteType = async (fieldId: string, args: Partial<FieldCombinationArgs>) => {
    adminApi
      .deleteField(fieldId)
      .then(() => {
        getAll(args);
        alert('Deleted type');
      })
      .catch(() => {
        alert('Unable to delete type');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    isLoading,
    data,
    getAll,
    deleteType,
  };
};
