import { useBrand } from 'application/modules/brand';
import { useEffect, useRef } from 'react';
import { NoContent } from '../components/NoContent';
import { Header } from '../components/Header';
import { BrandCard } from '../components/BrandCard';
import { useSubscription } from 'application/modules/subscription';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';

export const BrandListing = () => {
  const { getAll, brandList, isFetchingList } = useBrand();
  const { get, subscription } = useSubscription();
  const isFetched = useRef(false);

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (!isFetched.current) {
      getAll();
      isFetched.current = true;
    }
  }, [getAll]);

  if (!isFetchingList && brandList.length === 0) {
    return (
      <div className="flex flex-col justify-between px-10 py-10 h-full">
        <Header />
        <NoContent subscription={subscription} />
      </div>
    );
  }

  if (isFetchingList) {
    return (
      <div className="flex flex-col justify-center items-center">
        <Player
          src={inspiration_loader}
          className="player"
          loop
          autoplay
          style={{
            height: '80vh',
          }}
        />
        <p className="absolute pt-36">Fetching Brands...</p>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="mx-6 p-6 flex flex-wrap gap-6">
        {brandList.map((brand) => (
          <BrandCard key={brand.id} brand={brand} />
        ))}
      </div>
    </>
  );
};
