import { useEffect, useState } from 'react';
import { CreateType } from './Create';
import { TypesTable } from './Table';
import { Button } from 'presentation/components';
import { useCategories } from './hooks/useCategories';
import { Select, SelectItem } from '@nextui-org/react';
import { useSubCategories } from './hooks/useSubCategories';

export const Type = () => {
  const [refetch, setRefetch] = useState(false);
  const { categoryList, getAllCategories, isFetching } = useCategories();
  const {
    subcategoryList,
    getAllSubCategories,
    isFetching: isFetchingSubCategoryList,
  } = useSubCategories();
  const [categoryId, setCategoryId] = useState<string>();
  const [subcategoryId, setSubCategoryId] = useState<string>();

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (categoryId) {
      setSubCategoryId('');
      getAllSubCategories(categoryId);
    }
  }, [categoryId]);

  return (
    <div>
      <div className="mb-4 flex flex-row-reverse justify-between items-center">
        {!categoryId || !subcategoryId ? (
          <div />
        ) : (
          <CreateType
            categoryId={categoryId}
            subcategoryId={subcategoryId}
            Trigger={({ open }) => {
              return (
                <Button color="primary" className="bg-black text-white" onClick={open}>
                  Add Type
                </Button>
              );
            }}
            refreshList={() => {
              setRefetch(true);

              setTimeout(() => {
                setRefetch(false);
              }, 100);
            }}
          />
        )}
        <div className="flex gap-4">
          <Select
            items={categoryList}
            label="Category"
            placeholder="Select a category"
            className="w-96"
            isLoading={isFetching}
            defaultSelectedKeys={categoryId ? [categoryId] : []}
            onChange={(ev) => {
              setSubCategoryId('');
              setCategoryId(ev.target.value);
            }}
          >
            {(item) => (
              <SelectItem key={item.id} value={item.id}>
                {item.name}
              </SelectItem>
            )}
          </Select>
          {categoryId ? (
            <Select
              items={subcategoryList}
              label="Sub Category"
              placeholder="Select a sub category"
              className="w-96"
              isLoading={isFetchingSubCategoryList}
              defaultSelectedKeys={subcategoryId ? [subcategoryId] : []}
              onChange={(ev) => setSubCategoryId(ev.target.value)}
            >
              {(item) => (
                <SelectItem key={item.id} value={item.id}>
                  {item.name}
                </SelectItem>
              )}
            </Select>
          ) : null}
        </div>
      </div>
      {refetch || !categoryId || !subcategoryId ? null : (
        <TypesTable categoryId={categoryId} subcategoryId={subcategoryId} />
      )}
    </div>
  );
};
