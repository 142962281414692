import { Header } from './header';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Card, cn, Tooltip } from '@nextui-org/react';
import axios from 'axios';
import { useProjectDeliverables } from 'application/modules/projects/hooks/useProjectDeliverables';
import { Button } from 'presentation/components';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { Player } from '@lottiefiles/react-lottie-player';
import { ProjectAttachmentResponse } from 'domain/modules';

export const ListingsByProject = () => {
  const { projectId, iterationId } = useParams();
  const { getAttachments, deliveryAttachments, isFetching } = useProjectDeliverables();
  const location = useLocation();
  const { state } = location;

  const handleDownload = (img: string) => {
    const anchor = document.createElement('a');
    anchor.href = img;
    anchor.download = img;
    anchor.click();
    document.body.removeChild(anchor);
  };

  useEffect(() => {
    if (projectId && iterationId) {
      getAttachments(projectId, iterationId);
    }
  }, [projectId, iterationId]);

  const downloadKey = (iterationId: string) => {
    window.open(`${axios.defaults.baseURL}/api/storage/zipDownload/${iterationId}`, '_blank');
  };

  const pageTitle = () => {
    const label = state && state.projectTitle;
    return decodeURIComponent(label);
  };

  if (isFetching || !deliveryAttachments || deliveryAttachments.length === 0) {
    return (
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '80vh',
        }}
      />
    );
  }

  const actualDeliverableCount =
    deliveryAttachments.filter(
      (da) =>
        ![
          'MOCKUP_FILES_THUMBNAIL',
          'SHOWCASE_FILES_THUMBNAIL',
          'SOURCE_FILES_THUMBNAIL',
          'SHOWCASE_FILES',
        ].includes(da.project_asset_type)
    )?.length || 0;

  if (actualDeliverableCount <= 0) {
    return (
      <>
        <Header title={pageTitle()} />
        <div className="flex gap-6 mt-4">
          <span className="text-xs italic px-4 py-5">
            No attachments uploaded for this iteration
          </span>
        </div>
      </>
    );
  }

  const getImageByFileType = (deliverables: ProjectAttachmentResponse) => {
    // return (
    //   <LazyImage
    //     alt={deliverables.project_asset_type}
    //     className="object-contain h-[120px] w-[120px] mb-[25px] cursor-pointer"
    //     src={zipFile}
    //     onClick={() => downloadKey(deliverables.project_iteration_id)}
    //   />
    // );
    const _url = deliverables.asset.url.toLowerCase();
    const commonClassNames = 'text-9xl text-gray-500';

    if (_url.includes('.zip')) {
      return <i className={cn('ri-file-zip-fill', commonClassNames)} />;
    }

    if (_url.includes('.mp3')) {
      return <i className={cn('ri-file-music-fill', commonClassNames)} />;
    }
    if (_url.includes('.mp4')) {
      return <i className={cn('ri-file-video-fill', commonClassNames)} />;
    }
    if (_url.includes('.jpg') || _url.includes('.jpeg')) {
      return <i className={cn('ri-file-image-fill', commonClassNames)} />;
    }
    if (_url.includes('.png')) {
      return <i className={cn('ri-file-image-fill', commonClassNames)} />;
    }
    if (_url.includes('.pdf')) {
      return <i className={cn('ri-file-pdf-2-fill', commonClassNames)} />;
    }
    if (_url.includes('.ppt') || _url.includes('.pptx')) {
      return <i className={cn('ri-file-ppt-fill', commonClassNames)} />;
    }
    if (_url.includes('.docx') || _url.includes('.doc')) {
      return <i className={cn('ri-file-word-fill', commonClassNames)} />;
    }
    if (_url.includes('.xls') || _url.includes('.xlsx')) {
      return <i className={cn('ri-file-excel-fill', commonClassNames)} />;
    }
    return <i className={cn('ri-file-3-fill', commonClassNames)} />;
  };

  return (
    <>
      <Header title={pageTitle()} />
      <div className="flex gap-6 mt-4">
        {deliveryAttachments.map((deliverables, index) => {
          if (
            deliverables.project_asset_type === 'MOCKUP_FILES_THUMBNAIL' ||
            deliverables.project_asset_type === 'SHOWCASE_FILES_THUMBNAIL' ||
            deliverables.project_asset_type === 'SOURCE_FILES_THUMBNAIL' ||
            deliverables.project_asset_type === 'SHOWCASE_FILES'
          ) {
            return null;
          }
          return (
            <div key={index} className="">
              <Card
                radius="lg"
                className="border-none h-[250px] w-[250px] flex flex-col justify-between pt-8"
              >
                <div className="flex justify-center items-center">
                  {getImageByFileType(deliverables)}
                </div>

                <Tooltip showArrow={true} content={deliverables.name}>
                  <div className="flex items-center justify-between px-5">
                    <p className="line-clamp-1 text-sm">{deliverables.name}</p>

                    <Button
                      isIconOnly
                      variant="light"
                      className="p-0"
                      onClick={() => handleDownload(deliverables.asset.url)}
                      isDisabled={deliverables.project_asset_type === 'SOURCE_FILE'}
                    >
                      <i className="ri-download-2-line" />
                    </Button>
                  </div>
                </Tooltip>
              </Card>
            </div>
          );
        })}
      </div>
    </>
  );
};
