import { loginWithMagiclink } from 'application/modules/auth/auth.thunks';
import { useAppDispatch } from 'application/shared';
import { useEffect } from 'react';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';

import './style.css';
import { useNavigate } from 'react-router-dom';
import { Player } from '@lottiefiles/react-lottie-player';

export default function CompleteSignin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get('email') || '';
    if (email) {
      setTimeout(() => {
        dispatch(loginWithMagiclink(email));
      }, 3000);
    } else {
      navigate('/login');
    }
  }, [dispatch, navigate]);

  return (
    <div className="completeLogin_screen">
      <Player
        src={inspiration_loader}
        className="player"
        loop
        autoplay
        style={{
          height: '30vh',
        }}
      />
      <p className="text-gray-700">Signing you in</p>
    </div>
  );
}
