import { Button } from './../../../../components';
import login_page_logo from './../../../../assets/logo/login_page_logo.png';
import googleImage from './../../../../assets/social_icons/google.svg';
import microsoftImage from './../../../../assets/social_icons/microsoft.svg';
import { useAppDispatch } from 'application/shared';
import { loginWithGoogle, loginWithMicrosoft } from 'application/modules/auth/auth.thunks';
import { MagicLink } from './MagicLink';

import { Checkbox, cn } from '@nextui-org/react';
import { useState } from 'react';

export const LoginScreen = () => {
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(true);

  return (
    <div className="h-full w-full flex items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center justify-center gap-5 mb-9">
          <img
            src={login_page_logo}
            alt="Picassofusion"
            className="w-[100px] h-[100px] lg:w-[110px] lg:h-[110px]"
          />

          <div className="flex flex-col items-center justify-center gap-2.5">
            <h1 className="text-center font-bold text-3xl md:text-3xl lg:text-[32px]">
              Welcome to Picassofusion
            </h1>
            <h4 className="text-center text-xl leading-[125%] lg:w-3/5 w-3/5">
              Log in or sign up to embark on your journey today with us
            </h4>
          </div>
        </div>

        <Button
          size="lg"
          className={cn(
            'w-4/5 lg:min-w-[480px] min-h-[60px]',
            'bg-[#F0F0F0] rounded-full text-black',
            'text-[16px] font-medium',
            'mb-4'
          )}
          startContent={
            <img src={googleImage} alt="Google" className="w-5 h-5 object-contain mr-2" />
          }
          onClick={() => {
            dispatch(loginWithGoogle());
          }}
          isDisabled={!isChecked}
        >
          Continue with Google
        </Button>
        <Button
          size="lg"
          className={cn(
            'w-4/5 lg:min-w-[480px] min-h-[60px]',
            'bg-[#F0F0F0] rounded-full text-black',
            'text-[16px] font-medium',
            'mb-4'
          )}
          startContent={
            <img src={microsoftImage} alt="Microsoft" className="w-5 h-5 object-contain mr-2" />
          }
          onClick={() => {
            dispatch(loginWithMicrosoft());
          }}
          isDisabled={!isChecked}
        >
          Continue with Microsoft
        </Button>

        <span className={cn('italic text-sm text-black font-medium', 'mb-4')}>(or)</span>
        <MagicLink isChecked={isChecked} />

        <div className={cn('checkbox w-4/5 lg:w-3/5 mt-10')}>
          <Checkbox
            radius="none"
            classNames={{
              label: 'text-[#232323] text-xs ml-3',
            }}
            onChange={() => setIsChecked(!isChecked)}
            isSelected={isChecked}
          >
            By Clicking Continue you are agreeing to Picassofusions’s
            <span className="underline cursor-pointer">Terms & Conditions</span> and Acknowledging
            the <span className="underline cursor-pointer">Privacy Policy</span>
          </Checkbox>
        </div>
      </div>
    </div>
  );
};
