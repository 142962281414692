import { Subscription } from 'domain/modules';
import { Button } from 'presentation/components';
import { CreateProject } from 'presentation/modules/company/screens/createProject';

interface IProps {
  subscription: Subscription | null;
}

export const NoContent = ({ subscription }: IProps) => {
  return (
    <div className="brand_nocontent flex flex-col gap-24">
      <div className="flex flex-col justify-center items-center message">
        <h1 className="text-6xl font-semibold text-center mt-">Oops!</h1>
        <p className="text-[#0D1216] text-sm font-light italic text-center mt-5">
          You haven’t saved any Brand Kits yet, start by adding brand assets!
        </p>
      </div>
      {subscription ? (
        <div className="bg-[#F1F3F5] rounded-xl border-2 border-solid border-[#F1F3F5] mx-6 mt- px-10 py-4 flex justify-between items-center">
          <p className="text-black font-medium w-2/5 mr-3 text-medium">
            Don’t have a brand kit? Start by scheduling a branding task with us instead!
          </p>

          <CreateProject
            Trigger={({ open }) => {
              return (
                <Button
                  className="bg-black rounded-full text-white font-semibold text-sm px-8"
                  size="md"
                  onClick={open}
                >
                  Create Project
                </Button>
              );
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
