/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useAuth } from 'application/modules';
import { useProjectComments } from 'application/modules/projects';
import { Comments, Project, Replies } from 'domain/modules';
import { CommentSection } from 'presentation/vendors/react-comment-section';
import 'presentation/vendors/react-comment-section/Index.scss';
import { useProjectDetailContext } from '../../context';

interface IProps {
  project: Project;
  getProjectDetail?: (id: string) => void;
}

export const ProjectComments = (props: IProps) => {
  const { getProjectDetail = null } = props;
  const { beUser } = useAuth();
  const { comments, getComments, addComment, addReplyComment } = useProjectComments();
  const { setCommentsCount } = useProjectDetailContext();

  const countCommentsAndReplies = (comments: Comments[]) => {
    let count = comments.length;

    comments.forEach((comment) => {
      if (Array.isArray(comment.replies) && comment.replies.length) {
        count += comment.replies.length;
      }
    });

    return count;
  };

  useEffect(() => {
    if (props.project) {
      getComments(props.project.id).then((res) => {
        if (Array.isArray(res)) {
          setCommentsCount(countCommentsAndReplies(res));
        }
      });
    }
  }, []);

  return (
    <CommentSection
      currentUser={{
        currentUserId: beUser ? beUser.id : '',
        currentUserImg: beUser ? beUser.profile_picture : '',
        currentUserProfile: beUser ? beUser.profile_picture : '',
        currentUserFullName: beUser ? beUser.name : '',
      }}
      logIn={{
        loginLink: 'https://picassofusion.com/login',
        signupLink: 'https://picassofusion.com/login',
      }}
      commentData={comments}
      onSubmitAction={async (data: Comments) => {
        await addComment(props.project.id, data);
        if (props.project) {
          getComments(props.project.id).then((res) => {
            if (Array.isArray(res)) {
              setCommentsCount(countCommentsAndReplies(res));
            }
          });
          getProjectDetail && getProjectDetail(props.project.id);
        }
      }}
      onReplyAction={async (data: Replies) => {
        await addReplyComment(data);
        if (props.project) {
          getComments(props.project.id).then((res) => {
            if (Array.isArray(res)) {
              setCommentsCount(countCommentsAndReplies(res));
            }
          });
          getProjectDetail && getProjectDetail(props.project.id);
        }
      }}
      currentData={(data: any) => {
        console.log('curent data', data);
      }}
    />
  );
};
