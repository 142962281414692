import { Project } from 'domain/modules';
import { Lov } from 'domain/shared/models/lov.model';
import {
  DynamicFieldsResponse,
  dynamicFieldsService,
} from 'infra/api/modules/shared/dynamicFields.service';
import { useMemo, useState } from 'react';

export interface DynamicField {
  id: string;
  label: string;
  detail_view_label: string;
  group_name: string;
  field_name: string;
  field_type: string;
  fields_per_row: number;
  style: string;
  lov_group_name: string;
  form_id: string;

  is_conditional: boolean;
  conditional_field_id?: string;
  conditional_group_id?: string;
  validations?: string;
}

export const useDynamicForm = () => {
  const [fields, setFields] = useState<DynamicFieldsResponse[] | null>(null);
  const [lovData, setLovData] = useState<Lov[]>([]);

  const getFieldData = (project: Project) => {
    if (project.category_id && project.sub_category_id) {
      dynamicFieldsService
        .getFormFields(
          project.category_id,
          project.sub_category_id,
          project.type_id || null,
          project.style_name || null
        )
        .then((res) => {
          const _lovKeys: string[] = [];
          res.forEach((lovKey) => {
            if (lovKey.lov_group_name) {
              _lovKeys.push(lovKey.lov_group_name);
            }
          });
          getLovData(_lovKeys);
          if (Array.isArray(res)) {
            setFields(res);
          }
        })
        .catch(console.log);
    }
  };

  const removeDuplicates = (array: Lov[]) => {
    return array.filter(
      (obj, index, self) => index === self.findIndex((o) => o['id'] === obj['id'])
    );
  };

  const getLovData = async (lovKeys: string[]) => {
    const _lovData = await Promise.all(
      lovKeys.map(async (lov) => {
        const _lovArr = await dynamicFieldsService.getDynamicFormsLov(lov);

        return _lovArr;
      })
    );

    let finalValue: Lov[] = [];

    _lovData.forEach((i) => {
      finalValue = [...finalValue, ...i];
    });

    const uniqueFinalArray = removeDuplicates(finalValue);
    setLovData(uniqueFinalArray);
  };

  const fieldData = useMemo(() => {
    const fieldsObj: Record<string, DynamicField[]> = {};
    if (fields) {
      fields.forEach((field) => {
        if (!fieldsObj[field.group_name]) {
          fieldsObj[field.group_name] = [];
        }
        fieldsObj[field.group_name].push(field);
      });
    }
    return fieldsObj;
  }, [fields]);

  const flatFields = useMemo(() => {
    const fields: DynamicField[] = [];
    Object.keys(fieldData).forEach((groupName: string) => {
      fieldData[groupName].forEach((field) => {
        fields.push(field);
      });
    });

    return fields;
  }, [fieldData]);

  return {
    flatFields,
    fieldData,
    lovData,
    getFieldData,
  };
};
