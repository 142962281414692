import { Modal, ModalBody, ModalContent } from '@nextui-org/react';
import { Button } from 'presentation/components/Button';
import { SubscribeToCredits } from 'presentation/modules/subscription/screens/subscribeToCredits';

interface IProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  creditsModalOnOpenChange: () => void;
}

export const InsufficientCredits = ({ isOpen, creditsModalOnOpenChange }: IProps) => {
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onOpenChange={creditsModalOnOpenChange}
      className="py-6"
      hideCloseButton
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalBody>
              <div className="flex flex-col items-center justify-center gap-10">
                <div className="flex flex-col items-center justify-center gap-1">
                  <h3 className="text-black font-semibold text-xl">Insufficient Credits</h3>
                  <h5 className="text-[#828282] text-center text-[16px] w-[90%]">
                    Oh no! It seems like you&apos;re running low on credits. Buy more Design Credits
                    to carry out the current task
                  </h5>
                </div>

                <div className="flex items-center justify-center gap-4">
                  <Button radius="lg" onPress={onClose} className="bg-[#F0F0F0] text-black px-10">
                    No, cancel
                  </Button>
                  <SubscribeToCredits refreshAll={creditsModalOnOpenChange} />
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
