export const transformCamelToTitle = (camelCaseString: string) => {
  // Insert a space before each uppercase letter except the first one
  let titleCaseString = camelCaseString.replace(/([a-z])([A-Z])/g, '$1 $2');
  // Capitalize the first letter of each word
  titleCaseString = titleCaseString.replace(/\b\w/g, (char) => char.toUpperCase());
  return transformLabel(titleCaseString);
};

const transformLabel = (inputStr: string): string => {
  // Replace underscores with spaces
  let formattedStr = inputStr.replace(/_/g, ' ');
  // Capitalize each word
  formattedStr = formattedStr
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
  return formattedStr;
};
