import axios from 'axios';
import { Category, CreateCategoryDto } from './models/create-category';
import { CreateSubCategoryDto, SubCategory } from './models/create-sub-category';
import { CreateTypeDto, ProjectType } from './models/create-type';
import { CreateLovDto, Lov } from './models/create-lov';
import { FieldCombinationArgs } from './field/hooks/useGetAll';
import { CreateDynamicFieldDto, DynamicFields } from './models/create-dynamic-field';
import { CreateCreditsDto, Credits } from './models/create-credits';
import { CreateInspirationDto, Inspiration } from './models/create-inspiration';
import { StyleType } from './models/create-form-with-combination';
import { CreateAttachmentDto, ProjectAttachment } from './models/create-attachment';
import { Project } from './models/project';
import { Company, User } from 'domain/modules';
import { CreateFaqDto, Faq } from './models/create-faq';

class AdminApi {
  async createCategory(data: CreateCategoryDto): Promise<CreateCategoryDto> {
    return new Promise((resolve, reject) => {
      axios
        .post<CreateCategoryDto>(`/api/admin/category`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async updateCategory(categoryId: string, data: CreateCategoryDto): Promise<Category> {
    return new Promise((resolve, reject) => {
      axios
        .put<Category>(`/api/admin/category/${categoryId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async getAllCategory(): Promise<Category[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Category[]>(`/api/admin/category`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async deleteCategory(categoryId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/admin/category/${categoryId}`)
        .then(() => {
          resolve();
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  // Sub Category
  async createSubCategory(data: CreateSubCategoryDto): Promise<CreateSubCategoryDto> {
    return new Promise((resolve, reject) => {
      axios
        .post<CreateSubCategoryDto>(`/api/admin/sub-category`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async updateSubCategory(
    subcategoryId: string,
    data: CreateSubCategoryDto
  ): Promise<CreateSubCategoryDto> {
    return new Promise((resolve, reject) => {
      axios
        .put<CreateSubCategoryDto>(`/api/admin/sub-category/${subcategoryId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async getAllSubCategory(categoryId: string): Promise<SubCategory[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<SubCategory[]>(`/api/admin/category/${categoryId}/sub-category`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async deleteSubCategory(subcategoryId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/admin/sub-category/${subcategoryId}`)
        .then(() => {
          resolve();
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  // Type
  async createType(subcategory_id: string, data: CreateTypeDto): Promise<CreateTypeDto> {
    return new Promise((resolve, reject) => {
      axios
        .post<CreateTypeDto>(`/api/admin/sub-category/${subcategory_id}/type`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async updateType(typeId: string, data: CreateTypeDto): Promise<CreateTypeDto> {
    return new Promise((resolve, reject) => {
      axios
        .put<CreateTypeDto>(`/api/admin/type/${typeId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async getAllTypes(subcategoryId: string): Promise<ProjectType[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<ProjectType[]>(`/api/admin/sub-category/${subcategoryId}/type`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async deleteTypes(typeId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/admin/type/${typeId}`)
        .then(() => {
          resolve();
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  // LOV
  async createLov(data: CreateLovDto): Promise<CreateLovDto> {
    return new Promise((resolve, reject) => {
      axios
        .post<Lov>(`/api/lov`, { data })
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async updateLov(lovId: string, data: CreateLovDto): Promise<CreateLovDto> {
    return new Promise((resolve, reject) => {
      axios
        .put<Lov>(`/api/lov/${lovId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async getAllLovGroupNames(): Promise<{ id: string; label: string }[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<{ id: string; label: string }[]>(`/api/lov/groupNames`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async getAllLovFieldNames(groupName?: string): Promise<{ id: string; label: string }[]> {
    const searchParams = new URLSearchParams();
    if (groupName) {
      searchParams.set('groupName', groupName);
    }
    return new Promise((resolve, reject) => {
      axios
        .get<{ id: string; label: string }[]>(`/api/lov/fieldNames?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async getLovByGroupName(groupName: string): Promise<Lov[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Lov[]>(`/api/lov/groupName/${groupName}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async getLovByFieldName(fieldName: string): Promise<Lov[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Lov[]>(`/api/lov/fieldName/${fieldName}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
  async deleteLov(lovId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/lov/${lovId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  // Fields
  async getFieldsByCombination(
    params: Partial<FieldCombinationArgs>
  ): Promise<Array<DynamicFields>> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (params.categoryId) {
        searchParams.set('category_id', String(params.categoryId));
      }
      if (params.subCategoryId) {
        searchParams.set('subcategory_id', String(params.subCategoryId));
      }
      if (params.typeId) {
        searchParams.set('type_id', String(params.typeId));
      }
      if (params.style_name) {
        searchParams.set('style', String(params.style_name));
      }

      axios
        .get<DynamicFields[]>(`/api/admin/dynamicfields?${searchParams}`)
        .then((res) => {
          if (Array.isArray(res.data)) {
            resolve(res.data);
          } else {
            resolve([]);
          }
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async deleteField(fieldId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/admin/dynamicfields/${fieldId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async createField(data: CreateDynamicFieldDto): Promise<DynamicFields> {
    return new Promise((resolve, reject) => {
      axios
        .post<DynamicFields>(`/api/admin/dynamicfields`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async updateField(fieldId: string, data: CreateDynamicFieldDto): Promise<DynamicFields> {
    return new Promise((resolve, reject) => {
      axios
        .put<DynamicFields>(`/api/admin/dynamicfields/${fieldId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  // Credits
  async createCredit(data: CreateCreditsDto): Promise<Credits> {
    return new Promise((resolve, reject) => {
      axios
        .post<Credits>(`/api/credits`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async updateCredit(creditId: string, data: CreateCreditsDto): Promise<Credits> {
    return new Promise((resolve, reject) => {
      axios
        .put<Credits>(`/api/credits/${creditId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async deleteCredit(creditId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/credits/${creditId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getAllCreditsByCombination(params: Partial<FieldCombinationArgs>): Promise<Array<Credits>> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (params.categoryId) {
        searchParams.set('category_id', String(params.categoryId));
      }
      if (params.subCategoryId) {
        searchParams.set('subcategory_id', String(params.subCategoryId));
      }
      if (params.typeId) {
        searchParams.set('type_id', String(params.typeId));
      }
      if (params.style_name) {
        searchParams.set('style_name', String(params.style_name));
      }

      axios
        .get<Array<Credits>>(`/api/credits?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async createInspiration(data: CreateInspirationDto): Promise<Inspiration> {
    return new Promise((resolve, reject) => {
      axios
        .post<Inspiration>(`/api/admin/inspiration`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async updateInspiration(inspirationId: string, data: CreateInspirationDto): Promise<Inspiration> {
    return new Promise((resolve, reject) => {
      axios
        .put<Inspiration>(`/api/admin/inspiration/${inspirationId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async deleteInspiration(inspirationId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/admin/inspiration/${inspirationId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getAllInspirations(
    categoryId: string,
    subCategoryId: string,
    styleType: StyleType
  ): Promise<Inspiration[]> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (categoryId) {
        searchParams.set('category_id', String(categoryId));
      }
      if (subCategoryId) {
        searchParams.set('subcategory_id', String(subCategoryId));
      }
      if (styleType) {
        searchParams.set('style_name', String(styleType));
      }

      axios
        .get<Array<Inspiration>>(`/api/admin/inspiration?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  // Project Attachments
  async createAttachment(createAttachmentDto: CreateAttachmentDto): Promise<ProjectAttachment> {
    return new Promise((resolve, reject) => {
      axios
        .post<ProjectAttachment>(`/api/project/attachment`, createAttachmentDto)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getAllAttachment(params: {
    projectId: string;
    projectIterationId?: string;
  }): Promise<ProjectAttachment[]> {
    return new Promise((resolve, reject) => {
      const searchParams = new URLSearchParams();
      if (params.projectId) {
        searchParams.set('project_id', String(params.projectId));
      }
      if (params.projectIterationId) {
        searchParams.set('project_iteration_id', String(params.projectIterationId));
      }

      axios
        .get<ProjectAttachment[]>(`/api/project/attachment?${searchParams}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async deleteAttachment(attachmentId: string) {
    return new Promise((resolve, reject) => {
      reject(`Not implemented delete ${attachmentId}`);
    });
  }

  async getAllProjects(): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Project[]>(`/api/admin/projects`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getAllCompanies(): Promise<Company[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Company[]>(`/api/admin/companies`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getAllProjectsOfCompany(companyId: string): Promise<Project[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Project[]>(`/api/admin/companies/${companyId}/projects`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getProjectById(projectId: string): Promise<Project> {
    return new Promise((resolve, reject) => {
      axios
        .get<Project>(`/api/admin/projects/${projectId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getAllUsers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<User[]>(`/api/admin/users`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async getCompanyForUsers(userId: string): Promise<Company> {
    return new Promise((resolve, reject) => {
      axios
        .get<Company>(`/api/admin/users/${userId}/company`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  // FAQ's
  async getFaq(): Promise<Faq[]> {
    return new Promise((resolve, reject) => {
      axios
        .get<Faq[]>(`/api/faq`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async createFaq(createFaqDto: CreateFaqDto): Promise<Faq> {
    return new Promise((resolve, reject) => {
      axios
        .post<Faq>(`/api/faq`, createFaqDto)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }

  async deleteFaq(faqId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      axios
        .delete<void>(`/api/faq/${faqId}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((ex) => {
          console.log(ex);
          reject(ex);
        });
    });
  }
}

export const adminApi = new AdminApi();
