import { ProjectsListing } from 'domain/modules';
import { projectsService } from 'infra/api';
import { useState } from 'react';

export const useProjectAttachmentListing = () => {
  const [isFetching, setIsFetching] = useState(true);
  const [projects, setProjects] = useState<ProjectsListing>({});

  const getAll = async () => {
    try {
      setIsFetching(true);
      const data = await projectsService.getProjectAttachmentListing();
      setProjects(data);
      return data;
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsFetching(false);
    }
  };

  return {
    isFetching,
    projects,
    getAll,
  };
};
