import { useEffect, useState } from 'react';
import { Project } from 'domain/modules';
import { LeftPanel } from '../components/LeftPanel';
import { LOGO_CATEGORY_ID, stylesData } from '../constants';
import { InspirationSelection } from '../components/InspirationSelection';
import { StyleSelectionForm } from '../components/StyleSelectionForm';
import { useInspirations, useProjectDetail } from 'application/modules/projects';
import { CreateProjectSteps } from '../Wizard';
import { StyleType } from 'adminApp/models/create-form-with-combination';
import { CreateProjectHeader } from '../components/Header';

interface IProps {
  project?: Project;
  isFetching: boolean;
  onChangeStep: (stepName: CreateProjectSteps, projectId?: string, fromStep?: boolean) => void;
}

export const StyleSelection = (props: IProps) => {
  const [styles] = useState(stylesData);
  const [showInspirations, setShowInspirations] = useState(true);
  const [selectedStyle, setSelectedStyle] = useState(
    props.project && props.project.style_name ? props.project.style_name : ''
  );
  const [styleDescription, setStyleDescription] = useState(
    props.project && props.project.style_description ? props.project.style_description : ''
  );
  const [projectInspiration, setProjectInspiration] = useState(
    props.project && props.project.project_inspiration ? props.project.project_inspiration : []
  );
  const [isLogoCategory, setIsLogoCategory] = useState(false);
  const { updateProject } = useProjectDetail();

  const {
    isFetching,
    inspirations,
    selectedInspirations,
    getAllInsprations,
    getInspirationsByStyleType,
    setSelectedInspirations,
  } = useInspirations();

  useEffect(() => {
    if (props.project) {
      setStyleDescription(props.project.style_description || '');
      setSelectedStyle(props.project.style_name || '');
      setProjectInspiration(props.project.project_inspiration || []);
    }
  }, [props.project]);

  useEffect(() => {
    const inspirationArray: Array<string> = [];
    if (projectInspiration && projectInspiration.length > 0) {
      projectInspiration.map((pr) => {
        if (pr) {
          inspirationArray.push(pr.inspiration_id);
        }
      });
    }
    setSelectedInspirations(inspirationArray);
  }, [projectInspiration]);

  useEffect(() => {
    if (props.project?.category_id) {
      getAllInsprations(
        props.project?.category_id,
        props.project?.sub_category_id,
        props.project.category_id === LOGO_CATEGORY_ID ? 'Standard' : (selectedStyle as StyleType)
      );

      if (props.project.category_id === LOGO_CATEGORY_ID) {
        setShowInspirations(true);
        setIsLogoCategory(true);
        setSelectedStyle('Standard');
      } else {
        setIsLogoCategory(false);
      }
    }
  }, [props.project, props.project?.category_id, selectedStyle]);

  const isValid = styleDescription.length <= 750 ? true : false;

  const onNext = async () => {
    if (props.project && selectedStyle && isValid && selectedInspirations.length) {
      const tempProject = { ...props.project };
      tempProject.style_name = selectedStyle;
      const newInspirations = (selectedInspirations || []).filter((ins) => {
        if (tempProject.project_inspiration.find((pi) => pi.inspiration_id === ins)) {
          return false;
        }
        return true;
      });
      const newProjectInspirations = (newInspirations || []).map((ins) => {
        return {
          inspiration_id: ins,
          style_description: styleDescription,
        };
      });
      let inspirationsToKeep = tempProject.project_inspiration.filter((pi) => {
        if (selectedInspirations.includes(pi.inspiration_id)) {
          return true;
        } else {
          return false;
        }
      });
      inspirationsToKeep = inspirationsToKeep.map((i) => {
        return {
          ...i,
          style_description: styleDescription,
        };
      });
      tempProject.project_inspiration = inspirationsToKeep.concat(newProjectInspirations);
      tempProject.style_description = styleDescription;
      const data = await updateProject(tempProject);
      if (data) {
        props.onChangeStep('customQuestions', data.id, true);
      }
    }
  };

  const onPrev = () => {
    props.onChangeStep('titleAndCategory');
  };

  const onBack = () => {
    setShowInspirations(true);
  };

  const onSaveInspirationSelection = (ids: Array<string>) => {
    setSelectedInspirations(ids);
  };

  return (
    <LeftPanel>
      <>
        <CreateProjectHeader
          title="Create Design Task"
          totalSteps={props.project?.category_id === LOGO_CATEGORY_ID ? 9 : 6}
          currentStep={2}
        />
        {showInspirations ? (
          <InspirationSelection
            onPrev={onPrev}
            setShowInspirations={setShowInspirations}
            styles={styles.filter((style) => {
              // if (isLogoCategory) {
              //   return style.id === 'Standard';
              // }
              // return true;
              return style.id === 'Standard';
            })}
            setSelectedStyle={setSelectedStyle}
            inspirations={isLogoCategory ? inspirations : getInspirationsByStyleType(selectedStyle)}
            selectedDefaultInspirations={selectedInspirations}
            saveInspirationSelection={onSaveInspirationSelection}
            setProjectInspiration={setProjectInspiration}
            setSelectedInspirations={setSelectedInspirations}
            setStyleDescription={setStyleDescription}
            isLogoCategory={isLogoCategory}
            selectedStyle={selectedStyle}
            fetchingInspirations={isFetching}
          />
        ) : (
          <StyleSelectionForm
            onNext={onNext}
            onBack={onBack}
            setShowInspirations={setShowInspirations}
            selectedStyle={selectedStyle}
            inspirations={inspirations}
            selectedInspirations={selectedInspirations}
            styleDescription={styleDescription}
            setStyleDescription={setStyleDescription}
          />
        )}
      </>
    </LeftPanel>
  );
};
