export * from './auth';
export * from './notifications';

import authReducer from './auth';
import companyReducer from './company';
import brandReducer from './brand';
import projectsReducer from './projects';

export const reduxReducers = {
  auth: authReducer,
  company: companyReducer,
  brand: brandReducer,
  project: projectsReducer,
};
