import { Chip, cn, useDisclosure } from '@nextui-org/react';
import { Project } from 'domain/modules';
import { Button } from 'presentation/components';
import { useEffect, useState } from 'react';
import { useProjectIteration } from 'application/modules/projects';
import { IterationDetails } from './IterationDetails';
import { ProjectIteration } from 'domain/modules/projects/models/iteration.model';

interface IProps {
  projectId: string;
  project: Project;
  refetchProject: () => void;
}

export const ProjectIterations = ({ projectId, project, refetchProject }: IProps) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [iterationId, setIterationId] = useState('');
  const [iterationStatus, setiterationStatus] = useState('');
  const { getProjectIterations, projectIterations, deleteIteration, getIteration, iteration } =
    useProjectIteration();

  useEffect(() => {
    if (projectId) {
      getProjectIterations(projectId);
    }
  }, []);

  useEffect(() => {
    if (projectId && iterationId) {
      getIteration(projectId, iterationId);
    }
  }, [iterationId]);

  useEffect(() => {
    if (iteration) {
      if (project.status === 'IN_PROGRESS') {
        setiterationStatus('Iteration In Progress');
      } else if (!iteration.isActive) {
        setiterationStatus('Iteration Requested');
      } else {
        setiterationStatus('Iteration Completed');
      }
    }
  }, [iteration]);

  const getIterationStatus = (iteration: ProjectIteration, index: number) => {
    if (iteration) {
      if (project.status === 'IN_PROGRESS' && index === projectIterations.length - 1) {
        return { status: 'Iteration In Progress', to_show_new: true };
      } else if (!iteration.isActive) {
        return { status: 'Iteration Requested', to_show_new: true };
      } else {
        return { status: 'Iteration Completed', to_show_new: false };
      }
    }
    return { status: '', to_show_new: false };
  };

  const onClickIteration = (iterationId: string | undefined) => {
    if (iterationId) {
      setIterationId(iterationId);
      onOpen();
    }
  };

  const onDeleteIteration = async (iterationId: string | undefined) => {
    if (iterationId) {
      await deleteIteration(projectId, iterationId);
      getProjectIterations(projectId);
      refetchProject();
    }
  };

  if (!projectIterations || !Array.isArray(projectIterations) || projectIterations.length <= 1) {
    return (
      <div className="flex flex-col gap-2 text-sm">
        <i>No iterations</i>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-2">
        {projectIterations.map((pi, index) => {
          if (projectIterations.length === 1) {
            return null;
          }

          return (
            <Button
              key={pi.id}
              role="button"
              className={cn(
                'flex item-center justify-between bg-[#F2F2F2] rounded',
                index !== 0 ? 'cursor-pointer' : 'cursor-default'
              )}
              onClick={() => index !== 0 && onClickIteration(pi.id)}
            >
              <div className="flex items-center gap-4">
                <h5 className="text-normal text-black font-medium w-[150px] text-left truncate">
                  {index === 0 ? 'Original Iteration' : `Iteration ${index}`}
                </h5>
                <Chip variant="bordered" size="sm">
                  {getIterationStatus(pi, index).status}
                </Chip>
              </div>

              <div className="flex items-center gap-2">
                {getIterationStatus(pi, index).to_show_new && (
                  <Button className="bg-black text-white" size="sm" disabled>
                    New
                  </Button>
                )}
                {project.status === 'IN_PROGRESS' ||
                project.status === 'COMPLETED' ||
                pi.isActive ? null : (
                  <Button
                    variant="light"
                    className="text-danger"
                    size="sm"
                    onClick={() => onDeleteIteration(pi.id)}
                  >
                    Delete
                  </Button>
                )}
              </div>
            </Button>
          );
        })}
      </div>

      <IterationDetails
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        projectId={projectId}
        project={project}
        iteration={iteration}
        iterationStatus={iterationStatus}
      />
    </>
  );
};
