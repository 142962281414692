import { useCompany } from 'application/modules/company';
import { WizardContainer } from '../components/WizardContainer';
import { useNavigate } from 'react-router-dom';
import { Button } from 'presentation/components';
import { Company } from 'domain/modules';
import { useState } from 'react';
import { Select, SelectItem, cn } from '@nextui-org/react';

interface IProps {
  fieldName: string;
  company: Company;
  updateData: (company: Company, stepName: string) => void;
}

export const Step2 = ({ fieldName, updateData, company }: IProps) => {
  const [selectedValue, setSelectedValue] = useState(company.who_is_using);
  const [selectedDescription, setSelectedDescription] = useState(company.user_description);
  const { getLovDataForField } = useCompany();
  const optionData = getLovDataForField(fieldName);
  const optionData2 = getLovDataForField(`${fieldName}2`) || [];
  const navigate = useNavigate();

  const onNext = () => {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { id, userId, ...rest } = company;
    updateData(
      {
        ...rest,
        who_is_using: selectedValue,
        user_description: selectedDescription,
      },
      '3'
    );
    navigate(`/company/onboarding/step3`);
  };

  const onBack = () => {
    navigate(`/company/onboarding/step1`);
  };

  const onChange = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <WizardContainer
      title="Let's get to meet you."
      description="Understanding who uses Picassofusion will help us improve"
      step={2}
      disableNavigate={!selectedValue || !selectedDescription}
      company={company}
    >
      <div className="w-full flex justify-center">
        <div className="flex flex-col gap-4">
          <Select
            fullWidth
            onChange={(ev) => onChange(ev.target.value)}
            defaultSelectedKeys={selectedValue ? [selectedValue] : ''}
            placeholder="What Describes your Occupation?"
            variant="bordered"
            radius="lg"
            className="shadow-sm"
            classNames={{
              base: 'w-80 md:w-[540px] min-h-14 rounded-[20px]',
              trigger: cn(
                'bg-white border border-solid border-[#D9D9D9] max-w-none rounded-[20px] text-center',
                'px-5 py-3 shadow-none'
              ),
              popoverContent: 'bg-white text-black',
              selectorIcon: 'mr-2',
              value: !selectedValue ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
            }}
          >
            {optionData.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>

          <Select
            fullWidth
            onChange={(ev) => setSelectedDescription(ev.target.value)}
            defaultSelectedKeys={selectedDescription ? [selectedDescription] : ''}
            placeholder="What describes you the best?"
            variant="bordered"
            radius="lg"
            classNames={{
              base: 'w-80 md:w-[540px] min-h-14 rounded-[20px]',
              trigger: cn(
                'bg-white border border-solid border-[#D9D9D9] max-w-none rounded-[20px] text-center',
                'px-5 py-3 shadow-none'
              ),
              popoverContent: 'bg-white text-black',
              selectorIcon: 'mr-2',
              listbox: 'p-3',
              value: !selectedDescription ? 'text-xs 2xl:text-medium' : 'text-black text-sm',
            }}
          >
            {optionData2.map((option) => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </Select>

          <div className="flex gap-5 mt-2 w-full">
            <Button
              size="lg"
              fullWidth
              onClick={onBack}
              radius="full"
              startContent={<i className="ri-arrow-left-line" />}
              className={cn('bg-[#F0F0F0]', 'text-black font-medium')}
            >
              Back
            </Button>
            <Button
              size="lg"
              radius="full"
              className={cn('bg-[#99CF63]')}
              fullWidth
              onClick={onNext}
              isDisabled={!selectedValue || !selectedDescription}
              endContent={<i className="ri-arrow-right-line" />}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </WizardContainer>
  );
};
