import { Player } from '@lottiefiles/react-lottie-player';
import { Accordion, AccordionItem, Chip } from '@nextui-org/react';
import inspiration_loader from 'presentation/assets/animations/inspiration_loader.json';
import { cn } from '@nextui-org/system';
import { useFaq } from 'application/modules/faq';
import { useEffect } from 'react';
import './style.css';

interface FaqItem {
  question: string;
  answer: string;
}

export const FaqScreen: React.FC = () => {
  const { faqs, getFaq, isFetchingFaq } = useFaq();

  useEffect(() => {
    getFaq();
  }, []);

  if (isFetchingFaq) {
    return (
      <div className="h-full flex items-center justify-center">
        <Player
          src={inspiration_loader}
          className="player"
          loop
          autoplay
          style={{
            height: '50vh',
          }}
        />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col items-center justify-start gap-4 pt-[5vh]">
      <Chip
        radius="md"
        className="py-4 px-3"
        classNames={{
          base: cn(
            'bg-[linear-gradient(96deg,#2C61A1_9.62%,#BAD86D_93.34%)]',
            'border border-solid border-[#2C61A1]'
          ),
          content: 'text-white text-sm font-bold uppercase leading-6',
        }}
      >
        FAQ’s
      </Chip>

      <h1 className={cn('w-3/6 text-center', 'text-[#030D03] text-5xl font-semibold')}>
        Browse Our Frequently Asked Questions
      </h1>
      <p className={cn('w-3/6 text-center', 'text-[#6E6E6E] text-base')}>
        Get answers to commonly asked questions about Picassofusion, its features, and how to make
        the most of our powerful Design platform.
      </p>

      <Accordion
        variant="splitted"
        id="faq-questions"
        className="w-4/5 gap-4 p-0"
        itemClasses={{
          base: 'mb-4 rounded-2xl',
          heading: 'flex justify-between items-center',
          content: 'pb-6',
        }}
      >
        {faqs.map((ques: FaqItem, index: number) => (
          <AccordionItem
            key={index}
            value={index}
            title={
              <div className="flex justify-between items-center">
                <span>{ques.question}</span>
                <i className="ri-arrow-up-s-line text-lg" />
              </div>
            }
            textValue={ques.question}
            hideIndicator
          >
            <div dangerouslySetInnerHTML={{ __html: ques.answer }} />
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
