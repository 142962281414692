import { CircularProgress } from '@nextui-org/react';
import { useAssetsUpload } from 'application/shared';
import { AssetUploadResponse } from 'infra/api';
import { useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';

interface IFileUploadProps {
  hasUploadedFiles: boolean;
  handleSaveUploadFile: (asset: AssetUploadResponse, fileName: string) => void;
  path: string;
  allowAllAssets?: boolean;
}

export const FileUpload = ({ hasUploadedFiles, handleSaveUploadFile, path }: IFileUploadProps) => {
  const [loading, setIsLoading] = useState(false);
  const { upload } = useAssetsUpload();

  const handleChange = async (file: File) => {
    try {
      setIsLoading(true);
      const filePath = await upload(path, file);
      handleSaveUploadFile(filePath, file.name);
    } catch (ex) {
      console.log(ex);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FileUploader handleChange={handleChange} name="file">
      {hasUploadedFiles ? (
        <div className="file_upload_area">
          <i className="ri-upload-cloud-2-line ri-lg mb-2" />
          {loading ? (
            <p className="font-medium text-sm flex gap-4 items-center">
              <CircularProgress aria-label="Loading..." size="sm" /> Uploading
            </p>
          ) : (
            <p className="font-medium text-sm">Add a photo or just drag and drop one</p>
          )}
        </div>
      ) : (
        <div className="w-14 h-14 rounded border flex justify-center items-center hover:bg-gray-100 cursor-pointer">
          {loading ? (
            <CircularProgress aria-label="Loading..." size="sm" />
          ) : (
            <i className="ri-add-fill" />
          )}
        </div>
      )}
    </FileUploader>
  );
};
