import {
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@nextui-org/react';
import { useBrand } from 'application/modules/brand';
import { Brand } from 'domain/modules/brand';
import { Button } from 'presentation/components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreateBrand = () => {
  const { create, isCreating, errorCreating, clearErrors } = useBrand();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [brandName, setBrandName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      clearErrors();
      setBrandName('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onCreateBrand = async () => {
    if (brandName) {
      create(brandName)
        .then((data) => {
          const { id } = data as Brand;
          navigate(`/brand/${id}/edit`);
          onClose();
        })
        .catch(() => {
          console.log('Error Brand creation');
        });
    }
  };

  return (
    <>
      <Button
        onClick={onOpen}
        className="rounded-full bg-[#232323] text-white font-semibold px-8"
        startContent={<i className="ri-add-line"></i>}
      >
        Brand Kit
      </Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent className="rounded-lg">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Create Brand Kit</ModalHeader>
              <ModalBody>
                <Input
                  label="Name"
                  labelPlacement="outside"
                  value={brandName}
                  onChange={(ev) => setBrandName(ev.target.value)}
                />
                {errorCreating ? <i className="font-xs text-[#FF0000]">{errorCreating}</i> : null}
              </ModalBody>
              <ModalFooter>
                <Button
                  size="md"
                  onPress={onClose}
                  className="bg-[#F1F3F5] text-[#0D1216] rounded-full px-8 text-sm font-semibold"
                >
                  Cancel
                </Button>
                <Button
                  size="md"
                  isLoading={isCreating}
                  className="bg-[#232323] text-white rounded-full px-8 text-sm font-semibold"
                  onPress={onCreateBrand}
                >
                  Create
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};
